import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {
  Button,
  Col,
  Flex,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductVersion, ProductVersionSummary} from '../model/Product';
import {FormatVersionNumberDisplayString} from '../model/ProductUtils';
import {ProductService} from '../service/ProductService';
import {CopyProductVersionForm} from './CopyProductVersionForm';
import {DeleteProductVersionForm} from './DeleteProductVersionForm';
import {PublishProductVersionForm} from './PublishProductVersionForm';

export interface ProductVersionListProps {
  productService: ProductService;
  valueListService: ValueListService;
  productId: string | undefined;
  selectedProductVersionId: string | undefined;
  setSelectedProductVersionId: (selection: string | undefined) => void;

  onCreateNewProductVersion?: (version: ProductVersion) => void;
  readOnly?: boolean;
  isProductOwnedByTenant?: boolean;
}

export interface CopyModalOpen {
  open: boolean;
  openCount: number;
}

export interface DeleteModalOpen {
  open: boolean;
  openCount: number;
}

export interface NoteState {
  open: boolean;
  version?: ProductVersion;
}

export interface NewEffectiveDateState {
  open: boolean;
  version?: ProductVersion;
}

export function ProductVersionList(props: ProductVersionListProps) {
  const [productVersions, setProductVersions] = useState<
    ProductVersionSummary[]
  >([]);
  // this is the in memory filter list
  const [displayProductVersions, setDisplayProductVersions] = useState<
    ProductVersionSummary[]
  >([]);
  const [effectiveDateFilter, setEffectiveDateFilter] = useState<
    string | undefined
  >('');
  const [modifyCount, setModifyCount] = useState(0);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!props.productId) {
      setProductVersions([]);
      setDisplayProductVersionsBasedOnFilter([]);
    }

    const eff = async () => {
      if (!props.productId) {
        setProductVersions([]);
        setDisplayProductVersionsBasedOnFilter([]);
        return;
      }

      const loaded = await props.productService.QueryProductVersionSummaries({
        productId: props.productId ?? '',
      });
      setProductVersions(loaded);
      setDisplayProductVersionsBasedOnFilter(loaded);
    };
    eff();
  }, [props.productId, modifyCount]);

  useEffect(() => {
    setDisplayProductVersionsBasedOnFilter(productVersions);
  }, [effectiveDateFilter]);

  function onSelectionChanged(rowSelectionModel: GridRowSelectionModel) {
    if (rowSelectionModel.length > 0) {
      props.setSelectedProductVersionId(rowSelectionModel[0] as string);
    } else {
      props.setSelectedProductVersionId(undefined);
    }
  }

  function getDistinctEffectiveDatesAsOptions() {
    const distinctEffectiveDates = Array.from(
      new Set(productVersions.map(pv => pv.effectiveDate))
    );
    const options = distinctEffectiveDates.map(date => {
      return {
        value: date,
        label: DateIsoStringToDisplayString(date),
      };
    });
    return [{value: '', label: ''}, ...options];
  }

  function setDisplayProductVersionsBasedOnFilter(
    allPVs: ProductVersionSummary[]
  ) {
    let displayPV = [...allPVs];
    if (effectiveDateFilter && effectiveDateFilter !== '') {
      displayPV = allPVs.filter(pv => pv.effectiveDate === effectiveDateFilter);
    }

    if (displayPV.length > 0) {
      props.setSelectedProductVersionId(displayPV[0].id);
    } else {
      props.setSelectedProductVersionId(undefined);
    }
    setDisplayProductVersions(displayPV);
  }

  function openCopyDialog() {
    setCopyModalOpen(true);
  }

  function cancelCopyDialog() {
    setCopyModalOpen(false);
  }

  function cancelPublishDialog() {
    setPublishModalOpen(false);
  }

  function onCopyProductVersion(productVersion: ProductVersion) {
    setModifyCount(modifyCount + 1);
    setCopyModalOpen(false);
    props.setSelectedProductVersionId(productVersion.id);
    if (props.productId !== productVersion.productId) {
      navigate(`/platform/product/${productVersion.productId}/detail`);
    }
  }

  function openDeleteProductVersionDialog() {
    setDeleteModalOpen(true);
  }

  function openPublishProductVersionDialog() {
    setPublishModalOpen(true);
  }

  function onCancelDeleteProductVersion() {
    setDeleteModalOpen(false);
  }

  function onPublishProductVersion() {
    setPublishModalOpen(false);
    setModifyCount(modifyCount + 1);
  }

  function onDeleteProductVersion() {
    setDeleteModalOpen(false);
    if (productVersions.length === 1) {
      // assume if there was one version before delete that we just deleted the whole product, so we need to navigate out of this detail
      // to the product list page
      navigate('/platform/product/summary/my');
    } else {
      setModifyCount(modifyCount + 1);
    }
  }

  function isSelectedProductPublished() {
    if (!props.selectedProductVersionId) {
      return false;
    }
    const selectedProduct = productVersions.find(
      pv => pv.id === props.selectedProductVersionId
    );
    if (!selectedProduct) {
      return false;
    }

    return selectedProduct.status === 'Published';
  }

  const colDefs: GridColDef<ProductVersionSummary>[] = [
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      flex: 200,
      valueGetter: (value, row) => {
        return DateIsoStringToDisplayString(row.effectiveDate);
      },
    },
    {
      field: 'versionNumber',
      headerName: 'Version',
      flex: 150,
      renderCell: params => {
        return (
          <Link to={`/rating/product/version/${params.row.id}/table`}>
            {FormatVersionNumberDisplayString(params.row.versionNumber)}
          </Link>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 200,
    },
    {
      field: 'source',
      headerName: 'Version Source',
      flex: 400,
      valueGetter: (value, row) => {
        if (
          row.parentProductVersionEffectiveDate &&
          row.parentProductVersionNumber
        ) {
          return `${row.source} Created from ${DateIsoStringToDisplayString(row.parentProductVersionEffectiveDate)} ${FormatVersionNumberDisplayString(row.parentProductVersionNumber)}`;
        } else {
          return 'Newly created Product';
        }
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 400,
    },
  ].filter(col => props.isProductOwnedByTenant || col.field !== 'source');

  const deleteModal = deleteModalOpen ? (
    <Modal
      className="adaptify-modal"
      open={deleteModalOpen}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
    >
      <DeleteProductVersionForm
        productService={props.productService}
        productVersionId={props.selectedProductVersionId || ''}
        onCancel={onCancelDeleteProductVersion}
        onDeleted={onDeleteProductVersion}
        refreshFormCount={0}
      />
    </Modal>
  ) : (
    <></>
  );

  const copyOptions = props.isProductOwnedByTenant
    ? {
        disableSameEffectiveDate: true,
        disableNewEffectiveDate: true,
        disableNewProductSameUWCompany: true,
      }
    : {
        disableNewProductNewUwCompany: true,
      };

  const copyModal = copyModalOpen ? (
    <Modal
      className="adaptify-modal"
      open={copyModalOpen}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
      title="Copy Rate Product"
    >
      <CopyProductVersionForm
        {...copyOptions}
        productService={props.productService}
        productVersionId={props.selectedProductVersionId || ''}
        valueListService={props.valueListService}
        onCancel={cancelCopyDialog}
        onFinish={onCopyProductVersion}
        refreshFormCount={0}
      />
    </Modal>
  ) : (
    <></>
  );

  const publishModal = publishModalOpen ? (
    <Modal
      className="adaptify-modal"
      open={publishModalOpen}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
      title="Publish Rate Product"
    >
      <PublishProductVersionForm
        productService={props.productService}
        productVersionId={props.selectedProductVersionId || ''}
        onCancel={cancelPublishDialog}
        onPublish={onPublishProductVersion}
      />
    </Modal>
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical>
        <Row className="pb-2">
          <Col span={6}>
            <div className="page-title">Version Details</div>
          </Col>
          <Col span={12}>
            <Flex
              style={{width: '100%'}}
              gap={5}
              justify="center"
              align="center"
            >
              <Typography.Text>Effective Date:</Typography.Text>
              <Select
                showSearch
                optionFilterProp="label"
                style={{width: '200px'}}
                options={getDistinctEffectiveDatesAsOptions()}
                onChange={setEffectiveDateFilter}
                value={effectiveDateFilter}
              />
            </Flex>
          </Col>
          <Col span={6}>
            <Flex style={{width: '100%'}} justify="right" className="gap-2">
              <Tooltip placement="top" title={'Copy'}>
                <Button
                  {...ButtonSettings}
                  onClick={() => openCopyDialog()}
                  disabled={!props.selectedProductVersionId}
                  size="large"
                >
                  <ContentCopyOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip placement="top" title={'Delete'}>
                <Button
                  {...ButtonSettings}
                  onClick={() => openDeleteProductVersionDialog()}
                  disabled={isSelectedProductPublished()}
                  size="large"
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip placement="top" title={'Publish'}>
                <Button
                  {...ButtonSettings}
                  onClick={() => openPublishProductVersionDialog()}
                  disabled={isSelectedProductPublished()}
                  size="large"
                >
                  <PublishOutlinedIcon />
                </Button>
              </Tooltip>
            </Flex>
          </Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={productVersions}
              rowSelectionModel={
                props.selectedProductVersionId
                  ? [props.selectedProductVersionId]
                  : []
              }
              onRowSelectionModelChange={onSelectionChanged}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>

      {deleteModal}
      {copyModal}
      {publishModal}
    </>
  );
}
