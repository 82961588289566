package com.adaptify.rating.admin.model.calculation.validation.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.validation.BindingValidator
import com.adaptify.rating.admin.model.calculation.validation.FunctionValidationMessage

class VariableAlreadyDefinedValidator : BindingValidator {
  override fun validateBinding(
    binding: Binding,
    bindingDescriptor: BindingDescriptor,
    function: CalculationFunction,
    functionDescriptor: FunctionDescriptor,
    evalContext: CalculationEvalContext
  ): Array<FunctionValidationMessage> {
    if (binding.bindingType == BindingType.VariableDeclaration.name
      && binding.variableDeclaration != null) {

      // check the in scope variables, if there's already a variable with the same name
      // in scope then it's a redeclaration and an error
      evalContext.functionContexts.get(function.id)?.variables?.forEach {
        if (it.name == binding.variableDeclaration?.name) {
          return arrayOf(
            FunctionValidationMessage(
              "Variable ${binding.variableDeclaration} is already defined",
              function.id, bindingDescriptor.name
            )
          )
        }
      }
    }
    return emptyArray();
  }
}
