import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../service/LobService';
import {LobItemControl} from './LobItemControl';

type LobItemPageParams = {
  lobDefId: string;
};

export interface LobItemPageProps extends NavigationAware {
  lobService: LobService;
}
export function LobItemPage(props: LobItemPageProps) {
  const {lobDefId} = useParams<LobItemPageParams>();

  // the the requirements don't specify a way to select a rating organization for risk fields, use the URL
  return (
    <LobItemControl lobService={props.lobService} lobDefId={lobDefId || ''} />
  );
}
