package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.CalculationFunctionUtil
import com.adaptify.rating.admin.model.calculation.binding.BindingUtil
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType
import com.adaptify.rating.admin.model.calculation.util.TransitionUtil
import com.adaptify.rating.admin.model.generateUUID

// this is the old style if descriptor that has blocks under the if and else statement
// the new if statement will only be used flow nodes and has different options
object IfBranchDescriptor : FunctionDescriptor("IF", "If", FunctionType.If, "Logic") {

  val predicateDescriptor = object : BindingDescriptor(
    "predicate", "WHEN", arrayOf(BindingType.Predicate),
    null
  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.Predicate);
    }

    override fun shouldRenderBlockHeader(): Boolean {
      return false
    }
  }
  val thenDescriptor = object : BindingDescriptor(
    "then", "THEN", arrayOf(BindingType.Block),
    null

  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.Transition);
    }

    override fun isSingleFunctionBlock(): Boolean {
      return true
    }
  }
  val elseDescriptor = object : BindingDescriptor(
    "else", "ELSE", arrayOf(BindingType.Block),
    null
  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.If, FunctionType.Transition);
    }

    override fun isSingleFunctionBlock(): Boolean {
      return true
    }
  }

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(predicateDescriptor, thenDescriptor, elseDescriptor)

  override fun getDisplayTemplate(
  ): String = "IF" // blocks have special non-configurable rendering

  override fun createDefault(
    roots: Array<CalculationFunction>,
    parent: CalculationFunction?
  ): CalculationFunction {
    // create default bindings for the if statement
    val ifFunc = CalculationFunctionUtil.create(generateUUID(), name, emptyArray());
    // don't use the transition descriptor default here since we need to create two
    // and the creator won't see the names of the newly created transitions to avoid conflict
    val transitions = TransitionUtil.findAllTransitionNames(roots);

    val thenTransitionName = "Transition ${transitions.size + 1}"
    val elseTransitionName = "Transition ${transitions.size + 2}"

    val thenTransition = CalculationFunctionUtil.create(generateUUID(),
      TransitionDescriptor.name,
      arrayOf(
      BindingUtil.fromTransitionDefinition(TransitionDescriptor.nameDescriptor.name, thenTransitionName)))
    val elseTransition = CalculationFunctionUtil.create(generateUUID(),
        TransitionDescriptor.name,
      arrayOf(
        BindingUtil.fromTransitionDefinition(TransitionDescriptor.nameDescriptor.name, elseTransitionName)))

    ifFunc.bindings = arrayOf(
        BindingUtil.fromBlock("then", arrayOf(thenTransition)),
        BindingUtil.fromBlock("else", arrayOf(elseTransition))
    )
    return ifFunc;
  }


}
