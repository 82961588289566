import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Modal, Popconfirm, Row} from 'antd';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {
  LineOfBusinessHierarchyDef,
  LobHierarchyInfo,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';
import {EditRiskHierarchyForm} from './EditRiskHierarchyForm';

export interface LobHierarchyListProps {
  lobService: LobService;
  valueListService: ValueListService;
  lobs: LobHierarchyInfo[];
  selectedLineOfBusinessDefId: string | undefined;
  setSelectedLineOfBusinessDefId: (selection: string | undefined) => void;
  onCreateNewLob?: (lobDef: LineOfBusinessHierarchyDef) => void;
  readOnly?: boolean;
  modifyCount: number;
  setModifyCount: (count: number) => void;
}

export interface EditState {
  isNew: boolean;
  isEditing: boolean;
  lobDefId?: string;
}

export function LobHierarchyList(props: LobHierarchyListProps) {
  const [editState, setEditState] = useState<EditState>({
    isNew: true,
    isEditing: false,
  });

  const allColDefs: GridColDef<LobHierarchyInfo>[] = [
    {
      field: 'name',
      headerName: 'Hierarchy Name',
      flex: 300,
      renderCell: params => {
        return (
          <Link to={`/platform/lob/${params.row.id}/risk`}>
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: 'insuranceType',
      headerName: 'Insurance Type',
      flex: 300,
    },
    {
      field: 'lobName',
      headerName: 'LOB',
      flex: 300,
    },
    {
      field: 'hierarchySource',
      headerName: 'Hierarchy Source',
      flex: 300,
    },
  ];

  // if read only remove the action column
  const colDefs = props.readOnly
    ? allColDefs.filter(col => col.field !== 'actions')
    : allColDefs;

  function onOpenCreate() {
    setEditState({
      isNew: true,
      isEditing: true,
    });
  }

  function onOpenModify(lobDefId: string | undefined) {
    if (lobDefId) {
      setEditState({
        isNew: false,
        isEditing: true,
        lobDefId: lobDefId,
      });
    }
  }

  async function onDelete(lobDefId: string | undefined) {
    if (!lobDefId) {
      return;
    }
    await props.lobService.DeleteLineOfBusiness(lobDefId);
    props.setModifyCount(props.modifyCount + 1);
  }

  async function onSaveLineOfBusinessEntity(id: string) {
    setEditState({
      ...editState,
      isEditing: false,
    });

    props.setModifyCount(props.modifyCount + 1);
    props.setSelectedLineOfBusinessDefId(id);
  }

  function onCancelLineOfBusinessEntity() {
    setEditState({
      ...editState,
      isEditing: false,
    });
  }

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    props.setSelectedLineOfBusinessDefId(
      rowSelectionModel.length > 0
        ? (rowSelectionModel[0] as string)
        : undefined
    );
  }

  const deleteButton = (
    <Popconfirm
      title="Confirm deletion"
      description="Are you sure to want to delete?"
      onConfirm={() => onDelete(props.selectedLineOfBusinessDefId)}
      disabled={!props.setSelectedLineOfBusinessDefId}
      okText="Delete"
      cancelText="Cancel"
    >
      <Button
        {...ButtonSettings}
        disabled={!props.setSelectedLineOfBusinessDefId}
      >
        Delete
      </Button>
    </Popconfirm>
  );

  const editButtons = (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      <Button
        {...ButtonSettings}
        icon={<DriveFileRenameOutlineOutlinedIcon className="-m-1" />}
        onClick={() => onOpenModify(props.selectedLineOfBusinessDefId)}
        disabled={!props.selectedLineOfBusinessDefId}
      >
        Modify
      </Button>
      <Button
        {...ButtonSettings}
        icon={<AddOutlinedIcon className="-m-1" />}
        onClick={onOpenCreate}
      >
        Create New LOB Hierarchy
      </Button>
    </Flex>
  );

  const buttons = props.readOnly ? <></> : editButtons;

  return (
    <>
      <Flex vertical style={{width: '100%', height: '100%'}}>
        <Row>
          <Col span={18}>
            <div className="page-title">Hierarchy Summary</div>
          </Col>
          <Col span={6}>{buttons}</Col>
        </Row>
        <DataGrid
          style={{width: '100%', height: '100%'}}
          {...DataGridSettings}
          columns={colDefs}
          rows={props.lobs}
          getRowId={row => row.id}
          rowSelectionModel={
            props.selectedLineOfBusinessDefId
              ? [props.selectedLineOfBusinessDefId]
              : []
          }
          onRowSelectionModelChange={onTableSelectionChanged}
          sx={{
            '&.MuiDataGrid-root': {
              borderRadius: '8px',
              overflow: 'hidden',
              borderColor: '#CCCCCC',
            },
          }}
        />
      </Flex>
      <Modal
        className="adaptify-modal"
        title={editState.isNew ? 'Add Risk Hierarchy' : 'Edit Risk Hierarchy'}
        open={editState.isEditing}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
      >
        <EditRiskHierarchyForm
          lobService={props.lobService}
          valueListService={props.valueListService}
          lobDefId={editState.lobDefId}
          onSave={onSaveLineOfBusinessEntity}
          onCancel={onCancelLineOfBusinessEntity}
        />
      </Modal>
    </>
  );
}
