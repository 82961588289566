import {Flex} from 'antd';
import React, {useState} from 'react';
import {LobCoverageFieldList} from '../control/LobCoverageFieldList';
import {LobSummaryControl} from '../control/LobSummaryControl';
import {LobService} from '../service/LobService';

export interface LobCoverageFieldControlProps {
  lobHierarchyId: string | undefined;
  lobService: LobService;
  initialCoverageId: string | undefined;
  onSetLobItemId: (lobItemId: string | undefined) => void;
}

export function LobCoverageFieldControl(props: LobCoverageFieldControlProps) {
  const [selectedLobItemId, setSelectedLobItemId] = useState<
    string | undefined
  >(props.initialCoverageId);

  function onSetSelectedCoverageId(lobItemId: string | undefined) {
    setSelectedLobItemId(lobItemId);
    props.onSetLobItemId(lobItemId);
  }

  return (
    <Flex vertical gap={24}>
      <LobSummaryControl
        lobService={props.lobService}
        lobHierarchyId={props.lobHierarchyId}
      />
      <LobCoverageFieldList
        lobService={props.lobService}
        lobHierarchyId={props.lobHierarchyId}
        selectedCoverageId={selectedLobItemId}
        setSelectedCoverageId={onSetSelectedCoverageId}
      />
    </Flex>
  );
}
