import {TenantAwareEntity} from '../../common/model/Common';

export interface Product extends TenantAwareEntity {
  lineOfBusinessId: string;
  underwritingCompanyValueListItemId: string;
  stateCode: string;
  productTypeValueListItemId: string;
  source: string;
}

export interface ProductVersion extends TenantAwareEntity {
  productId: string;
  parentProductVersionId: string | undefined;
  versionNumber: number;
  source: string;
  status: string;
  effectiveDate: string;
  description: string;
}

export interface ProductVersionCoverage extends TenantAwareEntity {
  productVersionId: string;
  lineOfBusinessItemId: string;
  name: string;
  cardinality: string;
}

export interface ProductVersionCoverageField extends TenantAwareEntity {
  productVersionCoverageId: string;
  name: string;
  dataType: string;
}

export interface ProductVersionNote extends TenantAwareEntity {
  productVersionId: string;
  visibility: string;
  text: string;
}

export interface ProductVersionActivityHistory extends TenantAwareEntity {
  productVersionId: string;
  description: string;
}

export interface ProductVersionTableActivityHistory extends TenantAwareEntity {
  productVersionId: string;
  description: string;
}

export interface IdAndName {
  id: string;
  name: string;
}

export interface ProductLobInfo {
  insuranceType: string;
  name: string;
  stateCode: string;
  lineOfBusinessId: string;
}

export interface ProductVersionFlowInfo {
  id: string;
  flowVersion: number | undefined;
  displayName: string;
  parentLobItemId: string;
  parentLobItemType: string;
  parentRiskItemId: string;
  parentRiskItemName: string;
  sequenceNumber: number | undefined;
}

export interface ProductVersionSummary {
  id: string;
  productId: string;
  lineOfBusinessDefId: string;
  versionNumber: number;
  effectiveDate: string;
  stateCode: string;
  status: string;
  carrierName: string;
  carrierValueListItemId: string;
  underwritingCompany: string;
  underwritingCompanyValueListItemId: string;
  lineOfBusinessName: string;
  lineOfBusinessNameValueListItemId: string;
  lineOfBusinessInsuranceType: string;
  lineOfBusinessInsuranceTypeValueListItemId: string;
  lineOfBusinessHierarchyName: string;
  lineOfBusinessHierarchySource: string;
  productType: string;
  productTypeValueListItemId: string;
  source: string;
  description: string;
  parentProductVersionId: string | undefined;
  parentProductVersionNumber: number | undefined;
  parentProductVersionEffectiveDate: string | undefined;
}

export interface TestCaseInfo {
  id: string;
  description: string;
  sequenceNumber: number;
  expectedPremium: string;
}

export interface ProductQuery {
  id?: string;
  tenantId?: string;
  carrierName?: string;
  underwritingCompanyName?: string;
  insuranceType?: string;
  lineOfBusinessName?: string;
  stateCode?: string;
  productType?: string;
  carrierId?: string;
  underwritingCompanyId?: string;
  insuranceTypeId?: string;
  lineOfBusinessNameId?: string;
  productTypeId?: string;
}

export interface ProductVersionQuery {
  id?: string;
  productId?: string;
  carrierName?: string;
  underwritingCompanyName?: string;
  insuranceType?: string;
  lineOfBusinessName?: string;
  stateCode?: string;
  productType?: string;
  carrierId?: string;
  underwritingCompanyId?: string;
  insuranceTypeId?: string;
  lineOfBusinessNameId?: string;
  productTypeId?: string;
  effectiveDate?: string;
}

export interface ProductSummary {
  id: string;
  carrierName: string;
  underwritingCompany: string;
  insuranceType: string;
  lineOfBusinessName: string;
  stateCode: string;
  productType: string | undefined;
}

export interface ProductVersionTableMetadataSummary extends TenantAwareEntity {
  productVersionTableId: string;
  documentTableMetadataId: string;
  tableName: string;
  documentTableSummary: string;
}

export const ProductVersionStatus = {
  Draft: 'Draft',
  Published: 'Published',
} as const;

export const NoteVisibilityType = {
  External: 'External',
  Internal: 'Internal',
} as const;
