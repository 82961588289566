import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../service/LobService';
import {LobCoverageFieldControl} from './LobCoverageFieldControl';

type LobCoverageFieldPageParams = {
  lobHierarchyId: string;
};

export interface LobCoverageFieldPageProps extends NavigationAware {
  lobService: LobService;
}
export function LobCoverageFieldPage(props: LobCoverageFieldPageProps) {
  const {lobHierarchyId} = useParams<LobCoverageFieldPageParams>();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialCoverageId = searchParams.get('coverageId');

  function onSetCoverageId(coverageId: string | undefined) {
    if (coverageId) {
      setSearchParams({coverageId: coverageId}, {replace: true});
    }
  }
  return (
    <LobCoverageFieldControl
      lobService={props.lobService}
      lobHierarchyId={lobHierarchyId}
      initialCoverageId={initialCoverageId ?? undefined}
      onSetLobItemId={onSetCoverageId}
    />
  );
}
