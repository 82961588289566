package com.adaptify.rating.admin.model.lob

import com.adaptify.rating.admin.model.MetadataObject
import kotlin.js.JsExport

expect interface LineOfBusinessHierarchyItemField : MetadataObject {
  var parentLineOfBusinessItemId : String
  var dataType: String
}

expect fun createLineOfBusinessHierarchyItemField(
  id: String,
  name: String,
  version: Int,
  parentLineOfBusinessItemId : String,
  dataType: String
): LineOfBusinessHierarchyItemField

class LineOfBusinessHierarchyItemFieldImpl(
  override var id: String,
  override var name: String,
  override var version: Int,
  override var parentLineOfBusinessItemId : String,
  override var dataType: String
) : LineOfBusinessHierarchyItemField

@JsExport
object LineOfBusinessHierarchyItemFieldUtil {
  fun create(
    id: String,
    name: String,
    version: Int,
    parentLineOfBusinessItemId : String,
    dataType: String
  ): LineOfBusinessHierarchyItemField {
    return createLineOfBusinessHierarchyItemField(id, name, version, parentLineOfBusinessItemId, dataType)
  }
  fun deepCopy(obj: LineOfBusinessHierarchyItemField): LineOfBusinessHierarchyItemField {
    return create(
      obj.id,
      obj.name,
      obj.version,
      obj.parentLineOfBusinessItemId,
      obj.dataType,
    )
  }
}
