import {Button, Flex, Form, Input, Modal} from 'antd';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../common/control/Common';

export interface EditProductDescriptionModalProps {
  open: boolean;
  description: string;
  onOk: (description: string) => Promise<void>;
  onCancel: () => void;
}

interface EditDescriptionFormValues {
  description: string;
}

export function EditProductDescriptionModal(
  props: EditProductDescriptionModalProps
) {
  const [form] = Form.useForm<EditDescriptionFormValues>();

  useEffect(() => {
    form.setFieldsValue({
      description: props.description,
    });
  }, [props.open, props.description]);

  function onSave(values: EditDescriptionFormValues) {
    const eff = async () => {
      await props.onOk(values.description);
    };
    eff();
  }

  return (
    <Modal
      className="adaptify-modal"
      title="Edit Description"
      destroyOnClose={true}
      closable={false}
      open={true}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 600px)'}
      footer={null}
      height={'400px'}
    >
      <Form
        name="edit_product"
        labelWrap={true}
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        layout="vertical"
        size="large"
        style={{width: '100%'}}
        onFinish={onSave}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          name="description"
          label="Description"
          rules={[{required: true}]}
        >
          <Input />
        </Form.Item>
        <Flex justify="end" style={{paddingTop: '20px'}} className="gap-2">
          <Button
            {...ButtonSettings}
            htmlType="button"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button ghost={false} type="default" htmlType="submit">
            OK
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}
