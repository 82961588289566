import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Modal, Row, Space} from 'antd';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {LobService} from '../../lob/service/LobService';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {Product, ProductSummary} from '../model/Product';
import {ProductService} from '../service/ProductService';
import {CreateProductForm} from './CreateProductForm';

export interface ProductListProps {
  lobService: LobService;
  productService: ProductService;
  valueListService: ValueListService;
  authToken: string;
  products: ProductSummary[];
  selection: ProductSummary | undefined;
  selectionChanged: (selection: ProductSummary | undefined) => void;
  productChanged: () => void;
  onCreateNewProduct?: (product: Product) => void;
  readOnly?: boolean;
}

export interface EditState {
  open: boolean;
  product?: Product;
}

interface CreateProductPopupState {
  open: boolean;
  productId?: string;
}

export function ProductList(props: ProductListProps) {
  const [createPopupState, setCreatePopupState] =
    useState<CreateProductPopupState>({
      open: false,
    });

  // trigger the popup form to refresh
  const [refreshFormCount, setRefreshFormCount] = React.useState<number>(0);

  const colDefs: GridColDef<ProductSummary>[] = [
    {
      field: 'carrierName',
      headerName: 'Carrier Name',
      flex: 300,
    },
    {
      field: 'underwritingCompany',
      headerName: 'Underwiting Company',
      flex: 300,
      renderCell: params => {
        return (
          <Link to={`/platform/productversion/${params.row.id}/detail`}>
            {params.row.underwritingCompany}
          </Link>
        );
      },
    },
    {
      field: 'insuranceType',
      headerName: 'Insurance Type',
      flex: 300,
    },
    {
      field: 'lineOfBusinessName',
      headerName: 'Line of Business',
      flex: 300,
    },
    {
      field: 'stateCode',
      headerName: 'State',
      flex: 200,
    },
    {
      field: 'productType',
      headerName: 'Product Type',
      flex: 300,
    },
  ];

  function onCreateNew() {
    setRefreshFormCount(refreshFormCount + 1);
    setCreatePopupState({open: true});
  }

  function onModify() {
    setRefreshFormCount(refreshFormCount + 1);
    setCreatePopupState({open: true, productId: props.selection?.id});
  }

  async function onDelete() {
    await props.productService.DeleteProduct(props.selection?.id || '');
    props.productChanged();
  }

  function onCancelLineOfBusinessEntity() {
    setCreatePopupState({open: false});
  }

  function onProductSaved() {
    // up the modify count to trigger a refresh
    props.productChanged();
    setCreatePopupState({open: false});
  }

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    let selection: ProductSummary | undefined = undefined;
    if (rowSelectionModel.length > 0) {
      selection = props.products.find(lob => lob.id === rowSelectionModel[0]);
    }
    props.selectionChanged(selection);
  }

  const modifyButtons = props.readOnly ? (
    <></>
  ) : (
    <Flex justify="end" className="gap-2">
      <Button
        {...ButtonSettings}
        icon={<AddOutlinedIcon className="-m-1" />}
        onClick={onCreateNew}
        className="RightButton"
      >
        Create New Rate Product
      </Button>
      <Button
        {...ButtonSettings}
        icon={<DriveFileRenameOutlineOutlinedIcon className="-m-1" />}
        onClick={onModify}
        disabled={!props.selection}
      >
        Modify
      </Button>
      <Button
        {...ButtonSettings}
        icon={<DeleteOutlinedIcon className="-m-1" />}
        onClick={onDelete}
        disabled={!props.selection}
        className="RightButton"
      >
        Delete
      </Button>
    </Flex>
  );

  const createProductModal = createPopupState.open ? (
    <Modal
      className="adaptify-modal"
      title={
        createPopupState.productId
          ? 'Modify Product'
          : 'Create New Rate Product'
      }
      open={createPopupState.open}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
    >
      <CreateProductForm
        lobService={props.lobService}
        productService={props.productService}
        valueListService={props.valueListService}
        authToken={props.authToken}
        onSaved={onProductSaved}
        onCancel={onCancelLineOfBusinessEntity}
        refreshFormCount={refreshFormCount}
        existingProductId={createPopupState.productId}
      />
    </Modal>
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Rating Products</div>
          </Col>
          <Col span={6}>{modifyButtons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={props.products}
              rowSelectionModel={props.selection?.id}
              onRowSelectionModelChange={onTableSelectionChanged}
              className="striped-grid"
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>
      {createProductModal}
    </>
  );
}
