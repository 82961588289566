import React from 'react';

import {DataGrid, GridColDef} from '@mui/x-data-grid';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import {DataGridSettings} from '../../../common/control/Common';
import {ColumnLog, TableLog} from '../../model/Rating';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import TestCaseLobItem = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItem;

function transformColumnLogsIntoDisplayMatrix(
  columnLogs: ColumnLog[]
): string[][] {
  const columnTypeRow = ['Column Type'];
  const columnNameRow = ['Column Name'];
  const columnPathRow = ['Column Path / Variable'];
  const columnValueRow = ['Column Value'];

  for (const columnLog of columnLogs) {
    columnTypeRow.push(columnLog.columnType);
    columnNameRow.push(columnLog.columnName);
    columnPathRow.push(columnLog.path);
    columnValueRow.push(columnLog.columnValue);
  }
  return [columnTypeRow, columnNameRow, columnPathRow, columnValueRow];
}

export interface TableLogControlProps {
  tableLog: TableLog;
}

export function TableLogControl(props: TableLogControlProps) {
  const headerColDef: GridColDef<String[]> = {
    field: '0',
    headerName: 'Titles',
    flex: 300,
    renderCell: params => {
      return <strong>{params.value}</strong>;
    },
  };

  const dataColDef: GridColDef<String[]>[] = props.tableLog.columns.map(
    (columnLog, index) => {
      return {
        field: (index + 1).toString(),
        headerName: index.toString(),
        flex: 300,
      };
    }
  );

  const colDefs = [headerColDef, ...dataColDef];

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
      }}
    >
      <DataGrid
        {...DataGridSettings}
        columns={colDefs}
        getRowId={row => row[0]}
        rows={transformColumnLogsIntoDisplayMatrix(props.tableLog.columns)}
        hideFooter
        slots={{
          columnHeaders: () => null,
        }}
        sx={{
          '&.MuiDataGrid-root': {
            borderRadius: '8px',
            overflow: 'hidden',
            borderColor: '#CCCCCC',
          },
        }}
      />
    </div>
  );
}
