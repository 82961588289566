import {Flex} from 'antd';
import React, {useState} from 'react';
import {LobCoverageList} from '../control/LobCoverageList';
import {LobRiskList} from '../control/LobRiskList';
import {LobSummaryControl} from '../control/LobSummaryControl';
import {LobService} from '../service/LobService';

export interface LobItemControlProps {
  lobDefId: string;
  lobService: LobService;
}

export function LobItemControl(props: LobItemControlProps) {
  const [selectedRiskId, setSelectedRiskId] = useState<string | undefined>(
    undefined
  );
  const [selectedCoverageId, setSelectedCoverageId] = useState<
    string | undefined
  >(undefined);
  const [modifyCount, setModifyCount] = useState<number>(0);

  return (
    <Flex vertical gap={24}>
      <LobSummaryControl
        lobHierarchyId={props.lobDefId}
        lobService={props.lobService}
        supressRiskItemsAndCoverageNavigation={true}
      />
      <LobRiskList
        lobService={props.lobService}
        lobDefId={props.lobDefId}
        selection={selectedRiskId}
        selectionChanged={setSelectedRiskId}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
      />
      <LobCoverageList
        lobService={props.lobService}
        lobHierarchyId={props.lobDefId}
        parentLobItemId={selectedRiskId ?? ''}
        selection={selectedCoverageId}
        selectionChanged={setSelectedCoverageId}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
      />
    </Flex>
  );
}
