package com.adaptify.rating.admin.model.calculation.validation.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.validation.BindingValidator
import com.adaptify.rating.admin.model.calculation.validation.FunctionValidationMessage
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format.DateTimeFormat
import kotlinx.datetime.format.char

val DATE_FORMAT ="mm/dd/yyyy";
class LiteralValueValidator : BindingValidator {
  override fun validateBinding(
    binding: Binding,
    bindingDescriptor: BindingDescriptor,
    function: CalculationFunction,
    functionDescriptor: FunctionDescriptor,
    evalContext: CalculationEvalContext
  ): Array<FunctionValidationMessage> {
    val dataType = binding.literal?.dataType
    val value = binding.literal?.value

    if (binding.bindingType == BindingType.PrimitiveLiteral.name
      && dataType != null && value != null
    ) {
      if (dataType == PrimitiveDataType.Number.name) {
        try {
          value.toDouble()
        } catch (e: NumberFormatException) {
          return arrayOf(
            FunctionValidationMessage(
              "Value ${value} is not a valid number",
              function.id, bindingDescriptor.name
            )
          )
        }
      }
      if (dataType == PrimitiveDataType.Boolean.name) {
        if (value.lowercase() != "true" &&
            value.lowercase() != "false") {
          return arrayOf(FunctionValidationMessage(
            "Value ${value} is not a valid boolean",
            function.id, bindingDescriptor.name
          ))
        }
      }
      if (dataType == PrimitiveDataType.Date.name) {
        // there isn't a date formatter that's multiplatform so just build something stupid and simple
        // for first pass

        val dateFormat = LocalDate.Format {
          monthNumber()
          char('/')
          dayOfMonth()
          char('/')
          year()
        }
        try {
          LocalDate.Formats.ISO.parse(value)
        } catch (e : IllegalArgumentException) {
          return arrayOf(
            FunctionValidationMessage(
              "Value ${value} is not a valid date in format mm/dd/yyyy",
              function.id, bindingDescriptor.name))
        }
      }
    }
    return emptyArray()
  }
}

