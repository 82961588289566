package com.adaptify.rating.admin.model.calculation.validation.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.validation.BindingValidator
import com.adaptify.rating.admin.model.calculation.validation.FunctionValidationMessage
import com.adaptify.rating.admin.model.util.BindingResolver

class ValidPathValidator : BindingValidator {
  override fun validateBinding(
    binding: Binding,
    bindingDescriptor: BindingDescriptor,
    function: CalculationFunction,
    functionDescriptor: FunctionDescriptor,
    evalContext: CalculationEvalContext
  ): Array<FunctionValidationMessage> {
    val scopeVariables  = evalContext.functionContexts.get(function.id)?.variables ?: emptyArray()
    val path = binding.path;
    if (binding.bindingType == BindingType.Path.name && path != null) {

      val dataType = BindingResolver.ResolveDataTypeForBinding(function, binding, scopeVariables)
      if (dataType == null) {
        return arrayOf(
          FunctionValidationMessage(
            "Unable to resolve path ${binding.path}",
            function.id, bindingDescriptor.name
          )
        )
      }
    }
    val functionId = binding.dynamicVariableFunctionId;
    if (binding.bindingType == BindingType.DynamicVariable.name && functionId != null) {
      val dataType = BindingResolver.ResolveDataTypeForBinding(function, binding, scopeVariables)
      if (dataType == null) {
        return arrayOf(
          FunctionValidationMessage(
            "Unable to resolve step, check if the referenced step was modified or moved after the current step",
            function.id, bindingDescriptor.name
          )
        )
      }
    }
    return emptyArray();
  }
}
