package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.ConfigurationValue
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType
import com.adaptify.rating.admin.model.type.PrimitiveDataType

object RoundDescriptor : FunctionDescriptor("ROUND", "ROUND", FunctionType.Function, "Math") {

  val assignToDescriptor = BindingDescriptor(
    "assignTo", "Assign To", BindingDescriptor.standardOutputBindingTypes,
    BindingDescriptor.numberDataType
  )
  val valueDescriptor = BindingDescriptor(
    "value", "Value", BindingDescriptor.standardInputBindingTypes,
    BindingDescriptor.numberDataType
  )
  val numberOfDigitsDescriptor = object : BindingDescriptor(
    "numberOfDigits", "Number of Digits", arrayOf(BindingType.ConfigurationValue),
    BindingDescriptor.stringDataType


  ) {

    val roundConfigurationValues = arrayOf(
      ConfigurationValue("-6","1000000"),
      ConfigurationValue("-5","100000"),
      ConfigurationValue("-4","10000"),
      ConfigurationValue("-3","1000"),
      ConfigurationValue("-2","100"),
      ConfigurationValue("-1","10"),
      ConfigurationValue("0","0"),
      ConfigurationValue("1",".1"),
      ConfigurationValue("2",".01"),
      ConfigurationValue("3",".001"),
      ConfigurationValue("4",".0001"),
      ConfigurationValue("5",".00001"),
      ConfigurationValue("6",".000001"),
    )


    override fun getAllowedConfigurationValues(): Array<ConfigurationValue> =
      roundConfigurationValues
  }

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(assignToDescriptor, valueDescriptor, numberOfDigitsDescriptor)

  override fun getDisplayTemplate(
  ): String = "\$assignTo = ROUND(\$value, \$numberOfDigits)"
}
