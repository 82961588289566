import {Button, Flex, Form, Input, Select} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {IdAndValue} from '../../valuelist/model/ValueList';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {
  LineOfBusinessHierarchyDef,
  OrganizationName,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';

export interface EditRiskHierarchyFormProps {
  lobService: LobService;
  valueListService: ValueListService;
  lobDefId: string | undefined;
  onSave: (id: string) => void;
  onCancel: () => void;
}

interface FormValues {
  name: string;
  insuranceTypeValueListItemId: string;
  lobNameValueListItemId: string;
  hierarchySource: string;
}
export function EditRiskHierarchyForm(props: EditRiskHierarchyFormProps) {
  const [form] = Form.useForm<FormValues>();
  const [insuranceTypes, setInsuranceTypes] = useState<IdAndValue[]>([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState<IdAndValue[]>([]);
  const [selectedInsuranceTypeId, setSelectedInsuranceTypeId] = useState<
    string | undefined
  >(undefined);
  const [existingLobDef, setExistingLobDef] = useState<
    LineOfBusinessHierarchyDef | undefined
  >(undefined);

  useEffect(() => {
    const eff = async () => {
      form.resetFields();
      // load the LOB if provided
      if (!props.lobDefId) {
        setExistingLobDef(undefined);
        setSelectedInsuranceTypeId(undefined);
        return;
      }

      const lobDef = await props.lobService.GetLineOfBusiness(props.lobDefId);
      setExistingLobDef(lobDef);

      if (!lobDef) {
        return;
      }

      let insuranceTypeValueListItemId: string | undefined = undefined;
      if (lobDef.lobNameValueListItemId) {
        // we need to look up the parent value list to get the insurance type id, since the schema is normalized
        const lobValueListItem = await props.valueListService.GetValueListItem(
          lobDef.lobNameValueListItemId
        );
        insuranceTypeValueListItemId = lobValueListItem.parentValueListItemId;
        setSelectedInsuranceTypeId(insuranceTypeValueListItemId);
      } else {
        setSelectedInsuranceTypeId(undefined);
      }

      form.setFieldsValue({
        name: lobDef.name,
        insuranceTypeValueListItemId: insuranceTypeValueListItemId,
        lobNameValueListItemId: lobDef.lobNameValueListItemId,
        hierarchySource: lobDef.hierarchySource,
      });
    };
    eff();
  }, [props.lobDefId]);

  useEffect(() => {
    const eff = async () => {
      const loaded = await props.valueListService.GetInsuranceTypes();
      setInsuranceTypes(loaded);
    };
    eff();
  }, []);

  useEffect(() => {
    const eff = async () => {
      const loaded = await props.valueListService.GetLinesOfBusiness(
        selectedInsuranceTypeId
      );
      setLinesOfBusiness(loaded);
    };
    eff();
  }, [selectedInsuranceTypeId]);

  function getLobCategoryOptions(): BaseOptionType[] {
    return insuranceTypes.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getLobNameOptions(): BaseOptionType[] {
    return linesOfBusiness.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  async function onSave(formFields: FormValues) {
    const toSave = {
      ...NewTenantAwareEntity(),
      ...(existingLobDef || {}), // copy over the existing fields if they exist
      lobNameValueListItemId: formFields.lobNameValueListItemId,
      name: formFields.name,
      hierarchySource: OrganizationName.Adaptify, // TODO: this should be based on current user and validated by the server
      status: 'Draft',
    } as LineOfBusinessHierarchyDef;

    if (existingLobDef) {
      await props.lobService.UpdateLineOfBusiness(toSave);
    } else {
      await props.lobService.CreateLineOfBusiness(toSave);
    }

    // merge values in the form with the existing object
    props.onSave(toSave.id);
  }

  function onFormValuesChange(changedValues: any, allValues: FormValues) {
    if (changedValues.insuranceTypeValueListItemId) {
      // reset the dependent list of values
      form.setFieldValue('lineOfBusinessValueListItemId', '');
      setSelectedInsuranceTypeId(changedValues.insuranceTypeValueListItemId);
    }
  }

  return (
    <Form
      name="edit_risk_hierarchy"
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      style={{width: '100%'}}
      onValuesChange={onFormValuesChange}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item name="name" label="Hierarchy Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="insuranceTypeValueListItemId"
        label="Insurance Type"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="select an Insurance Type"
          options={getLobCategoryOptions()}
        />
      </Form.Item>
      <Form.Item
        name="lobNameValueListItemId"
        label="Line of Business"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="select a Line of Business"
          options={getLobNameOptions()}
        />
      </Form.Item>
      <Flex justify="end" className="w-full gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          OK
        </Button>
      </Flex>
    </Form>
  );
}
