import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {RatingNavigationBar} from '../control/RatingNavigationBar';
import {PremiumOrchestrationList} from '../control/orchestration/PremiumOrchestrationList';
import {RatingService} from '../service/RatingService';

import {
  ProductVersion,
  ProductVersionStatus,
} from '../../product/model/Product';
import {ProductVersionSummaryForm} from '../control/ProductVersionSummaryForm';

export interface ProductVersionPremiumOrchestrationControlProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  productVersionId: string;
}

export function ProductVersionPremiumOrchestrationControl(
  props: ProductVersionPremiumOrchestrationControlProps
) {
  const [productVersion, setProductVersion] = useState<
    ProductVersion | undefined
  >(undefined);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setProductVersion(undefined);
        return;
      }
      const productVersion = await props.productService.GetProductVersion(
        props.productVersionId
      );
      setProductVersion(productVersion);
    };
    eff();
  }, [props.productVersionId]);

  async function savePlaceholder() {}

  function isDirty() {
    return false;
  }

  function isReadOnly() {
    return productVersion?.status === ProductVersionStatus.Published;
  }

  // right now the premium orchestration is saved on every modification, so there's no concept of being dirty
  return (
    <Flex vertical gap={24}>
      <div style={{minHeight: '200px', width: '100%'}}>
        <ProductVersionSummaryForm
          productService={props.productService}
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          save={savePlaceholder}
          readOnly={isReadOnly()}
        />
      </div>
      <div style={{minHeight: '46px', width: '100%'}}>
        <RatingNavigationBar
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          selection="Orchestration"
          onSave={savePlaceholder}
        />
      </div>
      <div style={{width: '100%', height: '800px'}}>
        <PremiumOrchestrationList
          productService={props.productService}
          ratingService={props.ratingService}
          lobService={props.lobService}
          productVersionId={props.productVersionId}
          readOnly={isReadOnly()}
        />
      </div>
    </Flex>
  );
}
