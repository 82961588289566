package com.adaptify.rating.admin.model.calculation.util

import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.binding.ConjunctionType
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.format
import kotlinx.datetime.format.*
import kotlin.js.JsExport

@JsExport
object BindingHelper {

  val displayDateFormat = LocalDate.Format {
    monthNumber(padding = Padding.ZERO)
    char('/')
    dayOfMonth(padding = Padding.ZERO)
    char('/')
    year()
  }

  fun GetBindingDisplayString(
    parentFunctionId: String,
    bindingDescriptor: BindingDescriptor,
    binding: Binding,
    context: CalculationEvalContext
  ) : String {
    if (binding.bindingType == null) {
      return ""
    }

    if (binding.bindingType == "PrimitiveLiteral") {
      if (binding.literal?.dataType == PrimitiveDataType.Date.name &&
        binding.literal?.value != null) {
        try {
        return LocalDate.parse(binding.literal?.value!!).format(
          displayDateFormat)
        } catch (e: Exception) {
          return "<Invalid Date>"
        }
      }
      if (binding.literal?.dataType == PrimitiveDataType.Boolean.name &&
        binding.literal?.value != null) {
        return binding.literal?.value!!.uppercase();
      }
      return binding.literal?.value ?: "<Provide Value>";
    }

    val stepMapper = { functionId: String ->
      context.functionContexts[functionId]?.stepName ?: "<Invalid Step>"
    }

    if (binding.bindingType == "DynamicVariable") {
      return stepMapper(binding.dynamicVariableFunctionId ?: "<Invalid Variable>")
    }

    if (binding.bindingType == "DynamicVariableDeclaration") {
      return stepMapper(parentFunctionId)
    }

    if (binding.bindingType == "Path") {
      return binding.path ?: "<Pick Path>"
    }

    if (binding.bindingType == "VariableDeclaration") {
      return binding.variableDeclaration?.name ?: "<Pick Variable Name>"
    }
    if (binding.bindingType == "TableName") {
      val tableName = binding.tableName ?: "<Pick Table>"
      return "Table $tableName"
    }

    if (binding.bindingType == "TableBinding") {
      val tableName = binding.tableBinding?.tableName ?: "<Pick Table>"
      val resultColumn = binding.tableBinding?.resultColumn ?: "<Pick Result>"
      return "Lookup $resultColumn from $tableName"
    }
    if (binding.bindingType == "TransitionDefinition") {
      return binding.transitionDefinition ?: "<Define Transition>"
    }
    if (binding.bindingType == "Predicate") {
      if (binding.predicate?.conjunctionType == null) {
        return "<Define Predicate>"
      }
      return ConjunctionType.valueOf(binding.predicate?.conjunctionType!!).displayName
    }
    if (binding.bindingType == "ConfigurationValue") {
      if (binding.configurationValue == null) {
        return "<Provide Value>"
      }

      val allowedValues = bindingDescriptor.getAllowedConfigurationValues();
      val value = allowedValues.find { it.name == binding.configurationValue }
      return value?.displayName ?: "<Invalid Value>"
    }

    return ""
  }
}
