import {SERVER_BASE_URL} from '../../common/service/Constants';
import {
  CreateStandardHeaders,
  HandleFetchJson,
} from '../../common/service/Service';
import {Tenant} from '../model/Tenant';

const QUERY_TENANT_URL = `${SERVER_BASE_URL}/admin/tenant/query`;
const GET_TENANT_URL = (id: string) =>
  `${SERVER_BASE_URL}/admin/tenant/get/${id}`;
const SAVE_TENANT_URL = `${SERVER_BASE_URL}/admin/tenant/update`;

export interface TenantService {
  QueryTenants(): Promise<Tenant[]>;
  GetTenant(id: string): Promise<Tenant>;
  SaveTenant(tenant: Tenant): Promise<Tenant>;
}

export default function NewTenantService(authToken: string): TenantService {
  function createHeaders() {
    return CreateStandardHeaders(authToken);
  }

  async function QueryTenants(): Promise<Tenant[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(QUERY_TENANT_URL, requestOptions));
  }

  async function GetTenant(id: string): Promise<Tenant> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_TENANT_URL(id), requestOptions));
  }

  async function SaveTenant(tenant: Tenant): Promise<Tenant> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(tenant),
    };

    return HandleFetchJson(fetch(SAVE_TENANT_URL, requestOptions));
  }

  return {
    QueryTenants,
    GetTenant,
    SaveTenant,
  };
}
