import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Modal, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {User} from '../model/User';
import {UserService} from '../service/UserService';
import {CreateUserForm} from './CreateUserForm';

export interface UserListProps {
  userService: UserService;
  readOnly?: boolean;
  tenantId: string | undefined;
}

export interface EditUserState {
  open: boolean;
  userId?: string;
}

export function UserList(props: UserListProps) {
  const [createPopupState, setCreatePopupState] = useState<EditUserState>({
    open: false,
  });
  const [modifyCount, setModifyCount] = React.useState<number>(0);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!props.tenantId) {
      setUsers([]);
      return;
    }
    const eff = async () => {
      const loaded = await props.userService.QueryUser({
        tenantId: props.tenantId,
      });
      setUsers(loaded);
      setSelectedUserId(loaded.length > 0 ? loaded[0].id : undefined);
    };
    eff();
  }, [props.tenantId, modifyCount]);

  const colDefs: GridColDef<User>[] = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      flex: 300,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 300,
    },
    {
      field: 'disabled',
      headerName: 'Disabled',
      flex: 300,
      valueFormatter: params => {
        return params.value ? 'Yes' : 'No';
      },
    },
  ];

  function onCreateNew() {
    setCreatePopupState({open: true});
  }

  function onModify() {
    setCreatePopupState({open: true, userId: selectedUserId});
  }

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    if (rowSelectionModel.length > 0) {
      setSelectedUserId(rowSelectionModel[0] as string);
    } else {
      setSelectedUserId(undefined);
    }
  }

  function onUserSaved() {
    // up the modify count to trigger a refresh
    setModifyCount(modifyCount + 1);
    setCreatePopupState({open: false});
  }

  function onUserCancelled() {
    setCreatePopupState({open: false});
  }

  const selectedUser = users.find(u => u.id === selectedUserId);

  function onResetPassword() {
    if (!selectedUserId) {
      return;
    }
    props.userService.ResetPassword(selectedUser?.email ?? '');
    setModifyCount(modifyCount + 1);
  }

  function onInactivateUser() {
    if (!selectedUserId) {
      return;
    }
    props.userService.InactivateUser(selectedUser?.email ?? '');
    setModifyCount(modifyCount + 1);
  }

  function onActivateUser() {
    if (!selectedUserId) {
      return;
    }
    props.userService.ActivateUser(selectedUser?.email ?? '');
    setModifyCount(modifyCount + 1);
  }

  const modifyButtons = props.readOnly ? (
    <></>
  ) : (
    <Flex justify="end">
      <Button {...ButtonSettings} onClick={onCreateNew}>
        Create User
      </Button>
      <Button
        {...ButtonSettings}
        onClick={onResetPassword}
        disabled={!selectedUser}
      >
        Reset Password
      </Button>
      <Button
        {...ButtonSettings}
        onClick={onInactivateUser}
        disabled={selectedUser?.disabled}
      >
        Disable User
      </Button>
      <Button
        {...ButtonSettings}
        onClick={onActivateUser}
        disabled={selectedUser && !selectedUser?.disabled}
      >
        Enable User
      </Button>
    </Flex>
  );

  const createUserModal = createPopupState.open ? (
    <Modal
      className="adaptify-modal"
      title="Create User"
      open={createPopupState.open}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
    >
      <CreateUserForm
        userService={props.userService}
        existingTenantId={props.tenantId}
        onCreated={onUserSaved}
        onCancel={onUserCancelled}
      />
    </Modal>
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Users</div>
          </Col>
          <Col span={6}>{modifyButtons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={users}
              rowSelectionModel={selectedUserId ? [selectedUserId] : []}
              onRowSelectionModelChange={onTableSelectionChanged}
            />
          </div>
        </Space>
      </Flex>
      {createUserModal}
    </>
  );
}
