import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {RatingService} from '../service/RatingService';
import {ProductVersionTableControl} from './ProductVersionTableControl';

export interface ProductVersionTablePageProps extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authToken: string;
}

export function ProductVersionTablePage(props: ProductVersionTablePageProps) {
  const {productVersionId} = useParams<string>();

  const [searchParams, setSearchParams] = useSearchParams();

  if (typeof productVersionId !== 'string') {
    return <></>;
  }
  const initialTableId = searchParams.get('tableId') || undefined;

  function onTableSelected(tableId: string | undefined) {
    if (tableId) {
      setSearchParams({tableId: tableId}, {replace: true});
    }
  }

  return (
    <ProductVersionTableControl
      lobService={props.lobService}
      productService={props.productService}
      ratingService={props.ratingService}
      productVersionId={productVersionId as string}
      authToken={props.authToken}
      initialTableId={initialTableId}
      onTableSelected={onTableSelected}
    />
  );
}
