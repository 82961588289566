package com.adaptify.rating.admin.model.lob

@JsExport
external actual interface LineOfBusinessHierarchy {
  actual var id : String
  actual var version : Int
  actual var risks: Array<LineOfBusinessHierarchyItem>
}

actual fun createLineOfBusinessHierarchy(
  id: String,
  version: Int,
  risks: Array<LineOfBusinessHierarchyItem>) : LineOfBusinessHierarchy {
  return js("{id: id, version: version, risks: risks}")
}


