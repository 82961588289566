package com.adaptify.rating.admin.model.util

import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.util.GlobalVariable
import com.adaptify.rating.admin.model.calculation.validation.CalculationValidator
import com.adaptify.rating.admin.model.calculation.validation.FunctionValidationMessage
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise

// this is mostly used to convert between deferred and promises

@JsExport
@JsName("GetCalculationEvalContext")
@DelicateCoroutinesApi
fun GetCalculationEvalContextJs(
  calculation: Calculation,
  lobDef: LineOfBusinessHierarchy,
  globalVariables: Array<ScopedVariable>
): Promise<CalculationEvalContext?> {
  return GlobalScope.promise {
    CalculationEvalContext.Create(calculation, lobDef, globalVariables)
  }
}

@JsExport
@JsName("GetFunctionStepNameMap")
@DelicateCoroutinesApi
fun GetFunctionStepNameMapJs(calculation: Calculation, lobDef: LineOfBusinessHierarchy): Promise<Map<String, String>> {
   return GlobalScope.promise {
     BindingResolver.GetFunctionStepNameMap(calculation, lobDef)
   }
 }

@JsExport
@JsName("ValidateCalculation")
@DelicateCoroutinesApi
fun ValidateCalculationJs(
  calculation: Calculation,
  metadataProvider: MetadataProviderJs,
  evalContext: CalculationEvalContext
): Promise<Array<FunctionValidationMessage>> {
  return GlobalScope.promise {
    CalculationValidator().validateCalculation(calculation,  null, evalContext)
  }
}
