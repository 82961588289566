package com.adaptify.rating.admin.model.calculation.context

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.BoundDataType
import com.adaptify.rating.admin.model.calculation.descriptor.BoundDataTypeImpl
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptorMap
import com.adaptify.rating.admin.model.calculation.util.GlobalVariable
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlin.js.JsExport
import com.adaptify.rating.admin.model.util.BindingResolver

const val DynamicVariableDisplayPrefix = "Step ";

@JsExport
enum class VariableType {
  Input,
  Variable,
  DynamicVariable,
}

// a scoped variable can be a final data type, or pointing to a risk or coverage

@JsExport
class ScopedVariable(val type : String,
                     val name: String,
                     val displayName : String,
                     // either LOB item id or data type (PrimitiveDataType) will be set
                     val boundDataType: BoundDataType)

@JsExport
class FunctionEvalContext(val stepName: String, val variables: Array<ScopedVariable>)

@JsExport
class CalculationEvalContext(val functionContexts: Map<String, FunctionEvalContext>) {
  @JsExport.Ignore
  companion object {
    @JsExport.Ignore
    fun Create(calculation: Calculation,
                       lobDef: LineOfBusinessHierarchy,
                       globalVariables: Array<ScopedVariable> = emptyArray()): CalculationEvalContext {
      val map = mutableMapOf<String, FunctionEvalContext>()
      // add the calculation inputs to the context then process the functions

      val rootVariables = BindingResolver.getRootVariablesForLineOfBusinessItem(
        calculation.lineOfBusinessItemId, lobDef);

      val allVars = globalVariables.asList() + rootVariables;

      createInternal(lobDef, calculation.functions ?: emptyArray(),
        listOf(allVars), DynamicVariableDisplayPrefix, map)

      return CalculationEvalContext(map)
    }

    @JsExport.Ignore
    private fun createInternal(lobDef: LineOfBusinessHierarchy,
                                       calculationFunctions: Array<CalculationFunction>,
                                       stack : List<List<ScopedVariable>>,
                                       dynamicVariableDisplayPrefix: String,

                                       returnMap: MutableMap<String, FunctionEvalContext>) {
      val flattenedParents = stack.flatten();
      val scopedVariables = mutableListOf<ScopedVariable>();
      var currentStepNum = 1

      for (f in calculationFunctions) {
        // example: for loop, variable is defined on parent, but only scoped on children
        // we don't support variabled defined on parent but only scoped on one descriptor
        // of the children as there's no need yet
        val childOnlyScopedVariables = mutableListOf<ScopedVariable>();
        val functionStepName = dynamicVariableDisplayPrefix + currentStepNum.toString();
        currentStepNum++;
        // pick the character right before 'a' so we can append at the beginning
        // it makes the code a bit easier to write
        var subPrefixChar = 'a' - 1;

        // create a copy of the current scope for this function
        // compute this in bulk between we generally need this for all functions, and
        // it's much cheaper in aggregate to compute this once than individually per function
        // and trace backwards every time.
        val currentScope = flattenedParents.plus(scopedVariables).toTypedArray()
        returnMap.put(f.id, FunctionEvalContext(functionStepName, currentScope));

        val funcDef = FunctionDescriptorMap.get(f.name)
        if (funcDef != null) {
          val bindingDescs = funcDef.getBindingDescriptors()
          for (bindingDesc in bindingDescs) {
            val binding = f.bindings?.find { b ->
              b.name == bindingDesc.name
            }
            if (bindingDesc.allowedBindingTypes.find {
                it.name == BindingType.Block.name} != null) {
              // all blocks get a prefix allocated regardless of whether there is a current
              // binding or not
              subPrefixChar++;
            }
            if (binding == null) {
              continue;
            }
            if (binding.bindingType == BindingType.VariableDeclaration.name) {
              val varDec = binding.variableDeclaration
              if (varDec != null) {

                val newVar = ScopedVariable(
                  VariableType.Variable.name,
                  varDec.name ?: "",
                  varDec.name ?: "",
                  BoundDataTypeImpl.Primitive(PrimitiveDataType.valueOf(varDec.dataType), false)
                );
                if (bindingDesc.scopedToChildOnly)
                  childOnlyScopedVariables.add(newVar) else
                  scopedVariables.add(newVar);
              }
            }
            if (binding.bindingType == BindingType.DynamicVariableDeclaration.name
            ) {
              val boundType = bindingDesc.getExpectedDataType(
                f,
                funcDef,
                bindingDesc,
                binding,
                currentScope
              )
              if (boundType != null) {
                var newVar =
                  ScopedVariable(
                    VariableType.DynamicVariable.name, f.id,
                    functionStepName, boundType)

                if (bindingDesc.scopedToChildOnly)
                  childOnlyScopedVariables.add(newVar) else
                  scopedVariables.add(newVar);
              }
            }

            var childFunctions: Array<CalculationFunction>? = null
            var stepPrefix = subPrefixChar.toString()
            if (binding.bindingType == BindingType.Block.name) {
              childFunctions = binding.block
            } else if (binding.bindingType == BindingType.Predicate.name) {
              childFunctions = binding.predicate?.functions
              // predicates don't have referencable steps but giving them a name
              // for consistency
              stepPrefix = "pred";
            }

            if (childFunctions != null) {
              createInternal(lobDef,
                childFunctions,
                stack.plusElement(scopedVariables).plusElement(childOnlyScopedVariables),
                functionStepName + stepPrefix + "_", returnMap
              )
            }
          }
        }
      }
    }

  }
}

