import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Button, Col, Flex, Modal, Row, Space} from 'antd';
import dayjs from 'dayjs';
import React, {useContext, useEffect, useState} from 'react';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {
  DateIsoStringToDisplayString,
  DayJsToIsoString,
} from '../../common/model/CommonUtils';
import UserContext from '../../user/context/UserContext';
import {ProductVersionNote} from '../model/Product';
import {ProductService} from '../service/ProductService';
import {EditNoteForm} from './EditNoteForm';

export interface ProductVersionNotesListProps {
  productVersionId: string | undefined;
  productService: ProductService;
  // there's some nonsense going on because we need to put the comments on the tab control
  // which is owned by the parent component.  The solution here is to render the tab as part of this
  // control so that this control can own the logic around the buttons
  tabControl: React.ReactElement;
  isAdaptifyUser: boolean;
  isReadOnly?: boolean;
}

export interface EditState {
  isNew: boolean;
  open: boolean;
  note: ProductVersionNote;
}

export function ProductVersionNotesList(props: ProductVersionNotesListProps) {
  const userContext = useContext(UserContext);
  const [editState, setEditState] = useState<EditState>({
    isNew: true,
    open: false,
    note: createNewNote(),
  });

  const [notes, setNotes] = useState<ProductVersionNote[]>([]);

  // used to trigger a refresh on the UI on change
  const [modifyCount, setModifyCount] = useState(0);

  useEffect(() => {
    if (!props.productVersionId) {
      setNotes([]);
      return;
    }
    const eff = async () => {
      const loaded =
        await props.productService.GetProductVersionNotesByProductVersionId(
          props.productVersionId || ''
        );
      setNotes(loaded);
    };
    eff();
  }, [props.productVersionId, modifyCount]);

  const colDefs: GridColDef<ProductVersionNote>[] = [
    {
      field: 'createdBy',
      headerName: 'User',
      flex: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Date Added',
      flex: 150,
      renderCell: params => {
        return DateIsoStringToDisplayString(params.value);
      },
    },
    {
      field: 'text',
      headerName: 'Note',
      flex: 600,
    },
    {
      field: 'visibility',
      headerName: 'Type',
      flex: 200,
    },
  ].filter(col => props.isAdaptifyUser || col.field !== 'visibility');
  // edit and delete functionality which is disabled for the time being due to
  // product requirements
  /*
  <Button
    type="link"
    disabled={!isMine}
    onClick={e => {
      e.preventDefault();
      setEditState({
        open: true,
        isNew: false,
        note: params.row as ProductVersionNote,
      });
    }}
  >
    Modify
  </Button>
  <Popconfirm
    title="Confirm deletion"
    description="Are you sure to delete?"
    onConfirm={() => onDelete(params.row)}
    okText="Delete"
    cancelText="Cancel"
  >
    <Button {...ButtonSettings} type="link" disabled={!isMine}>
      Delete
    </Button>
  </Popconfirm>
</span>
  */

  function createNewNote(): ProductVersionNote {
    return {
      ...NewTenantAwareEntity(),
      ...{createdBy: userContext, createdAt: DayJsToIsoString(dayjs())}, // for a new note, default to the current user
      text: '',
      visibility: 'Internal',
      productVersionId: props.productVersionId || '',
    };
  }

  function onCreateNew() {
    setEditState({isNew: true, open: true, note: createNewNote()});
  }

  async function onSaveNote(note: ProductVersionNote) {
    if (editState.isNew) {
      // clear the created by and created at fields that were set for display
      note.createdAt = '';
      note.createdBy = '';
    }
    await props.productService.UpdateProductVersionNote(note);
    setModifyCount(modifyCount + 1);
    setEditState({isNew: true, open: false, note: createNewNote()});
  }

  async function onDelete(note: ProductVersionNote) {
    await props.productService.DeleteProductVersionNote(note.id);
    setModifyCount(modifyCount + 1);
  }

  function onCancelNote() {
    setEditState({isNew: true, open: false, note: createNewNote()});
  }

  function canCreateNote() {
    return !props.isReadOnly && props.productVersionId !== '';
  }

  const createButton = canCreateNote() ? (
    <Button
      {...ButtonSettings}
      onClick={onCreateNew}
      disabled={props.productVersionId === ''}
    >
      Add Note
    </Button>
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical style={{width: '100%'}} className="gap-2">
        <Row>
          <Col span={16}>{props.tabControl}</Col>
          <Col span={8}>
            <Flex justify="end" align="start">
              {createButton}
            </Flex>
          </Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              rowSelection={false}
              columns={colDefs}
              rows={notes}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>
      <Modal
        className="adaptify-modal"
        width={'clamp(300px, 70svw, 800px)'}
        height="50%"
        footer={null}
        title={editState.isNew ? 'Add Note' : 'Edit Note'}
        open={editState.open}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
      >
        <EditNoteForm
          note={editState.note}
          onSave={onSaveNote}
          onCancel={onCancelNote}
          isAdaptifyUser={props.isAdaptifyUser}
        />
      </Modal>
    </>
  );
}
