import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {ADAPTIFY_TENANT_ID} from '../../common/service/Constants';
import {LobService} from '../../lob/service/LobService';
import {AuthManager} from '../../user/util/AuthManager';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductList} from '../control/ProductList';
import {ProductQueryForm} from '../control/ProductQuery';
import {ProductQuery, ProductSummary} from '../model/Product';
import {ProductService} from '../service/ProductService';

export interface ProductOverviewControlProps {
  lobService: LobService;
  productService: ProductService;
  valueListService: ValueListService;
  authToken: string;
  authManager: AuthManager;
  adaptifyTenantMode: boolean;
}

export function ProductOverviewControl(props: ProductOverviewControlProps) {
  const [modifyCount, setModifyCount] = useState<number>(0);
  const [productQuery, setProductQuery] = useState<ProductQuery>({});
  const [productList, setProductList] = useState<ProductSummary[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<
    ProductSummary | undefined
  >(undefined);

  useEffect(() => {
    setProductQuery({
      ...productQuery,
      tenantId: props.adaptifyTenantMode
        ? ADAPTIFY_TENANT_ID
        : props.authManager.GetTenantId(),
    });
    setModifyCount(modifyCount + 1);
  }, [props.adaptifyTenantMode]);

  useEffect(() => {
    const eff = async () => {
      // issue an initial query when the page is loaded
      await onQuery();
    };
    eff();
  }, [modifyCount]);

  function onProductSelectionChanged(selection: ProductSummary | undefined) {
    setSelectedProduct(selection);
  }

  function onModified() {
    setModifyCount(modifyCount + 1);
  }

  async function onQuery() {
    const products = await props.productService.QueryProduct(productQuery);
    setProductList(products);
  }

  return (
    <Flex vertical gap={24}>
      <ProductQueryForm
        valueListService={props.valueListService}
        productQuery={productQuery}
        setProductQuery={setProductQuery}
        onQuery={onQuery}
        authManager={props.authManager}
        adaptifyTenantMode={props.adaptifyTenantMode}
      />
      <div style={{height: '100%'}}>
        <ProductList
          lobService={props.lobService}
          authToken={props.authToken}
          productService={props.productService}
          valueListService={props.valueListService}
          productChanged={onModified}
          selection={selectedProduct}
          selectionChanged={onProductSelectionChanged}
          products={productList}
          readOnly={props.adaptifyTenantMode}
        />
      </div>
    </Flex>
  );
}
