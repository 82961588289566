import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {RatingService} from '../service/RatingService';
import {ProductVersionRiskFlowControl} from './ProductVersionRiskFlowControl';

export interface ProductVersionRiskFlowPageProps extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authToken: string;
}

export function ProductVersionRiskFlowPage(
  props: ProductVersionRiskFlowPageProps
) {
  const {productVersionId} = useParams<string>();
  if (typeof productVersionId !== 'string') {
    return <></>;
  }

  const [searchParams, setSearchParams] = useSearchParams();

  function onRiskFlowSelected(
    riskId: string | undefined,
    flowId: string | undefined
  ) {
    if (riskId && flowId) {
      setSearchParams({riskId: riskId, flowId: flowId}, {replace: true});
    }
  }

  return (
    <ProductVersionRiskFlowControl
      lobService={props.lobService}
      productService={props.productService}
      ratingService={props.ratingService}
      productVersionId={productVersionId as string}
      initialSelectedRiskId={searchParams.get('riskId') || undefined}
      initialSelectedFlowId={searchParams.get('flowId') || undefined}
      authToken={props.authToken}
      onFlowSelected={onRiskFlowSelected}
    />
  );
}
