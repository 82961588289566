import {
  Button,
  Col,
  Flex,
  Modal,
  Popconfirm,
  Row,
  Space,
  Typography,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings} from '../../common/control/Common';
import {
  LineOfBusinessItem,
  LobItemCardinality,
  LobItemInfo,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {DataGridSettings} from '../../common/control/Common';
import {EditCoverageForm} from './EditCoverageForm';

export interface LobCoverageListProps {
  lobService: LobService;
  parentLobItemId: string | undefined;
  lobHierarchyId: string | undefined;
  selection: string | undefined;
  selectionChanged: (selection: string | undefined) => void;
  modifyCount: number;
  setModifyCount: (count: number) => void;
  readOnly?: boolean;
  disableDrilldown?: boolean;
}

export interface EditState {
  isNew: boolean;
  isEditing: boolean;
  lobItemId?: string;
  openCount: number;
}

export function LobCoverageList(props: LobCoverageListProps) {
  const [editState, setEditState] = useState<EditState>({
    isNew: true,
    isEditing: false,
    openCount: 0,
  });
  const [coverages, setCoverages] = useState<LobItemInfo[]>([]);

  useEffect(() => {
    if (
      !props.lobHierarchyId ||
      props.lobHierarchyId === '' ||
      !props.parentLobItemId ||
      props.parentLobItemId === ''
    ) {
      setCoverages([]);
      return;
    }
    const eff = async () => {
      const coverages =
        await props.lobService.GetLineOfBusinessCoveragesByParentRiskId(
          props.parentLobItemId ?? ''
        );
      // sort them locally for now
      // TODO move sort to the server
      coverages.sort((a, b) => a.name.localeCompare(b.name));
      setCoverages(coverages);
      if (coverages.length > 0 && !props.selection) {
        props.selectionChanged(coverages[0].id);
      }
    };
    eff();
  }, [props.parentLobItemId, props.modifyCount]);

  const allColDefs: GridColDef<LobItemInfo>[] = [
    {
      field: 'parentLineOfBusinessItemName',
      headerName: 'Risk Item',
      flex: 300,
    },
    {
      field: 'name',
      headerName: 'Coverage Name',
      flex: 300,
      renderCell: params => {
        return props.disableDrilldown ? (
          <Typography.Text>{params.row.name}</Typography.Text>
        ) : (
          <Link
            to={`/platform/lob/${props.lobHierarchyId}/coverage/field?coverageId=${params.row.id}`}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: 'cardinality',
      headerName: 'Cardinality',
      valueGetter: (value, row) => {
        const cardinality = row?.cardinality;
        if (cardinality === LobItemCardinality.Single.name) {
          return 'Single';
        }
        if (cardinality === LobItemCardinality.Many.name) {
          return 'Multi';
        }
        return '';
      },
      flex: 150,
    },
  ];

  const colDefs = props.readOnly
    ? allColDefs.filter(col => col.field !== 'actions')
    : allColDefs;

  function onOpenCreateDialog() {
    setEditState({
      isNew: true,
      isEditing: true,
      openCount: editState.openCount + 1,
    });
  }

  function onOpenEditDialog() {
    const sel = getSelectedRisk();
    if (!sel) {
      return;
    }
    setEditState({
      isNew: false,
      isEditing: true,
      lobItemId: sel.id,
      openCount: editState.openCount + 1,
    });
  }

  async function onDelete() {
    const sel = getSelectedRisk();
    if (!sel) {
      return;
    }
    await props.lobService.DeleteLineOfBusinessItem(sel.id);
    props.setModifyCount(props.modifyCount + 1);
  }

  async function onSaveLineOfBusinessEntity(value: LineOfBusinessItem) {
    setEditState({
      ...editState,
      isEditing: false,
    });
    props.selectionChanged(value.id);
    props.setModifyCount(props.modifyCount + 1);
  }

  function onCancelLineOfBusinessEntity() {
    setEditState({
      ...editState,
      isEditing: false,
    });
  }

  const deleteButton = (
    <Popconfirm
      title="Confirm deletion"
      description="Are you sure to want to delete?"
      onConfirm={onDelete}
      disabled={!getSelectedRisk()}
      okText="Delete"
      cancelText="Cancel"
    >
      <Button
        {...ButtonSettings}
        disabled={!getSelectedRisk()?.parentLineOfBusinessItemId}
      >
        Delete
      </Button>
    </Popconfirm>
  );

  const editButtons = (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      <Button
        {...ButtonSettings}
        icon={<DriveFileRenameOutlineOutlinedIcon className="-m-1" />}
        onClick={onOpenEditDialog}
        disabled={!getSelectedRisk()?.parentLineOfBusinessItemId}
      >
        Modify
      </Button>
      <Button
        {...ButtonSettings}
        icon={<AddOutlinedIcon className="-m-1" />}
        onClick={onOpenCreateDialog}
        disabled={props.lobHierarchyId === ''}
      >
        Add Coverage
      </Button>
    </Flex>
  );

  const buttons = props.readOnly ? <></> : editButtons;

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    const selection: LineOfBusinessItem | undefined = undefined;
    if (rowSelectionModel.length !== 0) {
      props.selectionChanged(rowSelectionModel[0] as string);
    } else {
      props.selectionChanged(undefined);
    }
  }

  function getSelectedRisk() {
    if (props.selection) {
      return coverages.find(r => r.id === props.selection);
    }
    return undefined;
  }

  return (
    <>
      <Flex vertical style={{width: '100%', height: '100%'}}>
        <Row>
          <Col span={18}>
            <div className="page-title">Coverages</div>
          </Col>
          <Col span={6}>{buttons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%', height: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={coverages}
              rowSelectionModel={props.selection ? [props.selection] : []}
              onRowSelectionModelChange={onTableSelectionChanged}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>
      <Modal
        className="adaptify-modal"
        title={editState.isNew ? 'Add Coverage' : 'Edit Coverage'}
        open={editState.isEditing}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
      >
        <EditCoverageForm
          lobService={props.lobService}
          lobId={props.lobHierarchyId}
          existingItemId={editState.lobItemId}
          defaultRiskId={props.parentLobItemId}
          onSave={onSaveLineOfBusinessEntity}
          onCancel={onCancelLineOfBusinessEntity}
          formOpenCount={editState.openCount}
        />
      </Modal>
    </>
  );
}
