package com.adaptify.rating.admin.model.calculation.binding

import com.adaptify.rating.admin.model.ModelUtil
import com.adaptify.rating.admin.model.impl.util.DeepCopyable
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlin.js.JsExport

expect interface TableBinding {
    var tableName: String?
    var matchBindings: Array<Binding>?  // this will replace match columns after migrating old data
    var resultColumn: String?
}

expect fun createTableBinding(
    tableName: String? = null,
    matchBindings: Array<Binding>?  = null,
    resultColumn: String? = null,
): TableBinding

@Serializable
class TableBindingImpl(
    override var tableName: String? = null,
    override var matchBindings: Array<Binding>? = null,
    override var resultColumn: String? = null,
) : TableBinding

object TableBindingUtil : ModelUtil<TableBinding> {
    fun create(
        tableName: String? = null,
        matchBindings: Array<Binding>? = null,
        resultColumn: String? = null,
    ): TableBinding {
        return createTableBinding(tableName, matchBindings, resultColumn)
    }

    override fun deepCopy(obj: TableBinding): TableBinding {
        return create(
            obj.tableName,
            obj.matchBindings?.map { BindingUtil.deepCopy(it) }?.toTypedArray(),
            obj.resultColumn,
        )
    }
}
