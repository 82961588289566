package com.adaptify.rating.admin.model.calculation.binding

@JsExport
external actual interface TableBinding {
    actual var tableName: String?
    actual var matchBindings: Array<Binding>?  // this will replace match columns after migrating old data
    actual var resultColumn: String?
}

actual fun createTableBinding(
  tableName: String?,
  matchBindings: Array<Binding>?,
  resultColumn: String?,
): TableBinding {
  return js("({tableName: tableName, matchBindings: matchBindings, resultColumn: resultColumn})")
}

