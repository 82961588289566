import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {RatingService} from '../service/RatingService';
import {ProductVersionTestCaseDetailControl} from './ProductVersionTestCaseDetailControl';

export interface ProductVersionTestCaseDetailPageProps extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
}

export function ProductVersionTestCaseDetailPage(
  props: ProductVersionTestCaseDetailPageProps
) {
  const {testCaseId} = useParams<string>();
  if (typeof testCaseId !== 'string') {
    return <></>;
  }

  return (
    <ProductVersionTestCaseDetailControl
      lobService={props.lobService}
      productService={props.productService}
      ratingService={props.ratingService}
      testCaseId={testCaseId}
    />
  );
}
