package com.adaptify.rating.admin.model.util

import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.context.VariableType
import com.adaptify.rating.admin.model.calculation.descriptor.BoundDataType
import com.adaptify.rating.admin.model.calculation.descriptor.BoundDataTypeImpl
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlin.js.JsExport

@JsExport

class PathResolver {

  fun ResolveDataTypeForRootPath(path : String?,
    lobHierarchy: LineOfBusinessHierarchy,
                                 globals: Array<ScopedVariable>) : BoundDataType? {
    val root = lobHierarchy.risks.firstOrNull();
    if (root == null) {
      return null;
    }

    val lobRootScopedVariable =  ScopedVariable(VariableType.Input.name,
      root.name, root.name,  BoundDataTypeImpl.LobItem(root, false));

    val allVars = ((globals ?: emptyArray()).asList() + lobRootScopedVariable).toTypedArray();
    return ResolveDataTypeForPath(path, allVars);
  }

  fun ResolveDataTypeForPath(path: String?,
                             scope: Array<ScopedVariable>) : BoundDataType? {
    val pathComponents = path?.split('.') ?: emptyList()
    if (pathComponents.isEmpty()) {
      return null
    }

    // root
    // first path component is the root, but resolving the root data type
    // might be recursive
    // don't call GetRootsForPathPicker in this function because GetRootsForPathPicker
    // will eventually call this causing circular infinite recursion

    // if a root property is resolvable, it must be an input or a variable declaration
    // check the inputs first as they are cheapest, then get all the variable decs in scope
    val rootPath = pathComponents[0]
    val rest = pathComponents.drop(1)
    val rootVar = scope.find { v -> v.name == rootPath }
    if (rootVar == null) {
      return null
    }

    // root
    if (rootVar.boundDataType.primitive != null) {
      if (rest.isEmpty()) {
        // if this resolves to a primitive type, it needs to be the final part of the path
        return rootVar.boundDataType;
      }
      return null
    }

    else if (rootVar.boundDataType.lobItem != null) {
      return resolvePath(
        rootVar.boundDataType.lobItem!!,
        rootVar.boundDataType.isMany,
        rest
      )
    }
    return null;
  }

  @JsExport.Ignore
  fun resolvePath(
    currentLineOfBusinessItem: LineOfBusinessHierarchyItem,
    isMany:  Boolean,
    pathComponents: List<String>,
  ): BoundDataType? {
    if (pathComponents.isEmpty()) {
      // this may be null if for example we resolve an empty path on the root
      return BoundDataTypeImpl.LobItem(currentLineOfBusinessItem, isMany)
    }

    val currentPath = pathComponents[0]
    val restPath = pathComponents.drop(1)

    val child = currentLineOfBusinessItem.children.find { it.name == currentPath }
    if (child != null) {
      // recurisvely go to the next level
      return resolvePath(
        child, (isMany || child.isMany), restPath,
      )
    }
    if (!restPath.isEmpty()) {
      return null
    }

    val field = currentLineOfBusinessItem.fields.find { it.name == currentPath }
    if (field?.dataType != null) {
      return BoundDataTypeImpl.Primitive(PrimitiveDataType.valueOf(field.dataType), isMany)
    }

    return null
  }
}

@JsExport
fun CreatePathResolver() : PathResolver {
  return PathResolver();
}
