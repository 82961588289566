package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType

// IF used within calculations have different behavior than if statements used in branches (sub process nodes)
// this is per product requirements, this is the version to use in calculations
object IfStatementDescriptor : FunctionDescriptor("IF_STATEMENT", "IF", FunctionType.Function, "Logic") {

  val predicateDescriptor = object : BindingDescriptor(
    "predicate", "WHEN", arrayOf(BindingType.Predicate),
    null
  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.Predicate);
    }

    override fun shouldRenderBlockHeader(): Boolean {
      return false
    }
  }
  val thenDescriptor = BindingDescriptor(
    "then", "THEN", arrayOf(BindingType.Block),
    null
  )
  val elseDescriptor = BindingDescriptor(
    "else", "ELSE", arrayOf(BindingType.Block),
    null
  )

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(predicateDescriptor, thenDescriptor, elseDescriptor)

  override fun getDisplayTemplate(
  ): String = "IF" // blocks have special non-configurable rendering
}
