import {Button, Col, Flex, Modal, Row} from 'antd';
import React from 'react';
import {ButtonSettings} from './Common';

// replace popconfirm so that we can style it
export interface ConfirmationModalProps {
  open: boolean;
  title?: string;
  message: string;
  onOk: () => void | Promise<void>;
  onCancel: () => void;
}

export function ConfirmationModal(props: ConfirmationModalProps) {
  return (
    <Modal
      className="adaptify-modal"
      title={props.title ?? ''}
      destroyOnClose={true}
      closable={false}
      open={props.open}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'600px'}
      height={'400px'}
    >
      <Row gutter={[20, 30]} style={{width: '100%', height: '100%'}}>
        <Col span={24}>
          <Flex justify="start" style={{width: '100%', textAlign: 'center'}}>
            {props.message}
          </Flex>
        </Col>
        <Col span={24}>
          <Flex justify="end" className="gap-2">
            <Button
              {...ButtonSettings}
              htmlType="button"
              onClick={props.onCancel}
            >
              Cancel
            </Button>
            <Button
              ghost={false}
              type="default"
              htmlType="button"
              onClick={async () => {
                const result = props.onOk();
                if (result instanceof Promise) {
                  await result;
                }
              }}
            >
              OK
            </Button>
          </Flex>
        </Col>
      </Row>
    </Modal>
  );
}
