package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType


object MonthsBetweenDescriptor : FunctionDescriptor("MONTHS_BETWEEN", "MONTHS_BETWEEN", FunctionType.Function, "Math") {

  val assignToDescriptor = BindingDescriptor(
    "assignTo", "Assign To", BindingDescriptor.standardOutputBindingTypes,
    BindingDescriptor.numberDataType
  )
  val leftDescriptor = BindingDescriptor(
    "left", "Left", BindingDescriptor.standardInputBindingTypes,
    BindingDescriptor.dateDataType
  )
  val rightDescriptor = BindingDescriptor(
    "right", "Right", BindingDescriptor.standardInputBindingTypes,
    BindingDescriptor.dateDataType
  )

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(assignToDescriptor, leftDescriptor, rightDescriptor)

  override fun getDisplayTemplate(
  ): String = "\$assignTo = MONTHS_BETWEEN(\$left, \$right)"
}
