import {decodeToken, isExpired} from 'react-jwt';
import {Cookie, CookieSetOptions} from 'universal-cookie';
import UserService from '../service/UserService';

export interface AuthManagerArgs {
  authCookie: any;
  setAuthCookie: (
    name: 'adaptify_auth_token',
    value: Cookie,
    options?: CookieSetOptions
  ) => void;
  authChanged: (token: string, userLogout: boolean) => void;
}

interface JwtTokenContents {
  exp: number;
  iat: number;
  sub: string;
  tenant_id: string;
}

export interface AuthManager {
  SetAuthToken: (token: string) => void;
  GetAuthCookieValue: () => string;
  IsLoggedIn: () => boolean;
  Logout: () => void;
  GetLoginUserId: () => string | undefined;
  GetTenantId: () => string | undefined;
  CreateTokenRefreshingServiceProxy: <T extends object>(service: T) => T;
}

export default function NewAuthManager(args: AuthManagerArgs): AuthManager {
  function SetAuthToken(token: string) {
    args.setAuthCookie('adaptify_auth_token', token, {path: '/'});
    // changing state will force a rerender
    args.authChanged(token, false);
  }

  function GetAuthCookieValue() {
    return args.authCookie?.adaptify_auth_token || '';
  }

  function IsLoggedIn() {
    if (GetAuthCookieValue() === '') {
      return false;
    }

    const expired = isExpired(GetAuthCookieValue());
    // is the token is expired obviously treat this as not logged in
    return !expired;
  }

  function Logout() {
    SetAuthToken('');
    args.authChanged('', true);
  }

  function CreateTokenRefreshingServiceProxy<T extends object>(service: T): T {
    return new Proxy(service, {
      get: (target, prop) => {
        /// if the function returns a promise, assume we can piggy back on a token refresh call to server
        const targetProp = target[prop as keyof T];
        if (typeof targetProp === 'function') {
          return async (...args: any[]) => {
            // binding is necessary to get an instance method to have the right this context
            const result = (targetProp as Function).bind(target)(...args);
            if (result instanceof Promise) {
              // try to refresh token as fire and forget, log and ignore erros
              result
                .then(() => checkAndRefreshToken())
                .catch(e => {
                  console.log(e);
                });
            }
            return result;
          };
        }
        return targetProp;
      },
    });
  }

  function GetLoginUserId(): string | undefined {
    if (!IsLoggedIn()) {
      return undefined;
    }

    // if token has already expired no sense in trying to refresh
    if (isExpired(GetAuthCookieValue())) {
      return undefined;
    }

    const decoded = decodeToken<JwtTokenContents>(GetAuthCookieValue());
    if (!decoded) {
      return undefined;
    }
    return decoded.sub;
  }

  function GetTenantId(): string | undefined {
    if (!IsLoggedIn()) {
      return undefined;
    }

    // if token has already expired no sense in trying to refresh
    if (isExpired(GetAuthCookieValue())) {
      return undefined;
    }

    const decoded = decodeToken<JwtTokenContents>(GetAuthCookieValue());
    if (!decoded) {
      return undefined;
    }
    return decoded.tenant_id;
  }

  async function checkAndRefreshToken() {
    if (!IsLoggedIn()) {
      return;
    }

    // if token has already expired no sense in trying to refresh
    if (isExpired(GetAuthCookieValue())) {
      return;
    }

    const decoded = decodeToken<JwtTokenContents>(GetAuthCookieValue());
    if (!decoded) {
      return;
    }

    const now = new Date().getTime() / 1000;
    const halfLife = (decoded.exp - decoded.iat) / 2 + decoded.iat;
    if (now > halfLife) {
      // if we are past the half life of the token, refresh it
      try {
        const newToken = await UserService(GetAuthCookieValue()).RefreshToken();

        SetAuthToken(newToken);
      } catch (e) {
        // ignore errors from behind the scenes refresh
        console.log(e);
      }
    }
  }
  return {
    SetAuthToken,
    GetAuthCookieValue,
    IsLoggedIn,
    Logout,
    CreateTokenRefreshingServiceProxy,
    GetLoginUserId,
    GetTenantId,
  };
}
