import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Modal, Popconfirm, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {
  LineOfBusinessItem,
  LobItemCardinality,
  LobItemInfo,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';
import {EditLobRiskForm} from './EditLobRiskForm';

export interface LobRiskListProps {
  lobService: LobService;
  lobDefId: string;
  selection: string | undefined;
  selectionChanged: (selection: string | undefined) => void;
  readonly?: boolean;
  modifyCount: number;
  setModifyCount: (count: number) => void;
}

export interface EditState {
  isNew: boolean;
  isEditing: boolean;
  lobItemId?: string;
  openCount: number;
}

export function LobRiskList(props: LobRiskListProps) {
  const [editState, setEditState] = useState<EditState>({
    isNew: false,
    isEditing: false,
    openCount: 0,
  });
  const [risks, setRisks] = useState<LobItemInfo[]>([]);

  useEffect(() => {
    if (props.lobDefId === '') {
      setRisks([]);
      return;
    }
    const eff = async () => {
      const risks = await props.lobService.GetLineOfBusinessRisks(
        props.lobDefId
      );
      setRisks(risks);
      if (risks.length > 0 && !props.selection) {
        props.selectionChanged(risks[0].id);
      }
    };
    eff();
  }, [props.lobDefId, props.modifyCount]);

  const allColDefs: GridColDef<LobItemInfo>[] = [
    {
      field: 'parentLineOfBusinessItemName',
      headerName: 'Parent Risk Item',
      flex: 300,
    },
    {
      field: 'name',
      headerName: 'Risk Item Name',
      flex: 300,
      renderCell: params => {
        return (
          <Link
            to={`/platform/lob/${props.lobDefId}/risk/field?lobItemId=${params.row.id}`}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: 'cardinality',
      headerName: 'Cardinality',
      valueGetter: (value, row) => {
        const cardinality = row?.cardinality;
        if (cardinality === LobItemCardinality.Single.name) {
          return 'Single';
        }
        if (cardinality === LobItemCardinality.Many.name) {
          return 'Multi';
        }
        return '';
      },
      flex: 150,
    },
  ];

  const colDefs = props.readonly
    ? allColDefs.filter(col => col.field !== 'actions')
    : allColDefs;

  function onOpenCreateDialog() {
    setEditState({
      isNew: true,
      isEditing: true,
      openCount: editState.openCount + 1,
    });
  }

  function onOpenEditDialog() {
    const sel = getSelectedRisk();
    if (!sel) {
      return;
    }
    setEditState({
      isNew: true,
      isEditing: true,
      lobItemId: sel.id,
      openCount: editState.openCount,
    });
  }

  async function onDelete() {
    const sel = getSelectedRisk();
    if (!sel) {
      return;
    }
    await props.lobService.DeleteLineOfBusinessItem(sel.id);
    props.setModifyCount(props.modifyCount + 1);
  }

  async function onSaveLineOfBusinessEntity(value: LineOfBusinessItem) {
    setEditState({
      ...editState,
      isEditing: false,
    });
    props.setModifyCount(props.modifyCount + 1);
  }

  function onCancelLineOfBusinessEntity() {
    setEditState({
      ...editState,
      isEditing: false,
    });
  }

  const deleteButton = (
    <Popconfirm
      title="Confirm deletion"
      description="Are you sure to want to delete?"
      onConfirm={onDelete}
      disabled={!getSelectedRisk()}
      okText="Delete"
      cancelText="Cancel"
    >
      <Button
        {...ButtonSettings}
        disabled={!getSelectedRisk()?.parentLineOfBusinessItemId}
      >
        Delete
      </Button>
    </Popconfirm>
  );

  const editButtons = (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      <Button
        {...ButtonSettings}
        onClick={onOpenEditDialog}
        disabled={!getSelectedRisk()?.parentLineOfBusinessItemId}
      >
        Modify
      </Button>
      <Button
        {...ButtonSettings}
        onClick={onOpenCreateDialog}
        disabled={props.lobDefId === ''}
      >
        Add Risk Item
      </Button>
    </Flex>
  );

  const buttons = props.readonly ? <></> : editButtons;

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    const selection: LineOfBusinessItem | undefined = undefined;
    if (rowSelectionModel.length !== 0) {
      props.selectionChanged(rowSelectionModel[0] as string);
    } else {
      props.selectionChanged(undefined);
    }
  }

  function getSelectedRisk() {
    if (props.selection) {
      return risks.find(r => r.id === props.selection);
    }
    return undefined;
  }

  return (
    <>
      <Flex vertical style={{width: '100%', height: '100%'}}>
        <Row>
          <Col span={18}>
            <div className="page-title">Risk Items</div>
          </Col>
          <Col span={6}>{buttons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%', height: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={risks}
              rowSelectionModel={props.selection ? [props.selection] : []}
              onRowSelectionModelChange={onTableSelectionChanged}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>
      <Modal
        className="adaptify-modal"
        title={editState.isNew ? 'Add Risk' : 'Edit Risk'}
        open={editState.isEditing}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
      >
        <EditLobRiskForm
          lobService={props.lobService}
          lobId={props.lobDefId}
          existingItemId={editState.lobItemId}
          onSave={onSaveLineOfBusinessEntity}
          onCancel={onCancelLineOfBusinessEntity}
          formOpenCount={editState.openCount}
        />
      </Modal>
    </>
  );
}
