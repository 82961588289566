import React from 'react';
import {NavigationAware} from '../../NavigationAware';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {LobService} from '../service/LobService';
import {LobHierarchyControl} from './LobHierarchyControl';

export interface LobHierarchyPageProps extends NavigationAware {
  lobService: LobService;
  valueListService: ValueListService;
}
export function LobHierarchyPage(props: LobHierarchyPageProps) {
  return (
    <LobHierarchyControl
      lobService={props.lobService}
      valueListService={props.valueListService}
    />
  );
}
