import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductService} from '../service/ProductService';
import {ProductDetailControl} from './ProductDetailControl';

export interface ProductDetailPageProps extends NavigationAware {
  productService: ProductService;
  valueListService: ValueListService;
}

type ProductDetailPageParams = {
  productId: string;
};

export function ProductDetailPage(props: ProductDetailPageProps) {
  const {productId} = useParams<ProductDetailPageParams>();
  return (
    <ProductDetailControl
      productService={props.productService}
      valueListService={props.valueListService}
      productId={productId}
    />
  );
}
