import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import 'typeface-roboto';
import App from './App';
import './index.scss';
// import reportWebVitals from './reportWebVitals';
import * as Ant from 'antd';
import {useCookies} from 'react-cookie';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoginPage from './user/login/LoginPage';

import {NavigationLocations} from './NavigationAware';
import {ChatAssistantPage} from './chat/page/ChatAssistantPage';
import {NewChatService} from './chat/service/ChatService';
import {AnalysisStudyDetailPage} from './comparative-analysis/page/AnalysisStudyDetailPage';
import {AnalysisStudyListPage} from './comparative-analysis/page/AnalysisStudyListPage';
import {CreateTableComparisonPage} from './comparative-analysis/page/CreateTableComparisonPage';
import {TableComparisonDetailPage} from './comparative-analysis/page/TableComparisonDetailPage';
import NewAnalysisService from './comparative-analysis/service/AnalysisService';
import {LobCoverageFieldPage} from './lob/page/LobCoverageFieldPage';
import {LobHierarchyPage} from './lob/page/LobHierarchyPage';
import {LobItemPage} from './lob/page/LobItemPage';
import {LobRiskFieldPage} from './lob/page/LobRiskFieldPage';
import NewLobService from './lob/service/LobService';
import {ProductDetailPage} from './product/page/ProductDetailPage';
import {ProductOverviewPage} from './product/page/ProductOverviewPage';
import {ProductVersionDetailPage} from './product/page/ProductVersionDetailPage';
import NewProductService from './product/service/ProductService';
import {ProductVersionCoveragePage} from './rating/page/ProductVersionCoveragePage';
import {ProductVersionPremiumOrchestrationPage} from './rating/page/ProductVersionPremiumOrchestrationPage';
import {ProductVersionRiskFlowPage} from './rating/page/ProductVersionRiskFlowPage';
import {ProductVersionTablePage} from './rating/page/ProductVersionTablePage';
import {ProductVersionTestCaseDetailPage} from './rating/page/ProductVersionTestCaseDetailPage';
import NewRatingService from './rating/service/RatingService';
import UserContext from './user/context/UserContext';
import {TenantSummaryPage} from './user/page/TenantSummaryPage';
import NewTenantService from './user/service/TenantService';
import NewUserService from './user/service/UserService';
import NewAuthManager from './user/util/AuthManager';
import {ValueListDefinitionPage} from './valuelist/page/ValueListDefinitionPage';
import {ValueListItemPage} from './valuelist/page/ValueListItemPage';
import {NewValueListService} from './valuelist/service/ValueListService';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export default function MyApp() {
  // TODO move this logic out of index and into App.tsx, which requires refactoring these routes out of this file

  const [authCookie, setAuthCookie] = useCookies(['adaptify_auth_token']);

  const [authTokenCounter, setAuthTokenCounter] = useState(0);
  // if we aren't authorized on initial render, show login screen, otherwise show a login popup
  // so work isn't lost if the token expires

  const authManager = NewAuthManager({
    authCookie,
    setAuthCookie,
    authChanged: (token: string, userLogout: boolean) => {
      setAuthTokenCounter(authTokenCounter + 1);
      if (userLogout) {
        // if the user logged out, show the login page instead of rending the app UI and login as popup
        setShowLoginPage(true);
      } else if (token !== '') {
        // if the user logged in, hide the login page and show subsequent login as popup
        setShowLoginPage(false);
      }
    },
  });

  const [showLoginPage, setShowLoginPage] = useState(!authManager.IsLoggedIn());

  const lobService = authManager.CreateTokenRefreshingServiceProxy(
    NewLobService(authManager.GetAuthCookieValue())
  );

  const productService = authManager.CreateTokenRefreshingServiceProxy(
    NewProductService(authManager.GetAuthCookieValue())
  );

  const ratingService = authManager.CreateTokenRefreshingServiceProxy(
    NewRatingService(authManager.GetAuthCookieValue())
  );

  const analysisService = authManager.CreateTokenRefreshingServiceProxy(
    NewAnalysisService(authManager.GetAuthCookieValue())
  );

  const valueListService = authManager.CreateTokenRefreshingServiceProxy(
    NewValueListService(authManager.GetAuthCookieValue())
  );

  const tenantService = authManager.CreateTokenRefreshingServiceProxy(
    NewTenantService(authManager.GetAuthCookieValue())
  );

  const userService = authManager.CreateTokenRefreshingServiceProxy(
    NewUserService(authManager.GetAuthCookieValue())
  );

  const chatService = authManager.CreateTokenRefreshingServiceProxy(
    NewChatService(authManager.GetAuthCookieValue())
  );
  const loggedInRoutes = (
    <>
      <UserContext.Provider value={authManager.GetLoginUserId()}>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginPage
                storeAuthToken={authManager.SetAuthToken}
                redirectToUrl="/"
              />
            }
          />
          {/* this is redundant with the platform route, should be cleaned up later to organize better*/}
          <Route
            path="/"
            element={
              <App
                authToken={authManager.GetAuthCookieValue()}
                setAuthToken={authManager.SetAuthToken}
                logout={authManager.Logout}
                title="PLATFORM ADMINISTRATION"
                authManager={authManager}
              />
            }
          >
            <Route
              path="/"
              element={
                <ProductOverviewPage
                  lobService={lobService}
                  productService={productService}
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.Rating}
                  authToken={authManager.GetAuthCookieValue()}
                  authManager={authManager}
                  adaptifyTenantMode={false}
                />
              }
            />
          </Route>
          <Route
            path="/platform"
            element={
              <App
                authToken={authManager.GetAuthCookieValue()}
                setAuthToken={authManager.SetAuthToken}
                logout={authManager.Logout}
                title="PLATFORM ADMINISTRATION"
                authManager={authManager}
              />
            }
          >
            <Route
              path="/platform/lob/hierarchy"
              element={
                <LobHierarchyPage
                  lobService={lobService}
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.LOB}
                />
              }
            />
            <Route
              path="/platform/lob/:lobHierarchyId/risk/field/"
              element={
                <LobRiskFieldPage
                  lobService={lobService}
                  currentNavigation={NavigationLocations.LOB}
                />
              }
            />
            <Route
              path="/platform/lob/:lobDefId/risk/"
              element={
                <LobItemPage
                  lobService={lobService}
                  currentNavigation={NavigationLocations.LOB}
                />
              }
            />
            <Route
              path="/platform/lob/:lobHierarchyId/coverage/field"
              element={
                <LobCoverageFieldPage
                  lobService={lobService}
                  currentNavigation={NavigationLocations.LOB}
                />
              }
            />
            <Route
              path="/platform"
              element={
                <ProductOverviewPage
                  lobService={lobService}
                  productService={productService}
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.Rating}
                  authToken={authManager.GetAuthCookieValue()}
                  authManager={authManager}
                  adaptifyTenantMode={false}
                />
              }
            />
            <Route
              path="/platform/chat"
              element={<ChatAssistantPage chatService={chatService} />}
            />
            <Route
              path="/platform/product/summary/adaptify"
              element={
                <ProductOverviewPage
                  lobService={lobService}
                  productService={productService}
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.Rating}
                  authToken={authManager.GetAuthCookieValue()}
                  authManager={authManager}
                  adaptifyTenantMode={true}
                />
              }
            />
            <Route
              path="/platform/product/summary/my"
              element={
                <ProductOverviewPage
                  lobService={lobService}
                  productService={productService}
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.Rating}
                  authToken={authManager.GetAuthCookieValue()}
                  authManager={authManager}
                  adaptifyTenantMode={false}
                />
              }
            />
            <Route
              path="/platform/product/:productId/detail"
              element={
                <ProductDetailPage
                  productService={productService}
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/platform/productversion/:productId/detail"
              element={
                <ProductVersionDetailPage
                  productService={productService}
                  valueListService={valueListService}
                  ratingService={ratingService}
                  authManager={authManager}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/platform/valuelist/summary"
              element={
                <ValueListDefinitionPage
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.Lists}
                />
              }
            />
            <Route
              path="/platform/valuelist/:valueDefinitionId/item"
              element={
                <ValueListItemPage
                  valueListService={valueListService}
                  currentNavigation={NavigationLocations.Lists}
                />
              }
            />
            <Route
              path="/platform/user/summary"
              element={
                <TenantSummaryPage
                  tenantService={tenantService}
                  userService={userService}
                  currentNavigation={NavigationLocations.Users}
                />
              }
            />
          </Route>
          <Route
            path="/rating"
            element={
              <App
                authToken={authManager.GetAuthCookieValue()}
                setAuthToken={authManager.SetAuthToken}
                logout={authManager.Logout}
                title="ADAPTIFY RATING EDITOR"
                authManager={authManager}
              />
            }
          >
            <Route
              path="/rating/product/version/:productVersionId/table"
              element={
                <ProductVersionTablePage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authToken={authManager.GetAuthCookieValue()}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/coverage"
              element={
                <ProductVersionCoveragePage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authToken={authManager.GetAuthCookieValue()}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/risk"
              element={
                <ProductVersionRiskFlowPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  authToken={authManager.GetAuthCookieValue()}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/rating/product/version/:productVersionId/orchestration"
              element={
                <ProductVersionPremiumOrchestrationPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
            <Route
              path="/rating/testcase/:testCaseId/detail"
              element={
                <ProductVersionTestCaseDetailPage
                  lobService={lobService}
                  productService={productService}
                  ratingService={ratingService}
                  currentNavigation={NavigationLocations.Rating}
                />
              }
            />
          </Route>
          <Route
            path="/analysis"
            element={
              <App
                authToken={authManager.GetAuthCookieValue()}
                setAuthToken={authManager.SetAuthToken}
                logout={authManager.Logout}
                title="COMPARATIVE ANALYSIS"
                authManager={authManager}
              />
            }
          >
            <Route
              path="/analysis/study/list"
              element={
                <AnalysisStudyListPage
                  analysisService={analysisService}
                  productService={productService}
                  lobService={lobService}
                  valueListService={valueListService}
                  authToken={authManager.GetAuthCookieValue()}
                />
              }
            />
            <Route
              path="/analysis/study/:studyId/detail"
              element={
                <AnalysisStudyDetailPage
                  analysisService={analysisService}
                  productService={productService}
                  valueListService={valueListService}
                  lobService={lobService}
                />
              }
            />
            <Route
              path="/analysis/study/:studyId/createtablecomparison"
              element={
                <CreateTableComparisonPage
                  analysisService={analysisService}
                  productService={productService}
                />
              }
            />
            <Route
              path="/analysis/studytablecomparison/:tableComparisonId/detail"
              element={
                <TableComparisonDetailPage
                  analysisService={analysisService}
                  productService={productService}
                />
              }
            />
          </Route>
        </Routes>
      </UserContext.Provider>
    </>
  );

  const loggedOutRoutes = (
    <Routes>
      <Route
        path="*"
        element={
          <Ant.App>
            <LoginPage
              storeAuthToken={authManager.SetAuthToken}
              redirectToUrl="/"
            />
          </Ant.App>
        }
      />
    </Routes>
  );

  return (
    <BrowserRouter>
      {!showLoginPage || authManager.IsLoggedIn()
        ? loggedInRoutes
        : loggedOutRoutes}
    </BrowserRouter>
  );
}

root.render(
  <React.StrictMode>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
    <Ant.ConfigProvider
      theme={{
        token: {
          fontFamily: 'Roboto, sans-serif',
        },
        components: {
          Layout: {
            headerBg: '#000000',
            headerColor: '#ffffff',
            algorithm: true,
          },
          Select: {
            colorBorder: '#ACB7C6',
          },
          Button: {
            primaryColor: '#ffffff',
            colorPrimary: '#019ED9',
            defaultActiveBg: '#00aeef',
            defaultActiveColor: '#ffffff',
            defaultGhostColor: '#ffffff',
            defaultColor: '#ffffff',
            defaultBg: '#00aeef',
            defaultHoverBg: '#049ed7',
            defaultHoverColor: '#ffffff',
          },
          Menu: {
            colorPrimary: '#019ED9',
          },
          Form: {
            colorPrimary: '#019ED9',
          },
        },
      }}
    >
      <MyApp />
    </Ant.ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
