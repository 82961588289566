package com.adaptify.rating.admin.model.lob

import kotlin.js.JsExport

expect interface LineOfBusinessHierarchy {
  var id : String
  var version : Int
  var risks: Array<LineOfBusinessHierarchyItem>
}


expect fun createLineOfBusinessHierarchy(
  id: String,
  version: Int,
  risks: Array<LineOfBusinessHierarchyItem>,
): LineOfBusinessHierarchy

class LineOfBusinessHierarchyImpl(
  override var id: String,
  override var version: Int,
  override var risks: Array<LineOfBusinessHierarchyItem>,
) : LineOfBusinessHierarchy

@JsExport
object LineOfBusinessHierarchyUtil {
  fun create(
    id: String,
    version: Int,
    risks: Array<LineOfBusinessHierarchyItem>,
  ): LineOfBusinessHierarchy {
    return createLineOfBusinessHierarchy(id, version, risks)
  }
  fun deepCopy(obj: LineOfBusinessHierarchy): LineOfBusinessHierarchy {
    return create(
      obj.id,
      obj.version,
      obj.risks.map { LineOfBusinessHierarchyItemUtil.deepCopy(it) }.toTypedArray(),
    )
  }
}
