import {Flex} from 'antd';
import React, {useState} from 'react';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductSummaryForm} from '../control/ProductSummaryForm';
import {ProductVersionList} from '../control/ProductVersionList';
import {ProductService} from '../service/ProductService';

export interface ProductDetailControlProps {
  productService: ProductService;
  valueListService: ValueListService;
  productId: string | undefined;
}

export function ProductDetailControl(props: ProductDetailControlProps) {
  const [selectedProductId, setSelectedProductId] = useState<
    string | undefined
  >(undefined);

  return (
    <Flex vertical gap={24}>
      <ProductSummaryForm
        productId={props.productId}
        productService={props.productService}
      />
      <div style={{height: '400px'}}>
        <ProductVersionList
          productService={props.productService}
          productId={props.productId}
          selectedProductVersionId={selectedProductId}
          setSelectedProductVersionId={setSelectedProductId}
        />
      </div>
    </Flex>
  );
}
