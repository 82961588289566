import {Flex} from 'antd';
import React from 'react';
import {TenantList} from '../control/TenantList';
import {UserList} from '../control/UserList';
import {TenantService} from '../service/TenantService';
import {UserService} from '../service/UserService';

export interface TenantSummaryControlProps {
  tenantService: TenantService;
  userService: UserService;
}

export function TenantSummaryControl(props: TenantSummaryControlProps) {
  const [selectedTenantId, setSelectedTenantId] = React.useState<
    string | undefined
  >(undefined);

  return (
    <Flex vertical gap={24}>
      <TenantList
        tenantService={props.tenantService}
        selectedTenantId={selectedTenantId}
        setSelectedTenantId={setSelectedTenantId}
      />
      <UserList userService={props.userService} tenantId={selectedTenantId} />
    </Flex>
  );
}
