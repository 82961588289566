package com.adaptify.rating.admin.model.calculation.validation

import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptorMap
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider

class FunctionBindingValidatorAdapter(val bindingValidator : BindingValidator) : FunctionValidator {
  override fun validateFunction(
    function: CalculationFunction,
    calculation: Calculation,
    evalCtx: CalculationEvalContext
  ): Array<FunctionValidationMessage> {
    val funDesc: FunctionDescriptor? = FunctionDescriptorMap.get(function.name);
    if (funDesc == null) {
      return emptyArray();
    }

    val validations = mutableListOf<FunctionValidationMessage>()
    for (bindingDescriptor in funDesc.getBindingDescriptors()) {
      val binding = function.bindings.find { it.name == bindingDescriptor.name }
      if (binding == null) {
        continue;
      }

      validations.addAll(
        bindingValidator.validateBinding(
          binding, bindingDescriptor, function,
          funDesc, evalCtx
        )
      )
    }
    return validations.toTypedArray();
  }
}
