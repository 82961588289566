import {Button, Flex, Menu} from 'antd';
import {ItemType} from 'antd/es/menu/interface';
import React, {useEffect, useState} from 'react';
import {ADAPTIFY_TENANT_ID} from '../../common/service/Constants';
import {TestCaseList} from '../../rating/control/testcase/TestCaseList';
import {RatingService} from '../../rating/service/RatingService';
import {AuthManager} from '../../user/util/AuthManager';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductSummaryForm} from '../control/ProductSummaryForm';
import {ProductVersionActivityHistoryList} from '../control/ProductVersionActivityHistoryList';
import {ProductVersionList} from '../control/ProductVersionList';
import {ProductVersionNotesList} from '../control/ProductVersionNotesList';
import {Product} from '../model/Product';
import {ProductService} from '../service/ProductService';

export interface ProductVersionDetailControlProps {
  productService: ProductService;
  ratingService: RatingService;
  valueListService: ValueListService;
  authManager: AuthManager;
  productId: string | undefined;
  isAdaptifyUser: boolean;
}

export function ProductVersionDetailControl(
  props: ProductVersionDetailControlProps
) {
  const [selectedProductVersionId, setSelectedProductVersionId] = useState<
    string | undefined
  >(undefined);

  const [selectedTab, setSelectedTab] = useState<string | undefined>('History');
  const [modifyCount, setModifyCount] = useState(0);
  const [selectedTestCaseId, setSelectedTestCaseId] = useState<
    string | undefined
  >(undefined);
  const [product, setProduct] = useState<Product | undefined>(undefined);

  useEffect(() => {
    if (!props.productId) {
      setProduct(undefined);
      return;
    }
    const eff = async () => {
      const prod = await props.productService.GetProduct(props.productId ?? '');
      setProduct(prod);
    };
    eff();
  }, [props.productId]);

  function handleTabChange(key: string) {
    setSelectedTab(key);
  }

  const isProductOwnedByTenant =
    props.authManager.GetTenantId() === product?.tenantId;

  const menuStyle = {color: 'black'};
  const childLists = [
    {
      key: 'History',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => handleTabChange('History')}
        >
          History
        </Button>
      ),
    },
    {
      key: 'Notes',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => handleTabChange('Notes')}
        >
          Notes
        </Button>
      ),
    },
    {
      key: 'Test Cases',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => handleTabChange('Test Cases')}
        >
          Test Cases
        </Button>
      ),
    },
  ] as ItemType[];

  const tabControl = (
    <Menu
      mode="horizontal"
      selectedKeys={selectedTab ? [selectedTab] : []}
      onSelect={e => {
        setSelectedTab(e.key as string);
      }}
      style={{
        width: '100%',
      }}
      items={childLists}
    ></Menu>
  );

  const childControl =
    selectedTab === 'History' ? (
      <ProductVersionActivityHistoryList
        productService={props.productService}
        productVersionId={selectedProductVersionId}
        tabControl={tabControl}
      />
    ) : selectedTab === 'Notes' ? (
      <ProductVersionNotesList
        productService={props.productService}
        productVersionId={selectedProductVersionId}
        tabControl={tabControl}
        isReadOnly={!isProductOwnedByTenant}
        isAdaptifyUser={props.authManager.GetTenantId() === ADAPTIFY_TENANT_ID}
      />
    ) : (
      <TestCaseList
        productService={props.productService}
        ratingService={props.ratingService}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
        selectedTestCaseId={selectedTestCaseId}
        setSelectedTestCaseId={setSelectedTestCaseId}
        productVersionId={selectedProductVersionId}
        tabControl={tabControl}
        isReadOnly={!isProductOwnedByTenant}
      />
    );

  return (
    <Flex vertical gap={48}>
      <ProductSummaryForm
        productId={props.productId}
        productService={props.productService}
      />
      <div>
        <ProductVersionList
          productId={props.productId}
          productService={props.productService}
          valueListService={props.valueListService}
          selectedProductVersionId={selectedProductVersionId}
          setSelectedProductVersionId={setSelectedProductVersionId}
          readOnly={!isProductOwnedByTenant}
          isProductOwnedByTenant={isProductOwnedByTenant}
        />
      </div>
      {childControl}
    </Flex>
  );
}
