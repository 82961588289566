import {Button, Flex, Modal} from 'antd';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../common/control/Common';

export interface DirtyEditorConfirmationModalProps {
  open: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  skipDialog: boolean;
}

export function DirtyEditorConfirmationModal(
  props: DirtyEditorConfirmationModalProps
) {
  useEffect(() => {
    // auto confirm if the dialog is disabled
    if (props.open && props.skipDialog) {
      const eff = async () => {
        await props.onConfirm();
      };
      eff();
    }
  }, [props.open, props.skipDialog]);

  if (props.skipDialog) {
    return <></>;
  }

  if (!props.open) {
    return <></>;
  }

  return (
    <Modal
      className="adaptify-modal"
      title="Confirm Navigation"
      destroyOnClose={true}
      closable={false}
      open={true}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 600px)'}
      footer={null}
      height={'400px'}
    >
      <div className="flex flex-col w-full gap-8">
        <Flex justify="start" style={{width: '100%'}}>
          You have unsaved changes. Do you want to ignore the unsaved changes?
        </Flex>
        <Flex justify="end" className="gap-2">
          <Button
            {...ButtonSettings}
            htmlType="button"
            onClick={props.onCancel}
          >
            No
          </Button>
          <Button ghost={false} type="default" onClick={props.onConfirm}>
            Yes
          </Button>
        </Flex>
      </div>
    </Modal>
  );
}
