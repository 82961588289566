import {Button, Flex, Form, Input} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {Tenant} from '../model/Tenant';
import {TenantService} from '../service/TenantService';

interface ModifyTenantFormProps {
  tenantService: TenantService;
  existingTenantId?: string;
  onSaved: (tenant: Tenant) => void;
  onCancel: () => void;
}

interface ModifyTenantFormArgs {
  name: string;
}

export function ModifyTenantForm(props: ModifyTenantFormProps) {
  const [form] = Form.useForm<ModifyTenantFormArgs>();

  // using this to refresh form dependencies, since I cannot figure out how to use ant forms shouldUpdate correctly
  const [internalRefreshNum, setInternalRefreshNum] = React.useState(0);
  const [tenant, setTenant] = useState<Tenant | undefined>(undefined);

  useEffect(() => {
    if (!props.existingTenantId) {
      setTenant(undefined);
      return;
    }
    const eff = async () => {
      const tenant = await props.tenantService.GetTenant(
        props.existingTenantId ?? ''
      );
      setTenant(tenant);
    };

    eff();
  }, [props.existingTenantId]);

  useEffect(() => {
    form.resetFields();
    if (tenant) {
      form.setFieldsValue({
        name: tenant.name,
      });
    }
  }, [tenant]);

  async function onSave(value: ModifyTenantFormArgs) {
    if (props.existingTenantId) {
      return await onModify(value);
    }
    return await onCreate(value);
  }

  async function onModify(value: ModifyTenantFormArgs) {
    if (!tenant) {
      return;
    }

    const toUpdate = {
      ...tenant,
      name: value.name,
    };

    const updated = await props.tenantService.SaveTenant(toUpdate);
    props.onSaved(updated);
  }

  async function onCreate(value: ModifyTenantFormArgs) {
    const toCreate = {
      ...NewTenantAwareEntity(),
      name: value.name,
    };

    const created = await props.tenantService.SaveTenant(toCreate);
    props.onSaved(created);
  }

  return (
    <Form
      name="edit_tenant"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      style={{maxWidth: 800}}
      onFinish={onSave}
      autoComplete="off"
      size="large"
      form={form}
    >
      <Form.Item name="name" label="Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Flex justify="end" style={{width: '100%'}} className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button {...ButtonSettings} type="primary" htmlType="submit">
          {props.existingTenantId ? 'Modify' : 'Create'}
        </Button>
      </Flex>
    </Form>
  );
}
