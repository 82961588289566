package com.adaptify.rating.admin.model.calculation.binding

import com.adaptify.rating.admin.model.ModelUtil
import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.CalculationFunctionUtil

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

expect interface Binding {
  var name: String
  var bindingType: String
  var literal: Literal?
  var variableDeclaration: VariableDeclaration?
  var dynamicVariableFunctionId: String?
  var path: String?
  var block: Array<CalculationFunction>?
  var tableName: String?
  var tableBinding: TableBinding?
  var predicate: Predicate?
  var transitionDefinition: String?
  var configurationValue:  String?
}

expect fun createBinding(
  name: String,
  bindingType: String,
  literal: Literal? = null,
  variableDeclaration: VariableDeclaration? = null,
  dynamicVariableFunctionId: String? = null,
  path: String? = null,
  block: Array<CalculationFunction>? = null,
  tableName: String? = null,
  tableBinding: TableBinding? = null,
  predicate: Predicate? = null,
  transitionDefinition: String? = null,
  configurationValue: String? = null,
): Binding

@Serializable
class BindingImpl(
  override var name: String,
  override var bindingType: String,
  override var literal: Literal?,
  override var variableDeclaration: VariableDeclaration?,
  override var dynamicVariableFunctionId: String?,
  override var path: String?,
  override var block: Array<CalculationFunction>?,
  override var tableName: String?,
  override var tableBinding: TableBinding?,
  override var predicate: Predicate?,
  override var transitionDefinition: String?,
  override var configurationValue:  String?
) : Binding

@JsExport
object BindingUtil : ModelUtil<Binding> {
  fun create(
    name: String,
    bindingType: String,
    literal: Literal? = null,
    variableDeclaration: VariableDeclaration? = null,
    dynamicVariableFunctionId: String? = null,
    path: String? = null,
    block: Array<CalculationFunction>? = null,
    tableName: String? = null,
    tableBinding: TableBinding? = null,
    predicate: Predicate? = null,
    transitionDefinition: String? = null,
    configurationValue: String? = null,
  ): Binding {
    return createBinding(
      name,
      bindingType,
      literal,
      variableDeclaration,
      dynamicVariableFunctionId,
      path,
      block,
      tableName,
      tableBinding,
      predicate,
      transitionDefinition,
      configurationValue,
    )
  }

  override fun deepCopy(obj: Binding): Binding {
    return create(
      obj.name,
      obj.bindingType,
      LiteralUtil.deepCopyOpt(obj.literal),
      obj.variableDeclaration,
      obj.dynamicVariableFunctionId,
      obj.path,
      obj.block?.map { CalculationFunctionUtil.deepCopy(it) }?.toTypedArray(),
      obj.tableName,
      obj.tableBinding?.let { TableBindingUtil.deepCopy(it) },
      obj.predicate?.let { PredicateUtil.deepCopy(it) },
      obj.transitionDefinition,
      obj.configurationValue,
    )
  }

  fun fromLiteral(name: String, literal: Literal): Binding {
    return create(name, BindingType.PrimitiveLiteral.name, literal)
  }

  fun fromVariableDeclaration(name: String, variableDeclaration: VariableDeclaration): Binding {
    return create(name, BindingType.VariableDeclaration.name, variableDeclaration = variableDeclaration)
  }

  fun fromDynamicVariableFunctionId(name: String, dynamicVariableFunctionId: String): Binding {
    return create(name, BindingType.DynamicVariable.name, dynamicVariableFunctionId = dynamicVariableFunctionId)
  }

  fun fromDynamicVariableDeclaration(name : String): Binding {
    return create(name, BindingType.DynamicVariableDeclaration.name)
  }


  fun fromPath(name: String, path: String): Binding {
    return create(name, BindingType.Path.name, path = path)
  }

  fun fromBlock(name: String, block: Array<CalculationFunction>): Binding {
    return create(name, BindingType.Block.name, block = block)
  }

  fun fromTableName(name: String, tableName: String): Binding {
    return create(name, BindingType.TableName.name, tableName = tableName)
  }

  fun fromTableBinding(name: String, tableBinding: TableBinding): Binding {
    return create(name, BindingType.TableBinding.name, tableBinding = tableBinding)
  }

  fun fromPredicate(name: String, predicate: Predicate): Binding {
    return create(name, BindingType.Predicate.name, predicate = predicate)
  }

  fun fromTransitionDefinition(name: String, transitionDefinition: String): Binding {
    return create(name, BindingType.TransitionDefinition.name, transitionDefinition = transitionDefinition)
  }

  fun fromConfigurationValue(name: String, configurationValue: String): Binding {
    return create(name, BindingType.ConfigurationValue.name, configurationValue = configurationValue)
  }
}

