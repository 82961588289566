import {CopyOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Card, Flex, Space, Tree, TreeDataNode, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../../common/control/Common';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {
  AddNewTestCaseItem,
  CanAddNewTestCaseItem,
  CanRemoveTestCaseItem,
  CopyTestCaseItem,
  RemoveTestCaseItem,
  TestCaseTreeNode,
} from './TestCaseUIModel';
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;

export interface TestCaseLobTreeProps {
  lobHierarchy: LineOfBusinessHierarchy | undefined;
  root: TestCaseTreeNode | undefined;
  updateRoot: (root: TestCaseTreeNode, newSelection?: string) => void;
  selectedTestCaseTreeNodeId: string | undefined;
  setSelectedTestCaseTreeNodeId: (id: string | undefined) => void;
  readonly?: boolean;
}

export function TestCaseLobTree(props: TestCaseLobTreeProps) {
  const [uiNodes, setUiNodes] = useState<TreeDataNode | undefined>(undefined);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    if (!props.root) {
      setUiNodes(undefined);
      return;
    }
    const uiNodes = createUiNodes(props.root);
    setUiNodes(uiNodes);
  }, [props.root]);

  function createUiNodes(node: TestCaseTreeNode): TreeDataNode {
    const addButton = canAdd(node) ? (
      <Button
        type="link"
        onClick={e => {
          e.stopPropagation(); // prevent tree node selection to be trigged when add button is pressed
          addChild(node);
        }}
      >
        <PlusOutlined />
      </Button>
    ) : (
      <></>
    );
    const removeButton = canRemove(node) ? (
      <Button {...ButtonSettings} type="link" onClick={() => removeItem(node)}>
        <MinusOutlined />
      </Button>
    ) : (
      <></>
    );

    const copyButton = canAdd(node) ? (
      <Button
        type="link"
        onClick={e => {
          e.stopPropagation(); // prevent tree node selection
          copyItem(node);
        }}
      >
        <CopyOutlined />
      </Button>
    ) : (
      <></>
    );

    const titleNode = (
      <Space>
        <Typography.Text>{node.displayName}</Typography.Text>
        {addButton}
        {removeButton}
        {copyButton}
      </Space>
    );

    return {
      key: node.id,
      title: titleNode,
      children: [...node.children.map(createUiNodes)],
    };
  }

  function onSelectionChanged(selectedKeys: React.Key[]) {
    const sel =
      selectedKeys.length > 0 ? (selectedKeys[0] as string) : undefined;
    props.setSelectedTestCaseTreeNodeId(sel);
  }

  function canAdd(node: TestCaseTreeNode | undefined): boolean {
    if (props.readonly) {
      return false;
    }

    if (!props.lobHierarchy || !props.root || !node) {
      return false;
    }

    return CanAddNewTestCaseItem(props.lobHierarchy, props.root, node?.id);
  }

  function canRemove(node: TestCaseTreeNode): boolean {
    if (props.readonly) {
      return false;
    }

    if (!node || !props.lobHierarchy || !props.root) {
      return false;
    }

    return CanRemoveTestCaseItem(props.lobHierarchy, props.root, node.id);
  }

  function addChild(node: TestCaseTreeNode) {
    if (!props.lobHierarchy || !props.root || !node) {
      return;
    }

    const rootAndNode = AddNewTestCaseItem(
      props.lobHierarchy,
      props.root,
      node.id
    );
    if (!rootAndNode) {
      return;
    }
    props.updateRoot(rootAndNode.root, rootAndNode.node?.id);
  }

  function removeItem(node: TestCaseTreeNode) {
    if (!props.lobHierarchy || !props.root || !node) {
      return;
    }

    const root = RemoveTestCaseItem(props.lobHierarchy, props.root, node.id);
    if (!root) {
      return;
    }
    props.updateRoot(root);
  }

  function copyItem(node: TestCaseTreeNode) {
    if (!props.lobHierarchy || !props.root || !node) {
      return;
    }

    const rootAndNode = CopyTestCaseItem(
      props.lobHierarchy,
      props.root,
      node.id
    );
    if (!rootAndNode) {
      return;
    }
    props.updateRoot(rootAndNode.root, rootAndNode.node?.id);
  }

  return (
    <Card bordered={false} title="Test Case">
      <Flex vertical>
        <Tree
          blockNode
          defaultExpandAll
          treeData={uiNodes ? [uiNodes] : []}
          onSelect={onSelectionChanged}
          expandedKeys={expandedKeys}
          onExpand={setExpandedKeys}
          selectedKeys={
            props.selectedTestCaseTreeNodeId
              ? [props.selectedTestCaseTreeNodeId]
              : []
          }
          style={{height: '100%', width: '300px'}}
        />
      </Flex>
    </Card>
  );
}
