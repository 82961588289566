import {GridColDef} from '@mui/x-data-grid';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Col, List, Row, Typography} from 'antd';
import React from 'react';
import {FunctionLog} from '../../model/Rating';
import {ProcessFunctionDisplayTemplate} from '../calculation/CalculationFunctionListModel';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import TestCaseLobItem = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItem;
import FunctionDescriptorMap = kot.com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptorMap;
import BindingType = kot.com.adaptify.rating.admin.model.calculation.BindingType;

export interface FunctionLogsControlProps {
  functionLog: FunctionLog[];
}

interface FunctionListModel {
  indentLevel: number;
  adminText: string;
  runtimeText: string;
}

export function FunctionLogsControl(props: FunctionLogsControlProps) {
  const headerColDef: GridColDef<String[]> = {
    field: '0',
    headerName: 'Titles',
    flex: 300,
  };

  function getDefinitionDisplayText(functionLog: FunctionLog) {
    const functionDescriptor = FunctionDescriptorMap.asJsReadonlyMapView().get(
      functionLog.name
    );

    if (!functionDescriptor) {
      return functionLog.name;
    }
    let text = '';
    ProcessFunctionDisplayTemplate(
      functionDescriptor,
      chunk => {
        text = text + ' ' + chunk;
      },
      bindingName => {
        const binding = functionLog.bindings.find(b => b.name === bindingName);
        if (!binding) {
          return;
        }
        text = text + ' ' + binding.bindingDefinitionDisplayString;
      }
    );
    return text;
  }

  function getRuntimeDisplayText(functionLog: FunctionLog) {
    const functionDescriptor = FunctionDescriptorMap.asJsReadonlyMapView().get(
      functionLog.name
    );

    if (!functionDescriptor) {
      return functionLog.name;
    }
    let text = '';
    ProcessFunctionDisplayTemplate(
      functionDescriptor,
      chunk => {
        text = text + ' ' + chunk;
      },
      bindingName => {
        const binding = functionLog.bindings.find(b => b.name === bindingName);
        if (!binding) {
          return;
        }
        text = text + ' ' + binding.boundValueDisplayString;
      }
    );
    return text;
  }

  function flattenFunctionLogs(
    functionLogs: FunctionLog[],
    indentLevel: number,
    suffix: string
  ): FunctionListModel[] {
    return functionLogs.flatMap((fl, index) => {
      const suffixByPosition = index < functionLogs.length - 1 ? suffix : '';
      return flattenFunctionLog(fl, indentLevel, suffixByPosition);
    });
  }

  function flattenFunctionLog(
    functionLog: FunctionLog,
    indentLevel: number,
    suffix: string
  ): FunctionListModel[] {
    const functionDescriptor = FunctionDescriptorMap.asJsReadonlyMapView().get(
      functionLog.name
    );

    const functionListModels: FunctionListModel[] = [];
    if (!functionDescriptor) {
      return [];
    }

    functionListModels.push({
      indentLevel: indentLevel,
      adminText: getDefinitionDisplayText(functionLog) + suffix,
      runtimeText: getRuntimeDisplayText(functionLog) + suffix,
    });

    for (const binding of functionDescriptor.getBindingDescriptors()) {
      const bindingLogs = functionLog.bindings.filter(
        b => b.name === binding.name
      );

      if (bindingLogs.length === 0) {
        continue;
      }

      // this code has some overal with CalculationFunctionListModel, but given that the input
      // and output are different, the choice here is to have a separate implementation for now
      const isBlock = binding.allowedBindingTypes.includes(BindingType.Block);
      const isPredicate = binding.allowedBindingTypes.includes(
        BindingType.Predicate
      );

      if ((isBlock || isPredicate) && binding.shouldRenderBlockHeader()) {
        // create the block placeholder, ie THEN or ELSE
        functionListModels.push({
          indentLevel: indentLevel + 1,
          adminText: binding.displayName,
          runtimeText: binding.displayName,
        });
      }
      for (const bindingLog of bindingLogs) {
        const suffix = isPredicate
          ? ' ' + bindingLog.bindingDefinitionDisplayString + ' '
          : '';
        if (isBlock || isPredicate) {
          // create the predicate placeholder, ie IF
          const children = flattenFunctionLogs(
            bindingLog.childFunctions ?? [],
            indentLevel + 1,
            suffix
          );

          children.forEach(c => {
            functionListModels.push(c);
          });
        }
      }
    }
    return functionListModels;
  }
  return (
    <List
      style={{width: '100%'}}
      dataSource={flattenFunctionLogs(props.functionLog || [], 0, '')}
      renderItem={item => (
        <List.Item>
          <Row gutter={10} style={{width: '100%'}}>
            <Col span={12} style={{borderRight: '1px solid black'}}>
              <Typography.Text style={{color: 'black', fontSize: 'larger'}}>
                {item.adminText}
              </Typography.Text>
            </Col>
            <Col span={12} style={{textWrap: 'wrap'}}>
              <Typography.Text style={{color: 'black', fontSize: 'larger'}}>
                {' '}
                {item.runtimeText}
              </Typography.Text>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
}
