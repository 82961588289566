import React from 'react';
import {NavigationAware} from '../../NavigationAware';
import {TenantService} from '../service/TenantService';
import {UserService} from '../service/UserService';
import {TenantSummaryControl} from './TenantSummaryControl';

export interface TenantSummaryPageProps extends NavigationAware {
  tenantService: TenantService;
  userService: UserService;
}

export function TenantSummaryPage(props: TenantSummaryPageProps) {
  return (
    <TenantSummaryControl
      tenantService={props.tenantService}
      userService={props.userService}
    />
  );
}
