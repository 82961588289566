package com.adaptify.rating.admin.model.calculation.binding

import com.adaptify.rating.admin.model.calculation.CalculationFunction

@JsExport
external actual interface Binding {
  actual var name: String
  actual var bindingType: String
  actual var literal: Literal?
  actual var variableDeclaration: VariableDeclaration?
  actual var dynamicVariableFunctionId: String?
  actual var path: String?
  actual var block: Array<CalculationFunction>?
  actual var tableName: String?
  actual var tableBinding: TableBinding?
  actual var predicate: Predicate?
  actual var transitionDefinition: String?
  actual var configurationValue:  String?
}

actual fun createBinding(
  name: String,
  bindingType: String,
  literal: Literal?,
  variableDeclaration: VariableDeclaration?,
  dynamicVariableFunctionId: String?,
  path: String?,
  block: Array<CalculationFunction>?,
  tableName: String?,
  tableBinding: TableBinding?,
  predicate: Predicate?,
  transitionDefinition: String?,
  configurationValue: String?
): Binding {
  return js("({name: name, bindingType : bindingType, literal : literal, variableDeclaration : variableDeclaration, dynamicVariableFunctionId: dynamicVariableFunctionId,  path : path, block : block, tableName : tableName, tableBinding : tableBinding, predicate : predicate, transitionDefinition : transitionDefinition, configurationValue : configurationValue})")
}
