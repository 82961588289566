import {SendOutlined, StopOutlined} from '@ant-design/icons';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {Avatar, Button, Input, InputRef} from 'antd';
import {AnimatePresence, motion} from 'framer-motion';
import React, {useLayoutEffect, useRef, useState} from 'react';
import Markdown from 'react-markdown';
import {StreamState} from '../hook/useStream';
import {Message, SelectedConversation, SendMessageArgs} from '../model/Chat';

export interface ChatControlProps {
  selectedConversation: SelectedConversation;
  setSelectedConversation: React.Dispatch<
    React.SetStateAction<SelectedConversation>
  >;
  loading: boolean;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  conversationState: StreamState<SendMessageArgs>;
}

export const ChatControl: React.FC<ChatControlProps> = ({
  selectedConversation,
  setSelectedConversation,
  loading,
  messages,
  setMessages,
  conversationState,
}: ChatControlProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({block: 'end', behavior: 'smooth'});
  };
  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  const [userInput, setUserInput] = useState<string>('');
  const handleSendMessage = () => {
    if (conversationState.isActive || !userInput.trim()) return;
    const userMessage: Message = {
      id: crypto.randomUUID(),
      role: 'user',
      content: userInput,
    };
    setMessages(prev => [...prev, userMessage]);
    setUserInput('');
    conversationState.startStream({
      message: userInput,
      conversationId: selectedConversation?.id,
    });
  };

  const userInputRef = useRef<InputRef>(null);
  const handleNewConversation = () => {
    setUserInput('');
    setMessages([]);
    setSelectedConversation(null);
    userInputRef.current?.focus();
  };

  const stopStream = () => conversationState.stopStream();
  return (
    <div className="flex flex-1 flex-col max-h-[752px]">
      <div className="flex justify-between">
        <h1 className="page-title mb-4">Rate Product Assistant</h1>
        <Button
          type="primary"
          disabled={!selectedConversation || loading}
          ghost={true}
          icon={<AddOutlinedIcon />}
          onClick={handleNewConversation}
          aria-label="New Conversation"
        >
          New Conversation
        </Button>
      </div>
      <div
        className="flex-1 overflow-y-auto mb-4 scroll-mb-4 p-4 scroll-p-4 bg-gray-50 border border-gray-200 rounded-lg"
        role="log"
        aria-label="Chat messages"
      >
        <AnimatePresence initial={false}>
          {messages.map(message => (
            <motion.div
              key={message.id}
              animate={{opacity: 1, y: 20}}
              className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}
            >
              <div
                className={`flex ${message.role === 'user' ? 'flex-row-reverse' : 'flex-row'} items-start max-w-[80%]`}
              >
                {message.role === 'assistant' && (
                  <Avatar
                    size={40}
                    src="/favicon.ico"
                    className="mx-2 flex-shrink-0"
                    alt="Assistant Avatar"
                  />
                )}
                <div
                  className={`flex flex-col ${message.role === 'user' ? 'items-end' : 'items-start'}`}
                >
                  {message.role === 'assistant' && (
                    <span className="text-xs text-gray-500 mb-1">
                      Assistant
                    </span>
                  )}
                  <div
                    className={`p-3 rounded-lg ${message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-white border border-gray-200'} shadow-sm`}
                  >
                    <Markdown>{message.content}</Markdown>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
          <div className="" ref={messagesEndRef} />
        </AnimatePresence>
      </div>

      <div className="flex items-center gap-2 p-4 bg-gray-50 border border-gray-200 rounded-lg">
        <Input
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
          onPressEnter={handleSendMessage}
          placeholder="Type your message here..."
          aria-label="Message input"
          className="flex-1"
          ref={userInputRef}
        />
        {conversationState.isActive ? (
          <Button
            disabled={!conversationState.isActive}
            type="primary"
            ghost={true}
            icon={<StopOutlined />}
            onClick={stopStream}
            aria-label="Stop Response"
          >
            Stop
          </Button>
        ) : (
          <Button
            disabled={loading || !userInput.trim()}
            type="primary"
            ghost={true}
            icon={<SendOutlined />}
            onClick={handleSendMessage}
            aria-label="Send Message"
          >
            Send
          </Button>
        )}
      </div>
    </div>
  );
};
