import React from 'react';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {AuthManager} from '../../user/util/AuthManager';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductService} from '../service/ProductService';
import {ProductOverviewControl} from './ProductOverviewControl';

export interface ProductOverviewPageProps extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  valueListService: ValueListService;
  authToken: string;
  authManager: AuthManager;
  adaptifyTenantMode: boolean;
}

export function ProductOverviewPage(props: ProductOverviewPageProps) {
  return (
    <ProductOverviewControl
      lobService={props.lobService}
      productService={props.productService}
      valueListService={props.valueListService}
      authToken={props.authToken}
      authManager={props.authManager}
      adaptifyTenantMode={props.adaptifyTenantMode}
    />
  );
}
