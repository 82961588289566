import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Modal, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {Tenant} from '../model/Tenant';
import {TenantService} from '../service/TenantService';
import {ModifyTenantForm} from './ModifyTenantForm';

export interface TenantListProps {
  tenantService: TenantService;
  readOnly?: boolean;
  selectedTenantId: string | undefined;
  setSelectedTenantId: (tenantId: string | undefined) => void;
}

export interface EditTenantState {
  open: boolean;
  tenantId?: string;
}

export function TenantList(props: TenantListProps) {
  const [createPopupState, setCreatePopupState] = useState<EditTenantState>({
    open: false,
  });
  const [modifyCount, setModifyCount] = React.useState<number>(0);
  const [tenants, setTenants] = useState<Tenant[]>([]);

  useEffect(() => {
    const eff = async () => {
      const loaded = await props.tenantService.QueryTenants();
      setTenants(loaded);
      if (loaded.length > 0 && !props.selectedTenantId) {
        props.setSelectedTenantId(loaded[0].id);
      } else {
        props.setSelectedTenantId(undefined);
      }
    };
    eff();
  }, [modifyCount]);

  const colDefs: GridColDef<Tenant>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 300,
    },
  ];

  function onCreateNew() {
    setCreatePopupState({open: true});
  }

  function onModify() {
    setCreatePopupState({open: true, tenantId: props.selectedTenantId});
  }

  function onTenantSaved() {
    // up the modify count to trigger a refresh
    setModifyCount(modifyCount + 1);
    setCreatePopupState({open: false});
  }

  function onTenantCancelled() {
    setCreatePopupState({open: false});
  }

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    if (rowSelectionModel.length > 0) {
      props.setSelectedTenantId(rowSelectionModel[0] as string);
    } else {
      props.setSelectedTenantId(undefined);
    }
  }

  const modifyButtons = props.readOnly ? (
    <></>
  ) : (
    <Flex justify="end">
      <Button {...ButtonSettings} onClick={onCreateNew}>
        Create Organization
      </Button>
      <Button
        {...ButtonSettings}
        onClick={onModify}
        disabled={!props.selectedTenantId}
      >
        Modify
      </Button>
    </Flex>
  );

  const createTenantModal = createPopupState.open ? (
    <Modal
      className="adaptify-modal"
      title={
        createPopupState.tenantId
          ? 'Modify Organization'
          : 'Create Organization'
      }
      open={createPopupState.open}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
    >
      <ModifyTenantForm
        tenantService={props.tenantService}
        existingTenantId={createPopupState.tenantId}
        onSaved={onTenantSaved}
        onCancel={onTenantCancelled}
      />
    </Modal>
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Organizations</div>
          </Col>
          <Col span={6}>{modifyButtons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={tenants}
              rowSelectionModel={
                props.selectedTenantId ? [props.selectedTenantId] : []
              }
              onRowSelectionModelChange={onTableSelectionChanged}
            />
          </div>
        </Space>
      </Flex>
      {createTenantModal}
    </>
  );
}
