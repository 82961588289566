import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {LobHierarchyList} from '../control/LobHierarchyList';
import {LobHierarchyQueryPanel} from '../control/LobHierarchyQueryPanel';
import {
  LineOfBusinessHierarchyDef,
  LobHierarchyInfo,
  LobHierarchyInfoQuery,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';

export interface LobHierarchyControlProps {
  lobService: LobService;
  valueListService: ValueListService;
}

export function LobHierarchyControl(props: LobHierarchyControlProps) {
  const [lobQuery, setLobQuery] = useState<LobHierarchyInfoQuery>({});

  const [modifyCount, setModifyCount] = useState<number>(0);

  const [selectedLobDefId, setSelectedLobDefId] = useState<string | undefined>(
    undefined
  );

  const [lobs, setLobs] = useState<LobHierarchyInfo[]>([]);

  useEffect(() => {
    const eff = async () => {
      const loaded = await props.lobService.QueryLinesOfBusinesses(lobQuery);
      setLobs(loaded);
      if (!selectedLobDefId && loaded.length > 0) {
        setSelectedLobDefId(loaded[0].id);
      }
    };
    eff();
  }, [modifyCount]);

  function onLobDefSelectionChanged(selection: string | undefined) {
    setSelectedLobDefId(selection);
  }

  function onCreateNewLobDef(lobDef: LineOfBusinessHierarchyDef) {
    // we need to reposition the tree based on the creation
    // TODO fix this if we are keeping the tree, waiting on the LOB requirements
    /*
    setCategoryAndName({
      category: lobDef.category,
      name: lobDef.name,
    });
    */
    onModified();
  }

  function onModified() {
    setModifyCount(modifyCount + 1);
  }

  async function onQuery() {
    const loaded = await props.lobService.QueryLinesOfBusinesses(lobQuery);
    setLobs(loaded);
  }

  return (
    <Flex vertical gap={24}>
      <LobHierarchyQueryPanel
        valueListService={props.valueListService}
        lobService={props.lobService}
        lobQuery={lobQuery}
        setLobQuery={setLobQuery}
        onQuery={onQuery}
        modifyCount={modifyCount}
      />
      <div style={{flexGrow: 1, height: '750px'}}>
        <LobHierarchyList
          lobService={props.lobService}
          valueListService={props.valueListService}
          lobs={lobs}
          onCreateNewLob={onCreateNewLobDef}
          selectedLineOfBusinessDefId={selectedLobDefId}
          setSelectedLineOfBusinessDefId={onLobDefSelectionChanged}
          modifyCount={modifyCount}
          setModifyCount={onModified}
        />
      </div>
    </Flex>
  );
}
