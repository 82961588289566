import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {Button, Col, Flex, Input, Row, Typography} from 'antd';

import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ButtonSettings} from '../../common/control/Common';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {ProductVersionSummary} from '../../product/model/Product';
import {FormatVersionNumberDisplayString} from '../../product/model/ProductUtils';
import {ProductService} from '../../product/service/ProductService';
import {DirtyEditorConfirmationModal} from './DirtyEditorConfirmationModal';
import {EditProductDescriptionModal} from './EditProductDescriptionModal';

export interface ProductVersionSummaryFormProps {
  productService: ProductService;
  productVersionId: string;
  isDirty?: boolean;
  save(): Promise<void>;
  // current UI requirements strangely involve hiding the description field in some UIs
  // probably as a workaround since the description field is editable in the summary
  hideDescription?: boolean;
  readOnly?: boolean;
}

export function ProductVersionSummaryForm(
  props: ProductVersionSummaryFormProps
) {
  const [productVersion, setProductVersion] = useState<ProductVersionSummary>();
  const navigate = useNavigate();
  const [navigateToProductConfirmOpen, setNavigateToProductConfirmOpen] =
    useState(false);
  const [navigateToVersionConfirmOpen, setNavigateToVersionConfirmOpen] =
    useState(false);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [modifyCount, setModifyCount] = useState(0);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId || props.productVersionId === '') {
        return;
      }
      const productVersion =
        await props.productService.GetProductVersionSummaryById(
          props.productVersionId
        );
      setProductVersion(productVersion);
    };
    eff();
  }, [props.productVersionId, modifyCount]);

  // per product requirements, there is no way to discard changes and navigate away, you either need to save your changes
  // or you are stuck on the editor
  // instead of using link will take the navigate to string, so we can trigger the save first
  /*
  function navigateButtonWrapInConfirmIfDirty(component : JSX.Element, navigateTo: string) {

    return props.isDirty? (
      <Popconfirm
      title=""
      description='You have unsaved changes in the canvas. Select "Save" to retain canvas modificiations and proceed forward or "Cancel" to return back to the canvas.'
      onConfirm={() => saveAndNavigateTo(navigateTo)}
      okText="Save"
      cancelText="Cancel"
    >
      component.
      {component}
    </Popconfirm>
    ) : component;
  }
*/
  async function saveAndNavigateTo(navigateTo: string) {
    if (props.isDirty) {
      await props.save();
    }
    navigate(navigateTo);
  }

  // actual UI requirements are to make the description field editable and then save it in the below
  // editor, which would make it subject to undo/redo.  As a stopgap, providing a popup to edit the description
  // thereby avoiding this complexity until I have time to implement the actual requirements
  const descriptionField = props.hideDescription ? (
    <Col span={12} />
  ) : (
    <>
      <Col span={3} className="adaptify-label-column">
        <Typography.Text>Description:</Typography.Text>
      </Col>
      <Col span={9}>
        <Flex gap={8}>
          <Input
            value={productVersion?.description}
            readOnly
            className="adaptify-read-only-field"
          ></Input>
          {props.readOnly ? (
            <></>
          ) : (
            <Button
              {...ButtonSettings}
              onClick={() => {
                setDescriptionModalOpen(true);
              }}
            >
              ...
            </Button>
          )}
        </Flex>
      </Col>
    </>
  );

  const descriptionModal = descriptionModalOpen ? (
    <EditProductDescriptionModal
      open={descriptionModalOpen}
      description={productVersion?.description ?? ''}
      onCancel={() => {
        setDescriptionModalOpen(false);
      }}
      onOk={async (description: string) => {
        // load the product version, set the description and save it
        if (!productVersion) {
          return;
        }
        const product = await props.productService.GetProductVersion(
          props.productVersionId
        );
        product.description = description;
        // no requirements right now for update of the description to change the activity history
        await props.productService.UpdateProductVersion(product);
        setDescriptionModalOpen(false);
        setModifyCount(modifyCount + 1);
      }}
    />
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical gap={10}>
        <Row gutter={[20, 10]}>
          <Col span={24}>
            <Flex align="end" justify="end" className="gap-2">
              <Button
                {...ButtonSettings}
                onClick={() => setNavigateToProductConfirmOpen(true)}
              >
                Rate Product Library
              </Button>
              <Button
                {...ButtonSettings}
                onClick={() => setNavigateToVersionConfirmOpen(true)}
              >
                Version Details
              </Button>
            </Flex>
          </Col>
        </Row>
        <div
          style={{
            border: 'solid',
            borderWidth: '1px',
            borderRadius: '8px',
            borderColor: '#CCCCCC',
            backgroundColor: '#F2F7FE',
          }}
        >
          <div
            style={{
              marginLeft: '16px',
              marginRight: '16px',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          >
            <Row gutter={[10, 16]}>
              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Carrier Name:</Typography.Text>
              </Col>
              <Col span={9}>
                <Input
                  value={productVersion?.carrierName}
                  readOnly
                  className="adaptify-read-only-field"
                ></Input>
              </Col>
              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Underwriting Company:</Typography.Text>
              </Col>
              <Col span={9}>
                <Input
                  value={productVersion?.underwritingCompany}
                  readOnly
                  className="adaptify-read-only-field"
                ></Input>
              </Col>

              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Insurance Type:</Typography.Text>
              </Col>
              <Col span={3}>
                <Input
                  value={productVersion?.lineOfBusinessInsuranceType}
                  readOnly
                  className="adaptify-read-only-field"
                ></Input>
              </Col>

              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Line of Business:</Typography.Text>
              </Col>
              <Col span={3}>
                <Input
                  value={productVersion?.lineOfBusinessName}
                  readOnly
                  className="adaptify-read-only-field"
                ></Input>
              </Col>

              <Col span={3} className="adaptify-label-column">
                <Typography.Text>State:</Typography.Text>
              </Col>
              <Col span={3}>
                <Input
                  value={productVersion?.stateCode}
                  readOnly
                  className="adaptify-read-only-field"
                ></Input>
              </Col>

              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Product Type:</Typography.Text>
              </Col>
              <Col span={3}>
                <Input
                  value={productVersion?.productType}
                  readOnly
                  className="adaptify-read-only-field"
                ></Input>
              </Col>

              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Effective Date:</Typography.Text>
              </Col>
              <Col span={3}>
                <Input
                  value={DateIsoStringToDisplayString(
                    productVersion?.effectiveDate
                  )}
                  readOnly
                  className="adaptify-read-only-field"
                ></Input>
              </Col>

              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Version:</Typography.Text>
              </Col>
              <Col span={3}>
                <Input
                  value={FormatVersionNumberDisplayString(
                    productVersion?.versionNumber
                  )}
                  readOnly
                  className="adaptify-read-only-field"
                ></Input>
              </Col>
              {descriptionField}
              {/* <Col span={24}></Col> */}
            </Row>
          </div>
        </div>
      </Flex>
      <DirtyEditorConfirmationModal
        onCancel={() => {
          setNavigateToProductConfirmOpen(false);
        }}
        onConfirm={() => {
          navigate('/platform/product/summary/my');
          setNavigateToProductConfirmOpen(false);
          return Promise.resolve();
        }}
        skipDialog={!props.isDirty}
        open={navigateToProductConfirmOpen}
      />
      <DirtyEditorConfirmationModal
        onCancel={() => {
          setNavigateToVersionConfirmOpen(false);
        }}
        onConfirm={() => {
          navigate(
            `/platform/productversion/${productVersion?.productId}/detail`
          );
          setNavigateToVersionConfirmOpen(false);
          return Promise.resolve();
        }}
        skipDialog={!props.isDirty}
        open={navigateToVersionConfirmOpen}
      />
      {descriptionModal}
    </>
  );
}
