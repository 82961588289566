import {RedoOutlined, SaveOutlined, UndoOutlined} from '@ant-design/icons';
import * as dagre from '@dagrejs/dagre';
import {
  Background,
  Controls,
  EdgeAddChange,
  EdgeChange,
  EdgeRemoveChange,
  EdgeReplaceChange,
  MarkerType,
  NodeAddChange,
  NodeChange,
  NodeDimensionChange,
  NodePositionChange,
  NodeRemoveChange,
  OnSelectionChangeParams,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import {Connection, EdgeBase, NodeBase} from '@xyflow/system';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Button, Col, Dropdown, Flex, Row} from 'antd';
import React, {MouseEvent, useCallback, useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {ButtonSettings} from '../../../common/control/Common';
import {LobService} from '../../../lob/service/LobService';
import {IdAndName} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';
import {RatingService} from '../../service/RatingService';
import {BranchNodeModal} from './BranchNodeModal';
import {CalculationNodeModal} from './CalculationNodeModal';
import {BranchSvg, CalculationSvg, TableSvg} from './FlowImages';
import {
  BranchNodeType,
  CalculationNodeType,
  ConditionNodeType,
  EndNodeType,
  StartNodeType,
  TableNodeType,
} from './FlowNode';
import {TableNodeDetailsModal} from './TableNodeModal';
import Flow = kot.com.adaptify.rating.admin.model.flow.Flow;
import Node = kot.com.adaptify.rating.admin.model.flow.Node;
import Edge = kot.com.adaptify.rating.admin.model.flow.Edge;
import NodeType = kot.com.adaptify.rating.admin.model.flow.NodeType;
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import FunctionDescriptors = kot.com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptors;

import CalculationFunction = kot.com.adaptify.rating.admin.model.calculation.CalculationFunction;
import TransitionUtil = kot.com.adaptify.rating.admin.model.calculation.util.TransitionUtil;
import GlobalVariable = kot.com.adaptify.rating.admin.model.calculation.util.GlobalVariable;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import GlobalVariarableUtil = kot.com.adaptify.rating.admin.model.calculation.util.GlobalVariableUtil;
import VariableType = kot.com.adaptify.rating.admin.model.calculation.context.VariableType;

export interface ProductVersionFlowControlProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authToken: string;
  productVersionId: string;
  flow: Flow | undefined;
  updateFlow: (flow: Flow) => void;
  saveFlow: (flow: Flow) => Promise<Flow>;
  deleteFlow: (id: string) => Promise<void>;
  isDirty: boolean;
  canUndo: boolean;
  undo: () => void;
  canRedo: boolean;
  redo: () => void;
  readOnly: boolean;
  refreshFromServerCount: number;
}

const nodeTypes = {
  Start: StartNodeType,
  End: EndNodeType,
  Branch: BranchNodeType,
  Calculation: CalculationNodeType,
  Condition: ConditionNodeType,
  Table: TableNodeType,
};

interface NodeDetailEditState {
  open: boolean;
  nodeId?: string;
}

function pruneDanglingEdges(flow: Flow): Flow {
  const nodes = flow.nodes;
  const edges = flow.edges;
  const nodeIds = new Set(nodes.map(n => n.id));
  const prunedEdges = edges.filter(
    e => nodeIds.has(e.sourceNode) && nodeIds.has(e.targetNode)
  );
  return {...flow, edges: prunedEdges};
}

function autoLayout(flow: Flow): Flow {
  const g = new dagre.graphlib.Graph();

  // Set an object for the graph label
  g.setGraph({
    rankdir: 'LR',
    ranksep: 100,
  });

  // Default to assigning a new object as a label for each new edge.
  g.setDefaultEdgeLabel(() => {
    return {};
  });

  flow.nodes.forEach(node => {
    g.setNode(node.id, {
      label: node.name,
      width: node.width,
      height: node.height,
    });
  });

  flow.edges.forEach(edge => {
    g.setEdge(edge.sourceNode, edge.targetNode);
  });

  dagre.layout(g, {
    rankdir: 'LR',
  });

  const updated = structuredClone(flow);

  const nodeMap = new Map<string, Node>();
  updated.nodes.forEach(node => {
    nodeMap.set(node.id, node);
  });

  g.nodes().forEach(n => {
    const node = nodeMap.get(n);
    if (node) {
      const pos = g.node(n);
      node.x = pos.x;
      node.y = pos.y;
    }
  });
  return updated;
}

export function ProductVersionFlowControl(
  props: ProductVersionFlowControlProps
) {
  const [allTableNames, setAllTableNames] = useState<IdAndName[]>([]);

  const [nodes, setNodes, onNodesChange] = useNodesState<NodeBase>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeBase>([]);

  const {screenToFlowPosition} = useReactFlow();

  const [selectedNode, setSelectedNode] = useState<Node | undefined>(undefined);

  const [lobHierarchy, setLobHierarchy] = useState<
    LineOfBusinessHierarchy | undefined
  >(undefined);

  const [nodeDetailEditState, setNodeDetailEditState] =
    useState<NodeDetailEditState>({
      open: false,
    });

  const [globalVariables, setGlobalVariables] = useState<GlobalVariable[]>([]);

  const store = useStoreApi();
  const {addSelectedNodes} = store.getState();

  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  useEffect(() => {
    const eff = async () => {
      const lobDefPrommise =
        props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );
      const tableNamePromise =
        props.productService.GetProductVersionTableNamesByProductVersionId(
          props.productVersionId
        );

      const globalVariablesPromise = props.ratingService.GetGlobalVariables(
        props.productVersionId
      );

      const [lobDef, tableNames, vars] = await Promise.all([
        lobDefPrommise,
        tableNamePromise,
        globalVariablesPromise,
      ]);

      setLobHierarchy(lobDef);
      setAllTableNames(tableNames);
      setGlobalVariables(vars);
    };
    eff();
  }, [props.productVersionId]);

  useEffect(() => {
    if (!props.flow) {
      setNodes([]);
    }
    const uiNodes = (props.flow?.nodes || []).map(node => {
      const nodeName =
        node.nodeType === NodeType.Table.name
          ? allTableNames.find(t => t.id === node.tableId)?.name ?? node.name
          : node.name;
      return {
        id: node.id,
        nodeType: 'input',
        position: {x: node.x, y: node.y},
        data: {label: nodeName},
        draggable: !props.readOnly,
        connectable: !props.readOnly,
        deletable:
          !props.readOnly &&
          node.nodeType !== NodeType.Start.name &&
          node.nodeType !== NodeType.End.name,
        type: node.nodeType,
        style: {
          background: '#fff',
          border: '1px solid black',
          fontSize: 12,
        },
        width: node.width,
        height: node.height,
        selected: selectedNode?.id === node.id,
      } as NodeBase;
    });
    setNodes(uiNodes);
    const uiEdges = (props.flow?.edges || []).map(edge => {
      return {
        id: edge.id,
        deletable: true,
        label: edge.sourceTransitionName,
        labelStyle: {fill: 'black', fontSize: 14},
        source: edge.sourceNode,
        target: edge.targetNode,
        animated: true,
        selectable: !props.readOnly,
        style: {
          stroke: '#000',
          strokeWidth: 1,
        },
      } as EdgeBase;
    });
    setEdges(uiEdges);
  }, [props.refreshFromServerCount, props.flow, allTableNames]);

  function onDragStart(event, nodeType) {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  }

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const nodeType = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof nodeType === 'undefined' || !nodeType) {
        return;
      }

      // project was renamed to screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNode = {
        id: uuidv4(),
        name: nodeType,
        x: position.x,
        y: position.y,
        width: 200,
        height: 100,
        nodeType: nodeType,
        functions: [],
      } as Node;

      if (nodeType === NodeType.Branch.name) {
        const ifFunction: CalculationFunction | undefined =
          FunctionDescriptors.find(f => f.name === 'IF')?.createDefault(
            [],
            undefined
          );
        // create a single condition function automatically, and we will disable the function creation
        newNode.functions = ifFunction ? [ifFunction] : [];
      }

      if (nodeType === NodeType.Condition.name) {
        // create a single condition function automatically, and we will disable the function creation
        newNode.functions = [
          {
            id: uuidv4(),
            name: 'CONDITION',
            bindings: [],
          },
        ];
      }

      /*
        Table lookup has been moved to an id as opposed to a function
      if (nodeType === NodeType.Table.name) {
        // create a single table function automatically, and we will disable the function creation
        newNode.functions = [
          {
            id: uuidv4(),
            name: 'LOOKUP_TABLE',
            bindings: [],
          },
        ];
      }
        */

      const copyFlow = structuredClone(props.flow);
      const newNodes = [...(copyFlow?.nodes || []), newNode];
      const newFlow = {...copyFlow, nodes: newNodes} as Flow;
      props.updateFlow(newFlow);
      setSelectedNode(newNode);
    },
    [screenToFlowPosition, props.flow]
  );

  const handleAllEdgesChange = useCallback(
    (edgeChanges: EdgeChange<EdgeBase>[]) => {
      onEdgesChange(edgeChanges);
      if (!props.flow) {
        return;
      }
      //batch the changes before updating the model to support undo of the atomic UI gesture in the future
      if (!props.flow) {
        return;
      }

      let currentFlow = props.flow;

      edgeChanges.forEach(edgeChange => {
        currentFlow = handleEdgeChange(currentFlow, edgeChange) || currentFlow;
      });

      if (currentFlow !== props.flow) {
        // this indicates that we have some updates
        props.updateFlow(currentFlow);
      }
    },
    [props.flow]
  );

  function handleEdgeChange(
    flowToChange: Flow,
    edgeChange: EdgeChange<EdgeBase>
  ): Flow | undefined {
    // handle terminal events as changes to the underlying model, handle temporary changes with react-flow's native state management
    const currentFlow = flowToChange;
    if (edgeChange.type === 'add') {
      const addChange = edgeChange as EdgeAddChange<EdgeBase>;
      const newEdge = {
        sourceNode: addChange.item.source,
        targetNode: addChange.item.target,
      } as Edge;
      const copyFlow = structuredClone(props.flow);
      return {
        ...copyFlow,
        edges: [...(copyFlow?.edges || []), newEdge],
      } as Flow;
    }
    if (edgeChange.type === 'remove') {
      const removeChange = edgeChange as EdgeRemoveChange;
      if (removeChange.id) {
        const copyFlow = structuredClone(props.flow);
        return {
          ...copyFlow,
          edges: (copyFlow?.edges || []).filter(
            edge => edge.id !== removeChange.id
          ),
        } as Flow;
      }
    }
    if (edgeChange.type === 'replace') {
      const removeChange = edgeChange as EdgeReplaceChange;
      if (removeChange.id) {
        const copyFlow = structuredClone(props.flow);
        return {
          ...copyFlow,
          edges: (copyFlow?.edges || []).filter(
            edge => edge.id !== removeChange.id
          ),
        } as Flow;
      }
    }
  }

  const handleAllNodesChange = useCallback(
    (nodeChanges: NodeChange<NodeBase>[]) => {
      // if no model changes, just send the changes to react-flow to update the UI
      onNodesChange(nodeChanges);

      // should we skip for now, which we will do if we are in the middle of a drag or resize
      const shouldSkip =
        nodeChanges.filter(
          change =>
            (change as NodeDimensionChange)?.resizing ||
            (change as NodePositionChange)?.dragging
        ).length > 0;
      if (shouldSkip) {
        return;
      }

      //batch the changes before updating the model to support undo of the atomic UI gesture in the future
      if (!props.flow) {
        return;
      }

      let currentFlow = props.flow;

      nodeChanges.forEach(nodeChange => {
        currentFlow = handleNodeChange(currentFlow, nodeChange) || currentFlow;
      });

      if (currentFlow !== props.flow) {
        // this indicates that we have some updates
        props.updateFlow(currentFlow);
      }
    },
    [props.flow, nodes]
  );

  function handleNodeChange(
    flowToChange: Flow,
    nodeChange: NodeChange<NodeBase>
  ): Flow | undefined {
    // handle terminal events as changes to the underlying model, handle temporary changes with react-flow's native state management
    if (nodeChange.type === 'dimensions') {
      const dimensionChange = nodeChange as NodeDimensionChange;
      if (!dimensionChange.resizing) {
        // the event that ends resizing doesn't have the dimensions on it, and we skipped the pending position changes
        // if the node was being resized such that the top left corner changed.  In this case we need to true up dimensions and position
        // from node state
        return mutateNodeById(dimensionChange.id, node => {
          let hasChange = false;
          const nodeFromState = nodes.find(n => n.id === dimensionChange.id);
          if (!nodeFromState) {
            return false;
          }
          if (
            nodeFromState.width !== undefined &&
            nodeFromState.height !== undefined
          ) {
            if (nodeFromState.width !== node.width) {
              node.width = nodeFromState.width || 0;
              hasChange = true;
            }
            if (nodeFromState.height !== node.height) {
              node.height = nodeFromState.height || 0;
              hasChange = true;
            }
            if (nodeFromState.position?.x !== node.x) {
              node.x = nodeFromState.position.x;
              hasChange = true;
            }
            if (nodeFromState.position?.y !== node.y) {
              node.y = nodeFromState.position.y;
              hasChange = true;
            }
          }
          return hasChange;
        });
      }
    }
    if (nodeChange.type === 'position') {
      const positionChange = nodeChange as NodePositionChange;
      if (!positionChange.dragging) {
        // intermediate changes should be skipped
        // for position changes, the event that ends dragging will have the new position on it
        // and we need to use that and not node state
        return mutateNodeById(positionChange.id, node => {
          let hasChange = false;
          if (
            positionChange.position?.x !== undefined &&
            positionChange.position?.y !== undefined &&
            positionChange.position?.x >= 0 &&
            positionChange.position?.y >= 0
          ) {
            if (positionChange.position.x !== node.x) {
              node.x = positionChange.position.x;
              hasChange = true;
            }
            if (positionChange.position.y !== node.y) {
              node.y = positionChange.position.y;
              hasChange = true;
            }
          }
          return hasChange;
        });
      }
    }
    if (nodeChange.type === 'add') {
      const addChange = nodeChange as NodeAddChange;
      if (addChange.item) {
        const newNode = {
          id: uuidv4(),
          name: addChange.item.data.label,
          x: addChange.item.position.x,
          y: addChange.item.position.y,
          width: addChange.item.width || 200,
          height: addChange.item.height || 100,
          nodeType: addChange.item.type,
          functions: [],
        } as Node;

        const copyFlow = structuredClone(flowToChange);
        return {
          ...copyFlow,
          nodes: [...(copyFlow?.nodes || []), newNode],
        } as Flow;
      }
    }
    if (nodeChange.type === 'remove') {
      const removeChange = nodeChange as NodeRemoveChange;
      if (removeChange.id) {
        const copyFlow = structuredClone(flowToChange);
        return pruneDanglingEdges({
          ...copyFlow,
          nodes: (copyFlow?.nodes || []).filter(n => n.id !== removeChange.id),
        });
      }
    }
    return undefined;
  }

  function mutateNodeById(
    id: String,
    mutator: (node: Node) => boolean
  ): Flow | undefined {
    if (!props.flow) {
      return undefined;
    }
    const updated = mutateNodeByIdForFlow(props.flow, id, mutator);
    if (updated) {
      return updated;
    }
    return undefined;
  }

  function mutateNodeByIdForFlow(
    flowToMutate: Flow,
    id: String,
    mutator: (node: Node) => boolean
  ): Flow | undefined {
    if (flowToMutate && id) {
      const existingIndex = flowToMutate.nodes.findIndex(
        node => node.id === id
      );
      if (existingIndex !== undefined && existingIndex >= 0) {
        const existingNode = flowToMutate.nodes[existingIndex];
        const copyNode = structuredClone(existingNode);
        if (mutator(copyNode)) {
          const copyFlow = structuredClone(flowToMutate);
          const newNodes = [...(copyFlow?.nodes || [])];
          newNodes[existingIndex] = copyNode;
          copyFlow.nodes = newNodes;
          return copyFlow;
        }
      }
    }
    return undefined;
  }

  function saveFlow() {
    if (!props.flow) {
      return;
    }

    if (!props.isDirty) {
      // skip saving if there are no saves
      return;
    }

    props.saveFlow(props.flow);
  }

  const onFlowSelectionChange = useCallback(
    (params: OnSelectionChangeParams) => {
      if (params?.nodes?.length > 0) {
        const selectedUINode = params.nodes[0];
        const selectedNode = props.flow?.nodes.find(
          n => n.id === selectedUINode.id
        );
        setSelectedNode(selectedNode);
      } else {
        setSelectedNode(undefined);
      }
    },
    [props.flow]
  );

  function onNodeUpdated(node: Node) {
    if (!props.flow) {
      return;
    }
    const existingIndex = props.flow.nodes.findIndex(n => n.id === node.id);
    if (existingIndex >= 0) {
      const copyFlow = structuredClone(props.flow);
      const newNodes = [...(copyFlow?.nodes || [])];
      newNodes[existingIndex] = node;
      const newFlow = {...copyFlow, nodes: newNodes} as Flow;
      props.updateFlow(newFlow);
    }
    setNodeDetailEditState({open: false});
  }

  const createNodeDetailsModal = useCallback(
    (nodeId: string | undefined) => {
      if (!nodeId) {
        return <></>;
      }

      if (!props.flow) {
        return <></>;
      }

      const node = props.flow?.nodes.find(n => n.id === nodeId);
      if (!node) {
        return <></>;
      }
      if (node.nodeType === NodeType.Table.name) {
        return (
          <TableNodeDetailsModal
            allTableNames={allTableNames}
            lobService={props.lobService}
            productService={props.productService}
            ratingService={props.ratingService}
            authToken={props.authToken}
            productVersionId={props.productVersionId}
            node={node}
            onSave={onNodeUpdated}
            onCancel={() => setNodeDetailEditState({open: false})}
            modifyCount={props.refreshFromServerCount}
            readOnly={props.readOnly}
          />
        );
      }

      if (node.nodeType === NodeType.Calculation.name) {
        return (
          <CalculationNodeModal
            allTableNames={allTableNames}
            flow={props.flow}
            node={node}
            lobHierarchy={lobHierarchy}
            onSave={onNodeUpdated}
            onCancel={() => setNodeDetailEditState({open: false})}
            modifyCount={props.refreshFromServerCount}
            metadataProvider={{
              getTableByName: getTableByName,
            }}
            globalVariables={globalVariables}
            readOnly={props.readOnly}
          />
        );
      }
      if (node.nodeType === NodeType.Branch.name) {
        return (
          <BranchNodeModal
            allTableNames={allTableNames}
            flow={props.flow}
            node={node}
            lobHierarchy={lobHierarchy}
            onSave={onNodeUpdated}
            onCancel={() => setNodeDetailEditState({open: false})}
            modifyCount={props.refreshFromServerCount}
            metadataProvider={{
              getTableByName: getTableByName,
            }}
            globalVariables={globalVariables}
            readOnly={props.readOnly}
          />
        );
      }
      return <></>;
    },
    [props.flow, allTableNames, lobHierarchy, globalVariables]
  );

  async function getTableByName(name: string): Promise<Table | undefined> {
    // lookup the table based on name from all tables?
    // TODO should we change the binding to store the table by id instead?
    const id = allTableNames.find(t => t.name === name)?.id;
    if (!id) {
      return undefined;
    }
    return await props.productService.GetProductVersionTable(id);
  }

  function getAvailableBranchTransitions(node: Node): string[] {
    const allTransitions = TransitionUtil.findAllTransitionNames(
      node.functions || []
    );
    const relevantEdges = props.flow?.edges.filter(
      e => e.sourceNode === node.id
    );

    const edgeNames = new Set(
      (relevantEdges || [])
        .filter(e => e.sourceTransitionName)
        .map(e => e.sourceTransitionName)
    );

    const remainingTransitions = allTransitions.filter(
      name => !edgeNames.has(name)
    );
    return remainingTransitions;
  }

  const onConnect = useCallback(
    (connection: Connection) => {
      const newEdge = {
        id: uuidv4(),
        sourceNode: connection.source,
        targetNode: connection.target,
      } as Edge;
      // check the source node to see if it is a branch, if so, we need to add a transition name
      // we should use the next unused transition
      const node = props.flow?.nodes.find(n => n.id === connection.source);
      if (node?.nodeType === NodeType.Branch.name) {
        const remainingTransitions = getAvailableBranchTransitions(node);
        if (remainingTransitions.length > 0) {
          newEdge.sourceTransitionName = remainingTransitions[0];
        }
      }

      const copyFlow = structuredClone(props.flow);
      const newEdges = [...(copyFlow?.edges || []), newEdge];
      const newFlow = {...copyFlow, edges: newEdges} as Flow;
      props.updateFlow(newFlow);
    },
    [props.flow, props.updateFlow]
  );

  const onReconnectEdge = useCallback(
    (oldEdge: EdgeBase, newConnection: Connection) => {
      // find the existing edge in the model
      if (!props.flow) {
        return;
      }

      const copyFlow = structuredClone(props.flow);
      const modelEdge = copyFlow?.edges.find(edge => edge.id === oldEdge.id);
      if (!modelEdge) {
        return;
      }

      modelEdge.sourceNode = newConnection.source;
      modelEdge.targetNode = newConnection.target;

      const sourceNode = copyFlow?.nodes.find(
        n => n.id === newConnection.source
      );
      if (oldEdge.source !== newConnection.source) {
        // a different source means we need to reset the transition name to either clear it or set to a valid name
        if (sourceNode?.nodeType === NodeType.Branch.name) {
          // clear any transition name if the source isn't a branch to support changing source from branch to nonbranch
          const remainingTransitions =
            getAvailableBranchTransitions(sourceNode);
          if (remainingTransitions.length > 0) {
            modelEdge.sourceTransitionName = remainingTransitions[0];
          } else {
            modelEdge.sourceTransitionName = undefined;
          }
        } else {
          modelEdge.sourceTransitionName = undefined;
        }
      }

      props.updateFlow(copyFlow);
    },
    [props.flow, props.updateFlow]
  );

  const onNodeDoubleClick = useCallback(
    (event: MouseEvent, nodeBase: NodeBase) => {
      setNodeDetailEditState({open: true, nodeId: nodeBase.id});
    },
    [setNodeDetailEditState]
  );

  const onKeyDownCapture = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Delete') {
        // enable the delete key, react flow only enables the backspace by default
        const selectedNodes = nodes.filter(n => n.selected && n.deletable);
        const selectedEdges = edges.filter(e => e.selected && e.deletable);
        if (
          props.flow &&
          (selectedNodes.length > 0 || selectedEdges.length > 0)
        ) {
          const copyFlow = structuredClone(props.flow);
          copyFlow.nodes = copyFlow.nodes.filter(
            n => !selectedNodes.some(sn => sn.id === n.id)
          );
          copyFlow.edges = copyFlow.edges.filter(
            e => !selectedEdges.some(se => se.id === e.id)
          );
          const prunedFlow = pruneDanglingEdges(copyFlow);
          props.updateFlow(prunedFlow);
          e.preventDefault();
        }
      }
    },
    [props.flow, nodes, edges]
  );

  const autoLayoutFlowCallback = useCallback(() => {
    if (!props.flow) {
      return;
    }
    const newFlow = autoLayout(props.flow);
    props.updateFlow(newFlow);
  }, [props.flow, props.updateFlow]);

  const buttonStyle = {width: '50px', height: '50px'};

  const contextMenuItems: MenuProps['items'] = [
    {
      label: (
        <Button
          {...ButtonSettings}
          type="link"
          onClick={autoLayoutFlowCallback}
          disabled={props.readOnly || !props.flow}
        >
          Auto Layout
        </Button>
      ),
      key: '0',
    },
  ];

  return (
    <>
      <div style={{height: '550px', width: '100%'}}>
        <Row style={{paddingBottom: '10px'}}>
          <Col span={12}>
            <Flex justify="flex-start" align="flex-start" className="gap-2">
              <Button
                {...ButtonSettings}
                style={{width: '50px', height: '50px'}}
                onDragStart={e => onDragStart(e, NodeType.Table.name)}
                draggable={props.flow !== undefined && !props.readOnly}
                disabled={!props.flow || props.readOnly}
              >
                <TableSvg />
              </Button>
              <Button
                style={{width: '50px', height: '50px'}}
                onDragStart={e => onDragStart(e, NodeType.Calculation.name)}
                draggable={props.flow !== undefined && !props.readOnly}
                disabled={!props.flow || props.readOnly}
                {...ButtonSettings}
              >
                <CalculationSvg />
              </Button>
              <Button
                style={{width: '50px', height: '50px'}}
                onDragStart={e => onDragStart(e, NodeType.Branch.name)}
                draggable={props.flow !== undefined && !props.readOnly}
                disabled={!props.flow || props.readOnly}
                {...ButtonSettings}
              >
                <BranchSvg />
              </Button>
            </Flex>
          </Col>
          <Col span={12}>
            <Flex justify="flex-end" align="flex-start" className="gap-2">
              <Button
                onClick={props.undo}
                style={{...buttonStyle}}
                {...ButtonSettings}
                disabled={!props.canUndo || props.readOnly}
              >
                <UndoOutlined />
              </Button>
              <Button
                onClick={props.redo}
                style={{...buttonStyle}}
                {...ButtonSettings}
                disabled={!props.canRedo || props.readOnly}
              >
                <RedoOutlined />
              </Button>
              <Button
                onClick={saveFlow}
                style={{...buttonStyle}}
                {...ButtonSettings}
                disabled={!props.isDirty || props.readOnly}
              >
                <SaveOutlined />
              </Button>
            </Flex>
          </Col>
        </Row>
        <div
          style={{
            height: '500px',
            width: '100%',
            border: 'solid',
            borderWidth: '1px',
            borderRadius: '8px',
            borderColor: '#CCCCCC',
          }}
        >
          <Dropdown
            trigger={['contextMenu']}
            menu={{
              items: contextMenuItems,
            }}
          >
            <ReactFlow
              onDragOver={onDragOver}
              onNodesChange={handleAllNodesChange}
              onEdgesChange={handleAllEdgesChange}
              onNodeDoubleClick={onNodeDoubleClick}
              onKeyDownCapture={onKeyDownCapture}
              onReconnect={onReconnectEdge}
              onDrop={onDrop}
              nodes={nodes}
              onSelectionChange={onFlowSelectionChange}
              edges={edges}
              selectNodesOnDrag={true}
              elementsSelectable={true}
              defaultEdgeOptions={{
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                  height: 20,
                  width: 20,
                },
              }}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
            >
              <Background />
              <Controls />
            </ReactFlow>
          </Dropdown>
        </div>
      </div>
      {createNodeDetailsModal(nodeDetailEditState.nodeId)}
    </>
  );
}
