import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {ProductVersionSummaryForm} from '../control/ProductVersionSummaryForm';
import {RatingNavigationBar} from '../control/RatingNavigationBar';
import {ProductVersionFlowControl} from '../control/flow/ProductVersionFlowControl';
import {CreateDefaultNodesAndEdges} from '../model/FlowUtils';
import {RatingService} from '../service/RatingService';
import Flow = kot.com.adaptify.rating.admin.model.flow.Flow;
import Edge = kot.com.adaptify.rating.admin.model.flow.Edge;
import Node = kot.com.adaptify.rating.admin.model.flow.Node;
import NodeType = kot.com.adaptify.rating.admin.model.flow.NodeType;

import {ReactFlowProvider} from '@xyflow/react';
import {
  ProductVersion,
  ProductVersionStatus,
} from '../../product/model/Product';
import {DirtyEditorConfirmationModal} from '../control/DirtyEditorConfirmationModal';
import {CoverageSelectionForm} from '../control/flow/CoverageSelectionForm';

export interface ProductVersionCoverageControlProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  productVersionId: string;
  initialSelectedCoverageId: string | undefined;
  authToken: string;
  onCoverageSelected?: (coverageId: string | undefined) => void;
}

interface SelectedFlowConfirmState {
  open: boolean;
  coverageId?: string;
}

export function ProductVersionCoverageControl(
  props: ProductVersionCoverageControlProps
) {
  const [refreshFromServerCount, setRefreshFromServerCount] =
    useState<number>(0);

  const [selectedCoverageId, setSelectedCoverageId] = useState<
    string | undefined
  >(props.initialSelectedCoverageId);

  const [flow, setFlow] = useState<Flow | undefined>();
  // TODO limit the size of the stack so it can't grow indefinitely
  const [undoStack, setUndoStack] = useState<Flow[]>([]);
  const [redoStack, setRedoStack] = useState<Flow[]>([]);
  const [productVersion, setProductVersion] = useState<
    ProductVersion | undefined
  >(undefined);

  const [selectedFlowConfirmState, setSelectedFlowConfirmState] =
    useState<SelectedFlowConfirmState>({
      open: false,
    });

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setProductVersion(undefined);
        return;
      }
      const productVersion = await props.productService.GetProductVersion(
        props.productVersionId
      );
      setProductVersion(productVersion);
    };
    eff();
  }, [props.productVersionId]);

  useEffect(() => {
    const eff = async () => {
      if (
        props.productVersionId === '' ||
        !selectedCoverageId ||
        selectedCoverageId === ''
      ) {
        handleNewFlowFromServer(undefined);
        return;
      }
      // if there's no flow, create an empty one
      const loadedFlow =
        await props.productService.GetProductVersionFlowsByVersionIdAndLobItemId(
          props.productVersionId,
          selectedCoverageId || ''
        );
      if (!loadedFlow || loadedFlow.length === 0) {
        // if there's no flow, create an empty one with the default nodes and edges
        const nodesAndEdges = CreateDefaultNodesAndEdges();
        const emptyFlow = {
          ...NewTenantAwareEntity(),
          name: undefined,
          productVersionId: props.productVersionId,
          sequenceNumber: undefined,
          lineOfBusinessItemId: selectedCoverageId || '',
          nodes: nodesAndEdges.nodes,
          edges: nodesAndEdges.edges,
        } as Flow;
        handleNewFlowFromServer(emptyFlow);
      } else {
        handleNewFlowFromServer(loadedFlow[0]);
      }
    };
    eff();
  }, [props.productVersionId, selectedCoverageId, refreshFromServerCount]);

  async function handleNewFlowFromServer(flow?: Flow) {
    setFlow(flow);
    setUndoStack([]);
    setRedoStack([]);
  }

  function isDirty() {
    return undoStack.length > 0;
  }

  function onFlowLocalChanged(updated: Flow | undefined) {
    if (flow) {
      setUndoStack([...undoStack, flow]);
    }
    setFlow(updated);
  }

  async function saveFlow(flowToSave: Flow): Promise<Flow> {
    if (!flowToSave) {
      return flowToSave;
    }

    const updated =
      await props.productService.UpdateProductVersionFlow(flowToSave);
    // need to go back to the server after saving because the list might need updating
    // so force reload (esp if it's saving the same table)
    setRefreshFromServerCount(refreshFromServerCount + 1);

    // remove the undo stack on save for now, so we don't need to figure out how to manage server conflicts
    setUndoStack([]);
    setRedoStack([]);
    return updated;
  }

  async function deleteFlow(flowId: string): Promise<void> {
    await props.productService.DeleteProductVersionFlow(flowId);
    setRefreshFromServerCount(refreshFromServerCount + 1);
  }

  async function saveCurrentFlow() {
    if (flow && isDirty()) {
      await saveFlow(flow);
    }
  }

  function canUndo() {
    return undoStack.length > 0;
  }

  function canRedo() {
    return redoStack.length > 0;
  }

  function undo() {
    if (undoStack.length === 0) {
      return;
    }
    const last = undoStack[undoStack.length - 1];
    if (flow) {
      setRedoStack([...redoStack, flow]);
    }
    setFlow(last);
    setUndoStack(undoStack.slice(0, undoStack.length - 1));
    // undo pushes an external change to the model this triggers a graph refresh
  }

  function redo() {
    if (redoStack.length === 0) {
      return;
    }
    const last = redoStack[redoStack.length - 1];
    if (flow) {
      setUndoStack([...undoStack, flow]);
    }
    setFlow(last);
    setRedoStack(redoStack.slice(0, redoStack.length - 1));
    // redo pushes an external change to the model this triggers a graph refresh
  }

  function isReadOnly() {
    return productVersion?.status === ProductVersionStatus.Published;
  }

  useHotkeys('mod+s', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (flow) {
      saveFlow(flow);
    }
  });

  useHotkeys('mod+z', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canUndo()) {
      undo();
    }
  });

  // mac os
  useHotkeys('mod+shift+z', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  //windows
  useHotkeys('ctrl+y', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  const confirmModal = selectedFlowConfirmState.open ? (
    <DirtyEditorConfirmationModal
      open={selectedFlowConfirmState.open}
      skipDialog={!isDirty()}
      onCancel={() => {
        setSelectedFlowConfirmState({open: false});
        // do nothing
      }}
      onConfirm={async () => {
        if (selectedFlowConfirmState.coverageId) {
          setSelectedCoverageId(selectedFlowConfirmState.coverageId);
          if (props.onCoverageSelected) {
            props.onCoverageSelected(selectedFlowConfirmState.coverageId);
          }
        }
        setSelectedFlowConfirmState({open: false});
      }}
    />
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical gap={24}>
        <div style={{minHeight: '200px', width: '100%'}}>
          <ProductVersionSummaryForm
            productService={props.productService}
            productVersionId={props.productVersionId ?? ''}
            isDirty={isDirty()}
            save={saveCurrentFlow}
            readOnly={isReadOnly()}
          />
        </div>
        <div style={{minHeight: '46px', width: '100%'}}>
          <RatingNavigationBar
            productVersionId={props.productVersionId}
            isDirty={isDirty()}
            selection="Coverage"
            onSave={saveCurrentFlow}
          />
        </div>
        <CoverageSelectionForm
          productService={props.productService}
          productVersionId={props.productVersionId}
          selectedCoverageId={selectedCoverageId}
          setSelectedCoverageId={value => {
            setSelectedFlowConfirmState({
              open: true,
              coverageId: value,
            });
          }}
        />
        <div style={{width: '100%', height: 'clamp(550px, 60svh, 800px)'}}>
          <ReactFlowProvider>
            <ProductVersionFlowControl
              productService={props.productService}
              ratingService={props.ratingService}
              lobService={props.lobService}
              authToken={props.authToken}
              productVersionId={props.productVersionId}
              flow={flow}
              updateFlow={onFlowLocalChanged}
              saveFlow={saveFlow}
              deleteFlow={deleteFlow}
              isDirty={isDirty()}
              canUndo={canUndo()}
              canRedo={canRedo()}
              undo={undo}
              redo={redo}
              refreshFromServerCount={refreshFromServerCount}
              readOnly={isReadOnly()}
            />
          </ReactFlowProvider>
        </div>
      </Flex>
      {confirmModal}
    </>
  );
}
