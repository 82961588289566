import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {Button, Col, Flex, Modal, Row} from 'antd';
import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {LobService} from '../../lob/service/LobService';
import {IdAndName} from '../../product/model/Product';
import {ProductService} from '../../product/service/ProductService';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {Study, StudyInfo} from '../model/ComparativeAnalysis';
import {AnalysisService} from '../service/AnalysisService';
import {EditStudyForm} from './EditStudyForm';

export interface AnalysisStudyListProps {
  analysisService: AnalysisService;
  productService: ProductService;
  lobService: LobService;
  valueListService: ValueListService;

  modifyCount: number;
  setModifyCount: (count: number) => void;
  studies: StudyInfo[];
  selectedStudyId: string | undefined;
  setSelectedStudyId: (id: string | undefined) => void;
}

interface EditStudyFormState {
  isNew: boolean;
  open: boolean;
  studyId?: string | undefined;
}

export function AnalysisStudyList(props: AnalysisStudyListProps) {
  const [editStudyFormState, setEditStudyFormState] =
    useState<EditStudyFormState>({
      isNew: false,
      open: false,
    });
  const [tableComparisonEditState, setTableComparisonEditState] =
    useState<EditStudyFormState>({
      isNew: false,
      open: false,
    });
  const navigate = useNavigate();

  function onStudySelectionChanged(rowSelectionModel: GridRowSelectionModel) {
    if (rowSelectionModel.length > 0) {
      props.setSelectedStudyId(rowSelectionModel[0] as string);
    } else {
      props.setSelectedStudyId(undefined);
    }
  }

  async function onSaveEditStudy(study: Study) {
    setEditStudyFormState({
      isNew: false,
      open: false,
    });
    props.setSelectedStudyId(study.id);
    props.setModifyCount(props.modifyCount + 1);

    // the requirements are to immediately open the table comparison dialog when the study dialog
    // it makes most sense to do this from the detail screen so navigate there
    navigate(
      `/analysis/study/${study.id}/detail?launchAddTableComparisonDialog=true`
    );
  }

  function onCancelEditStudy() {
    setEditStudyFormState({
      isNew: false,
      open: false,
    });
  }

  function onOpenCreateDialog() {
    setEditStudyFormState({
      isNew: true,
      open: true,
    });
  }

  function onOpenEditDialog() {
    const study = props.studies.find(s => s.id === props.selectedStudyId);
    if (study) {
      setEditStudyFormState({
        isNew: false,
        open: true,
        studyId: study.id,
      });
    }
  }

  function onDelete() {
    if (props.selectedStudyId) {
      props.analysisService.DeleteStudy(props.selectedStudyId);
      props.setSelectedStudyId(undefined);
      props.setModifyCount(props.modifyCount + 1);
    }
  }

  function onCancelTableComparisonDialog() {
    setTableComparisonEditState({
      ...tableComparisonEditState,
      open: false,
    });
    // if we cancel the table comparison we presumably created a new study so navigate to the study details
    navigate(
      `/analysis/study/${tableComparisonEditState.studyId ?? ''}/detail`
    );
  }

  const colDefs: GridColDef<IdAndName>[] = [
    {
      field: 'name',
      headerName: 'Study Name',
      flex: 100,
      renderCell: params => {
        return (
          <Link to={`/analysis/study/${params.row.id}/detail`}>
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 500,
    },
  ];

  // edit and delete buttons aren't part of current requirements but they might get added back
  /*

  const editButtons = (
    <Flex justify="flex-end" align="flex-start">
      <Button
        {...ButtonSettings}
        onClick={onOpenEditDialog}
        disabled={!props.selectedStudyId}
      >
  <Button onClick={onOpenEditDialog} disabled={!props.selectedStudyId}>
        Modify
      </Button>
      <Popconfirm
        title="Confirm deletion"
        description="Are you sure to want to delete?"
        onConfirm={onDelete}
        disabled={!props.selectedStudyId}
        okText="Delete"
        cancelText="Cancel"
      >
        <Button {...ButtonSettings} disabled={!props.selectedStudyId}>
          Delete
        </Button>
      </Popconfirm>
      <Button {...ButtonSettings} type="primary" onClick={onOpenCreateDialog}>
        Create New Carrier Study
      </Button>
*/
  const editButtons = (
    <Flex justify="flex-end" align="flex-start">
      <Button
        {...ButtonSettings}
        icon={<AddOutlinedIcon className="-m-1" />}
        onClick={onOpenCreateDialog}
      >
        Create New Carrier Study
      </Button>
    </Flex>
  );

  const editStudyModal = editStudyFormState.open ? (
    <Modal
      className="adaptify-modal"
      title={editStudyFormState.isNew ? 'Add Study' : 'Edit Study'}
      open={editStudyFormState.open}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 1200px)'}
      footer={null}
    >
      <EditStudyForm
        analysisService={props.analysisService}
        productService={props.productService}
        lobService={props.lobService}
        valueListService={props.valueListService}
        studyId={editStudyFormState.studyId}
        onSave={onSaveEditStudy}
        onCancel={onCancelEditStudy}
      />
    </Modal>
  ) : (
    <> </>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Carrier Studies</div>
          </Col>
          <Col span={6}>{editButtons}</Col>
          <Col span={24}>
            <DataGrid
              style={{height: 'clamp(500px, 60svh, 800px)'}}
              {...DataGridSettings}
              columns={colDefs}
              filterMode="client"
              rowSelectionModel={
                props.selectedStudyId ? [props.selectedStudyId] : []
              }
              onRowSelectionModelChange={onStudySelectionChanged}
              rows={props.studies}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </Col>
        </Row>
      </Flex>
      {editStudyModal}
    </>
  );
}
