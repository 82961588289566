import {Button, Flex, Form, Input, Modal, Select} from 'antd';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../../common/control/Common';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {PathPickTree} from '../calculation/binding/PathPickTree';
import Column = kot.com.adaptify.rating.admin.model.table.Column;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;
import ColumnType = kot.com.adaptify.rating.admin.model.table.ColumnType;
import ColumnEvaluationType = kot.com.adaptify.rating.admin.model.table.ColumnEvaluationType;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import VariableType = kot.com.adaptify.rating.admin.model.calculation.context.VariableType;
import PathResolver = kot.com.adaptify.rating.admin.model.util.PathResolver;
import CreatePathResolver = kot.com.adaptify.rating.admin.model.util.CreatePathResolver;

export interface EditColumnProps {
  column: Column;
  columnIndex: number;
  lobHierarchy: LineOfBusinessHierarchy | undefined;
  onSaveColumn: (column: Column, columnIndex: number) => void;
  onCancel: () => void;
  globalVariables: ScopedVariable[];
  readOnly: boolean;
}

interface PathPickerState {
  open: boolean;
  selectedPath?: string;
}

interface ColumnFormValues {
  name: string;
  columnType: string;
  evaluationType: string;
  path: string;
  dataType: string;
}

export function EditColumnForm(props: EditColumnProps) {
  const [internalRefreshNumber, setInternalRefreshNumber] = React.useState(0);
  const [pathPickerState, setPathPickerState] = React.useState<PathPickerState>(
    {
      open: false,
    }
  );
  const [scopedVariables, setScopedVariables] = React.useState<
    ScopedVariable[]
  >([]);

  const [form] = Form.useForm<ColumnFormValues>();

  useEffect(() => {
    form.setFieldsValue({
      name: props.column.name ?? undefined,
      columnType: props.column.columnType ?? undefined,
      evaluationType: props.column.evaluationType ?? undefined,
      path: props.column.path ?? undefined,
    });
    setInternalRefreshNumber(internalRefreshNumber + 1);
  }, [props.column]);

  useEffect(() => {
    setScopedVariables([]);
    if (props.lobHierarchy) {
      const rootLobItem =
        props.lobHierarchy?.risks?.length > 0
          ? props.lobHierarchy.risks[0]
          : undefined;
      if (rootLobItem) {
        setScopedVariables(
          [
            {
              name: rootLobItem.name,
              type: VariableType.Input.name,
              displayName: rootLobItem.name,
              boundDataType: {
                lobItem: rootLobItem,
                isMany: false,
              },
            } as ScopedVariable,
            ...(props.globalVariables ?? []),
          ].sort((a, b) => a.displayName.localeCompare(b.displayName))
        );
      }
    }
  }, [props.lobHierarchy, props.globalVariables]);

  useEffect(() => {
    const path = form.getFieldsValue().path;
    const dataType =
      path && props.lobHierarchy
        ? CreatePathResolver().ResolveDataTypeForRootPath(
            path,
            props.lobHierarchy,
            props.globalVariables
          )
        : undefined;

    form.setFieldsValue({
      ...form.getFieldsValue(),
      dataType: dataType?.primitive?.name,
    });
  }, [props.lobHierarchy, form.getFieldsValue().path]);

  const columnTypeOptions = [
    '',
    ColumnType.Input.name,
    ColumnType.Output.name,
  ].map(v => <Select.Option value={v}>{v}</Select.Option>);

  const evaluationTypeOptions = ColumnEvaluationType.values().map(v => (
    <Select.Option value={v.value}>{v.value}</Select.Option>
  ));

  function onSave(value: ColumnFormValues) {
    const column = {
      ...props.column,
      name: value.name,
      columnType: value.columnType,
      evaluationType: value.evaluationType,
      path: value.path,
    };
    props.onSaveColumn(column, props.columnIndex);
  }

  function onPathPickCancel() {
    setPathPickerState({open: false});
  }

  function onPathPickFinish() {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      path: pathPickerState.selectedPath,
    });
    setPathPickerState({open: false});
    setInternalRefreshNumber(internalRefreshNumber + 1);
  }

  function onFormValuesChange(
    changedValues: ColumnFormValues,
    allValues: ColumnFormValues
  ) {
    if (changedValues.path) {
      setInternalRefreshNumber(internalRefreshNumber + 1);
    }
    if (changedValues.columnType) {
      setInternalRefreshNumber(internalRefreshNumber + 1);
    }
  }

  const inputStyleClass = props.readOnly ? 'adaptify-read-only-field' : '';

  const evaluationTypeFormItem =
    form.getFieldsValue().columnType === ColumnType.Input.name ? (
      <Form.Item
        name="evaluationType"
        label="Column Conditions"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          placeholder="select a column condition type"
          disabled={props.readOnly}
          className={inputStyleClass}
        >
          {evaluationTypeOptions}
        </Select>
      </Form.Item>
    ) : (
      <> </>
    );

  const pathModal = pathPickerState.open ? (
    <Modal
      className="adaptify-modal"
      width={'clamp(300px, 70svw, 800px)'}
      height={600}
      title={`Pick ${props.column.name}`}
      open={pathPickerState.open}
      onCancel={onPathPickCancel}
      cancelButtonProps={{...ButtonSettings, size: 'large'}}
      onOk={onPathPickFinish}
      okButtonProps={{
        ...ButtonSettings,
        type: 'default',
        size: 'large',
        ghost: false,
      }}
      style={{top: 20}}
    >
      <PathPickTree
        height={400}
        rootProperties={scopedVariables}
        allowedDataTypes={[]}
        selectedPath={form.getFieldsValue().path}
        setSelectedPath={path => {
          setPathPickerState({...pathPickerState, selectedPath: path});
        }}
        resetVersion={internalRefreshNumber}
      />
    </Modal>
  ) : (
    <></>
  );

  return (
    <>
      <Form
        name="edit_column"
        layout="vertical"
        size="large"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        style={{width: '100%'}}
        onFinish={onSave}
        onValuesChange={onFormValuesChange}
        autoComplete="off"
        form={form}
      >
        <Form.Item name="name" label="Name" rules={[{required: true}]}>
          <Input disabled={props.readOnly} className={inputStyleClass} />
        </Form.Item>
        <Form.Item
          name="columnType"
          label="Column Type"
          rules={[{required: true}]}
        >
          <Select
            showSearch
            placeholder="select a column type"
            disabled={props.readOnly}
            className={inputStyleClass}
          >
            {columnTypeOptions}
          </Select>
        </Form.Item>
        {evaluationTypeFormItem}
        <Form.Item name="path" label="Risk Item Field" style={{width: '100%'}}>
          <Flex style={{width: '100%'}}>
            <Input
              readOnly
              value={form.getFieldsValue().path}
              className={inputStyleClass}
              style={{width: '100%'}}
            />
            {props.readOnly ? (
              <></>
            ) : (
              <Button
                {...ButtonSettings}
                onClick={() =>
                  setPathPickerState({
                    open: true,
                    selectedPath: form.getFieldsValue().path,
                  })
                }
              >
                ...
              </Button>
            )}
          </Flex>
        </Form.Item>
        <Form.Item name="dataType" label="Data Type" style={{width: '100%'}}>
          <Input readOnly className="adaptify-read-only-field" />
        </Form.Item>
        <Flex justify="end" className="gap-2 w-full">
          {props.readOnly ? (
            <Button
              {...ButtonSettings}
              htmlType="button"
              onClick={props.onCancel}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                {...ButtonSettings}
                htmlType="button"
                onClick={props.onCancel}
              >
                Cancel
              </Button>
              <Button ghost={false} type="default" htmlType="submit">
                OK
              </Button>
            </>
          )}
        </Flex>
      </Form>
      {pathModal}
    </>
  );
}
