package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.CalculationFunctionUtil
import com.adaptify.rating.admin.model.calculation.binding.BindingUtil
import com.adaptify.rating.admin.model.calculation.descriptor.*
import com.adaptify.rating.admin.model.calculation.util.CalculationFunctionVisitor
import com.adaptify.rating.admin.model.calculation.util.TransitionUtil
import com.adaptify.rating.admin.model.generateUUID

object TransitionDescriptor : FunctionDescriptor("TRANSITION", "Transition", FunctionType.Transition, "Logic") {

  val nameDescriptor = BindingDescriptor(
    "name", "Transition Name",
    arrayOf(BindingType.TransitionDefinition), null
  )

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> =  arrayOf(nameDescriptor);

  override fun getDisplayTemplate(
  ): String = "\$name"

  override fun createDefault(roots: Array<CalculationFunction>, parent: CalculationFunction?): CalculationFunction {
    val transitionNames = TransitionUtil.findAllTransitionNames(roots)
    val transitionName = "Transition ${transitionNames.size + 1}"
    val function = CalculationFunctionUtil.create(generateUUID(), name, emptyArray());
    function.bindings = arrayOf(
      BindingUtil.fromTransitionDefinition(nameDescriptor.name, transitionName))
    return function
  }

}
