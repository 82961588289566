import {Button, Flex, Form, Input} from 'antd';
import React from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {User} from '../model/User';
import {UserService} from '../service/UserService';

interface CreateUserFormProps {
  userService: UserService;
  existingTenantId?: string;
  onCreated: (user: User) => void;
  onCancel: () => void;
}

interface CreateUserFormArgs {
  fullName: string;
  email: string;
}

export function CreateUserForm(props: CreateUserFormProps) {
  const [form] = Form.useForm<CreateUserFormArgs>();

  async function onSave(value: CreateUserFormArgs) {
    const user = await props.userService.CreateUser(
      props.existingTenantId ?? '',
      value.fullName,
      value.email
    );
    props.onCreated(user);
  }

  return (
    <Form
      name="edit_tenant"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      style={{maxWidth: 800}}
      size="large"
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item name="fullName" label="Full Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{required: true, type: 'email'}]}
      >
        <Input />
      </Form.Item>
      <Flex justify="end" style={{width: '100%'}} className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          Save
        </Button>
      </Flex>
    </Form>
  );
}
