import {Button, Flex, Form, Input} from 'antd';
import React, {useEffect} from 'react';

import {ButtonSettings} from '../../common/control/Common';
import {ValueListDefinition} from '../model/ValueList';

export interface EditValueListDefinitionFormProps {
  valueListDefinition: ValueListDefinition;
  allValueListDefinitions: ValueListDefinition[];
  onSave: (valueList: ValueListDefinition) => void;
  onCancel: () => void;
}

export function EditValueListDefinitionForm(
  props: EditValueListDefinitionFormProps
) {
  const [form] = Form.useForm<ValueListDefinition>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(props.valueListDefinition);
  }, [props.valueListDefinition]);

  function onSave(value: ValueListDefinition) {
    // merge values in the form with the existing object
    props.onSave({
      ...props.valueListDefinition,
      ...value,
    });
  }

  return (
    <Form
      name="value_list_definition"
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      style={{width: '100%'}}
      initialValues={{...props.valueListDefinition}}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item name="name" label="Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Form.Item name="code" label="Code" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Flex style={{width: '100%'}} justify="center" className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          OK
        </Button>
      </Flex>
    </Form>
  );
}
