import {Col, Menu, MenuProps, Row} from 'antd';
import React from 'react';

import {Link, useNavigate} from 'react-router-dom';

export interface RatingNavigationBarProps {
  selection: string;
  productVersionId: string;
  isDirty: boolean;
  onSave: () => void;
}

export function RatingNavigationBar(props: RatingNavigationBarProps) {
  const navigate = useNavigate();
  const navItems: MenuProps['items'] = [
    {
      key: 'Table',
      label: (
        <Link to={`/rating/product/version/${props.productVersionId}/table`}>
          Tables
        </Link>
      ),
    },
    {
      key: 'Coverage',
      label: (
        <Link to={`/rating/product/version/${props.productVersionId}/coverage`}>
          Coverages
        </Link>
      ),
    },
    {
      key: 'Risk',
      label: (
        <Link to={`/rating/product/version/${props.productVersionId}/risk`}>
          Risk Item Calculations
        </Link>
      ),
    },
    {
      key: 'Orchestration',
      label: (
        <Link
          to={`/rating/product/version/${props.productVersionId}/orchestration`}
        >
          Premium Orchestration
        </Link>
      ),
    },
  ];

  async function onSaveAndExit() {
    if (props.onSave) {
      await props.onSave();
    }
    navigate('/platform/product/summary/my');
  }

  async function onExit() {
    navigate('/platform/product/summary/my');
  }

  return (
    <Row>
      <Col span="24">
        <Menu
          mode="horizontal"
          defaultSelectedKeys={[props.selection]}
          items={navItems}
        />
      </Col>
    </Row>
  );
}
