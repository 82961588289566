import {App, Button, Card, Flex, Form, Input} from 'antd';
import React, {useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {HTTP_FORBIDDEN} from '../../common/service/Constants';
import {HttpError} from '../../common/service/Service';
import UserService from '../service/UserService';

interface LoginInfo {
  email: string;
  password: string;
}

interface LoginFormProps {
  onLogin: (token: string) => void;
}

const loginButtonStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  minHeight: 48,
  minWidth: 160,
  lineHeight: '1',
  fontSize: '1rem',
  fontWeight: 700,
  height: 'auto',
};

export function LoginForm(props: LoginFormProps) {
  const [loginError, setLoginError] = useState('');
  const [form] = Form.useForm();

  const modal = App.useApp().modal;
  async function onFinish(info: LoginInfo) {
    try {
      const token = await UserService('any').LoginUser(
        info.email,
        info.password
      );
      props.onLogin(token);
      form.resetFields();
    } catch (e) {
      const error = e as HttpError;
      if (error.statusCode === HTTP_FORBIDDEN) {
        setLoginError('Invalid login or password');
        form.setFields([
          {
            name: 'password',
            errors: ['Invalid login or password'],
          },
        ]);
      } else {
        modal.error({
          title: 'Internal Error',
          content: e.message + '\n' + (e.stack || ''),
        });
      }
    }
  }

  function onValidateLogin() {
    if (loginError !== '') {
      return Promise.reject(loginError);
    }

    return Promise.resolve(loginError);
  }

  function onFormFieldsChange() {
    // reset the login message after a failed login
    setLoginError('');
  }

  return (
    <Card bordered={false} title="Login to your account" className="login-card">
      <Form
        name="login"
        labelCol={{span: 16}}
        wrapperCol={{span: 24}}
        style={{width: 453}}
        initialValues={{remember: true}}
        onFieldsChange={onFormFieldsChange}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        layout={'vertical'}
        size="large"
      >
        <Form.Item<LoginInfo>
          label="Email"
          name="email"
          rules={[{required: true, message: 'Please input your email'}]}
        >
          <Input placeholder="Enter email ID" />
        </Form.Item>

        <Form.Item<LoginInfo>
          label="Password"
          name="password"
          validateStatus={loginError !== '' ? 'error' : ''}
          rules={[
            {required: true, message: 'Please input your password'},
            {validator: onValidateLogin, validateTrigger: ['onChange']},
          ]}
        >
          <Input.Password placeholder="Enter password" />
        </Form.Item>

        <Form.Item wrapperCol={{offset: 8, span: 16}} className="pt-2">
          <Flex justify="end">
            <Button
              {...ButtonSettings}
              style={loginButtonStyle}
              type="default"
              htmlType="submit"
              className="login-cta"
            >
              Login
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Card>
  );
}
