package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.BoundDataType
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import com.adaptify.rating.admin.model.util.BindingResolver

object AssignDescriptor : FunctionDescriptor("ASSIGN", "=", FunctionType.Function, "Math"){

  val assignToDescriptor = object : BindingDescriptor(
    "assignTo", "Assign To",
    BindingDescriptor.standardOutputBindingTypes, null
  ) {
    override fun getExpectedDataType(
      calculationFunction: CalculationFunction,
      functionDescriptor: FunctionDescriptor,
      bindingDescriptor: BindingDescriptor,
      binding: Binding,
      scope: Array<ScopedVariable>
    ): BoundDataType? {
      val valueBinding = calculationFunction.bindings.find { it.name == "value" }
      if (valueBinding == null) {
        return null;
      }

      return BindingResolver.ResolveDataTypeForBinding(
        calculationFunction,
        valueBinding,
        scope
      );
    }
  }

  val valueDescriptor = BindingDescriptor(
    "value", "Value",
    BindingDescriptor.standardInputBindingTypes,
    // limit variables to only point to primitive types
    // I don't think our product team understands variables that don't point to primitives
    // so I have to assume that customers won't either.
    // this will make global variable resolution easier since you can't form a path
    // from a global variables
    arrayOf(PrimitiveDataType.String, PrimitiveDataType.Number,
      PrimitiveDataType.Boolean, PrimitiveDataType.Date)
  )

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> =  arrayOf(assignToDescriptor, valueDescriptor);

  override fun getDisplayTemplate(
  ): String = "\$assignTo = \$value"
}
